import { Card, Skeleton, PageHeader, Tag, Space } from "antd";
import { StarFilled } from "@ant-design/icons";

import networks, { activeNetworks } from "../../data/networks";
import returnStatValue from "../../lib/returnStatValue";
import formatRating from "../../lib/formatRating";

const { Meta } = Card;

const SearchCard = ({
	influencer = null,
	loading = false,
	onClick = null,
	selected = false,
}) => {
	const influencerNetworks = activeNetworks(
		influencer === null ? {} : influencer
	);

	return (
		<Card
			bordered={false}
			className={"search-card " + (selected ? "selected" : "")}
			hoverable
			onClick={onClick}
			cover={
				influencer !== null && influencer.avatar !== false ? (
					<img alt="example" src={influencer.avatar} />
				) : (
					<img alt="example" src="/no-avatar.png" />
				)
			}
		>
			<Skeleton loading={loading} active>
				<Meta
					title={
						<>
							<PageHeader
								title={
									influencer !== null &&
									`${influencer.firstname} ${influencer.lastname}`
								}
								className="influencer-title-small search-card-title"
							/>
							<span className="search-card-rating">
								<StarFilled />{" "}
								{influencer !== null && formatRating(influencer.rating)}
							</span>
						</>
					}
					description={
						<Space wrap>
							{networks
								.filter((network) => influencerNetworks.includes(network.id))
								.map((network, key) => (
									<Tag key={key} icon={network.icon}>
										{influencer !== null &&
											returnStatValue(influencer[network.id].followers)}
									</Tag>
								))}
						</Space>
					}
				/>
			</Skeleton>
		</Card>
	);
};

export default SearchCard;
