import { Card, Skeleton, PageHeader, Tag, Space } from "antd";
import {
	TeamOutlined,
	SyncOutlined,
	DollarOutlined,
	CheckCircleOutlined,
	EditOutlined,
} from "@ant-design/icons";
import useTranslation from "../../intl/useTranslation";

const { Meta } = Card;

const MissionCard = ({ mission = null, loading = false, onClick = null }) => {
	const { t } = useTranslation();

	const statusIcon = {
		inprogress: <SyncOutlined />,
		completed: <CheckCircleOutlined />,
		draft: <EditOutlined />,
	};

	return (
		<Card
			bordered={false}
			className={`search-card ${
				mission?.status &&
				mission.status === "completed" &&
				"mission-card-completed"
			}`}
			hoverable
			onClick={onClick}
			cover={
				mission !== null && mission.image ? (
					<img alt="Mission preview" src={mission.image} />
				) : (
					<img alt="Mission preview" src="/no-picture.png" />
				)
			}
		>
			<Skeleton loading={loading} active>
				{mission !== null && (
					<Meta
						title={
							<>
								<PageHeader
									title={mission.title}
									className="influencer-title-small search-card-title"
								/>
							</>
						}
						description={
							<Space wrap>
								<Tag icon={statusIcon[mission.status] || <SyncOutlined />}>
									{t(`missions.${mission.status}`)}
								</Tag>
								{mission?.participants && (
									<Tag icon={<TeamOutlined />}>
										{`${mission?.enrolled ? mission.enrolled : 0}/${
											mission.participants
										}`}
									</Tag>
								)}
								{typeof mission?.reward === "number" && (
									<Tag icon={<DollarOutlined />}>
										{`${mission.reward} ${mission.currency}`}
									</Tag>
								)}
							</Space>
						}
					/>
				)}
			</Skeleton>
		</Card>
	);
};

export default MissionCard;
