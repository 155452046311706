import { Routes, Route } from "react-router-dom";

import useTranslation from "../../intl/useTranslation";
import Title from "../layout/Title";
import TabNav from "../navigation/TabNav";
import Waiting from "./Waiting";
import Active from "./Active";

const Influencers = () => {
	const { t } = useTranslation();

	const routes = [
		{
			label: t("influencers.active"),
			key: "",
			to: "",
		},
		{
			label: t("influencers.waiting"),
			key: "waiting",
			to: "waiting",
		},
		{
			label: t("influencers.boarding"),
			key: "boarding",
			to: "boarding",
		},
	];

	return (
		<>
			<Title
				extra={<TabNav routes={routes} classNames="spacer" />}
				title={t("influencers.pageTitle")}
			/>
			<Routes>
				<Route path={`waiting/*`} element={<Waiting />} />
				<Route path={`boarding/*`} element={<Waiting boarding />} />
				<Route path={`*`} element={<Active />} />
			</Routes>
		</>
	);
};

export default Influencers;
