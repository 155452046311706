import { useState } from "react";
import {
	Row,
	Drawer,
	Col,
	Divider,
	Pagination,
	Space,
	Typography,
	Tag,
} from "antd";

import { useLocation, useNavigate } from "react-router-dom";

import SearchCard from "./SearchCard";
import ActiveInfluencer from "./ActiveInfluencer";
import NotFound from "./NotFound";
import SearchInfluencers from "./SearchInfluencers";
import returnStatValue from "../../lib/returnStatValue";

const { Text } = Typography;

const Active = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const [influencers, setInfluencers] = useState(null);
	const [audience, setAudience] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalResults, setTotalResults] = useState(0);
	const [pageSize, setPageSize] = useState(50);

	const matchActive = () => typeof location.pathname.split("/")[2] === "string";

	const toggleInfluencer = (influencer) => {
		if (audience.findIndex((inf) => inf.id === influencer.id) !== -1) {
			setAudience(audience.filter((inf) => inf.id !== influencer.id));
		} else {
			setAudience([...audience, influencer]);
		}
	};

	const changePage = (page, pageSize) => {
		setCurrentPage(page);
		setPageSize(pageSize);
	};

	const searchInfluencer = (
		<SearchInfluencers
			setInfluencers={setInfluencers}
			staticCriteria="status:active"
			currentPage={currentPage}
			setTotalResults={setTotalResults}
			pageSize={pageSize}
			audience={audience}
			setAudience={setAudience}
			toggleInfluencer={toggleInfluencer}
		/>
	);

	if (influencers === null)
		return (
			<>
				{searchInfluencer}
				<Row gutter={[16, 16]}>
					{[...Array(20)].map((item, key) => (
						<Col key={key} xs={24} sm={12} md={12} lg={8} xl={6}>
							<SearchCard loading={true} />
						</Col>
					))}
				</Row>
			</>
		);

	if (Array.isArray(influencers) && influencers.length === 0) {
		return (
			<>
				{searchInfluencer}
				<Divider />
				<NotFound />
			</>
		);
	}

	return (
		<>
			{searchInfluencer}
			<Divider />
			<Space
				direction="vertical"
				size={16}
				style={{ minWidth: "100%", maxWidth: "100%", paddingBottom: 16 }}
			>
				<Row gutter={[16, 16]}>
					{influencers.map((influencer, key) => (
						<Col key={key} xs={24} sm={12} md={12} lg={8} xl={6}>
							<SearchCard
								selected={
									audience !== null &&
									audience.findIndex((inf) => inf.id === influencer.id) !== -1
								}
								influencer={influencer}
								onClick={() =>
									audience === null
										? navigate(influencer.id)
										: toggleInfluencer(influencer)
								}
							/>
						</Col>
					))}
				</Row>
				<Space>
					<Pagination
						total={totalResults}
						onChange={changePage}
						current={currentPage}
						pageSize={pageSize}
						showSizeChanger
					/>
					<Tag className="total-influencers">
						<Space>
							<Text strong>{returnStatValue(totalResults)}</Text>
							<Text>Influencers</Text>
						</Space>
					</Tag>
				</Space>
			</Space>
			<Drawer
				placement="right"
				width=""
				closable={false}
				open={matchActive()}
				className="influencer-details mobile-drawer"
			>
				<ActiveInfluencer influencerId={location.pathname.split("/")[2]} />
			</Drawer>
		</>
	);
};

export default Active;
