import {
	Space,
	Typography,
	Button,
	Tag,
	Collapse,
	PageHeader,
	Descriptions,
} from "antd";
import { collection, onSnapshot, query } from "firebase/firestore";
import db, { fbFunctions } from "../../data/Firebase";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import useTranslation from "../../intl/useTranslation";
import { editManagers, getManagers } from "./managersSlice";
import EditManager from "./EditManager";
import { parsePhoneNumber } from "libphonenumber-js";
import { httpsCallable } from "firebase/functions";

const { Title } = Typography;
const { Panel } = Collapse;

const emptyManager = {
	firstname: "",
	lastname: "",
	email: "",
	phoneNumber: "",
	role: "manager",
	language: "fr",
	password: "",
};

const Managers = ({ success, error }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const managers = useSelector(getManagers);
	const [editManager, setEditManager] = useState(null);
	const [deleteLoading, setDeleteLoading] = useState(false);

	useEffect(() => {
		const managersQuery = query(collection(db, "managers"));
		onSnapshot(managersQuery, (querySnapshot) => {
			const managers = [];
			querySnapshot.forEach((doc) => {
				managers.push({ ...doc.data(), id: doc.id });
			});
			dispatch(editManagers(managers));
		});
	}, [dispatch]);

	const deleteManager = async (managerId) => {
		setDeleteLoading(true);
		try {
			const deleteManager = httpsCallable(fbFunctions, "deleteManager");
			await deleteManager({
				managerId,
			});
			success("Manager");
			setEditManager(null);
		} catch (e) {
			error();
		}
		setDeleteLoading(false);
	};

	if (managers === null) return <>Loading</>;

	return (
		<Space direction="vertical" size={24} className="settings-wrapper">
			<div className="settings-section-button">
				<Button onClick={() => setEditManager(emptyManager)} type="ghost">
					{t("settings.addManager")}
				</Button>
			</div>
			<Collapse bordered={false} accordion className="payments-list">
				{managers.map((manager, index) => (
					<Panel
						header={
							<Space wrap size={24} className="payment-title-space">
								<Title className="payment-title" level={5}>
									{`${manager.firstname} ${manager.lastname}`}
								</Title>
								<div className="payment-title-details-wrapper">
									<div className="payment-title-spacer" />
									<Tag className={`mission-tag task-tag title-tag`}>
										<PageHeader
											title={
												manager.role.charAt(0).toUpperCase() +
												manager.role.slice(1)
											}
											className="task-title-small metrics-title"
										/>
									</Tag>
								</div>
							</Space>
						}
						key={index}
					>
						<Descriptions
							bordered
							title={t("settings.details")}
							size="small"
							extra={
								<Space>
									<Button
										onClick={() => deleteManager(manager.id)}
										loading={deleteLoading}
									>
										{t("settings.delete")}
									</Button>
									<Button
										onClick={() =>
											setEditManager({
												...manager,
												phoneNumber: parsePhoneNumber(
													manager.phoneNumber
												).formatInternational(),
											})
										}
									>
										{t("settings.edit")}
									</Button>
								</Space>
							}
						>
							<Descriptions.Item label={t("settings.firstname")}>
								{manager.firstname}
							</Descriptions.Item>
							<Descriptions.Item label={t("settings.lastname")}>
								{manager.lastname}
							</Descriptions.Item>
							<Descriptions.Item label={t("clients.labelPhone")}>
								{manager.phoneNumber}
							</Descriptions.Item>
							<Descriptions.Item label={t("settings.emailTitle")}>
								{manager.email}
							</Descriptions.Item>
						</Descriptions>
					</Panel>
				))}
			</Collapse>
			<EditManager
				manager={editManager}
				success={success}
				error={error}
				setEditManager={setEditManager}
			/>
		</Space>
	);
};

export default Managers;
