const getEnvironment = () => {
	const hostname = window.location.hostname;
	if (hostname === "preview.app.socialife.ch") {
		return "staging";
	} else if (hostname === "app.socialife.ch") {
		return "production";
	} else {
		return "development"; // Default or for other cases
	}
};

export default getEnvironment;
