import { useEffect, useState, useCallback } from "react";
import { Input, Space, Row, Col, Collapse, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { influencersIndex as index } from "../../data/Algolia";

import useTranslation from "../../intl/useTranslation";
import FiltersGeneral from "./FiltersGeneral";
import FiltersSmallNetwork from "./FiltersSmallNetwork";
import Audience from "./Audience";
import FiltersLocation from "./FiltersLocation";

const { Panel } = Collapse;

const SearchInfluencers = ({
	setInfluencers,
	staticCriteria,
	currentPage,
	setTotalResults,
	pageSize,
	audience,
	setAudience,
	toggleInfluencer,
}) => {
	const [search, setSearch] = useState({ query: "", filters: {} });
	const [searchInput, setSearchInput] = useState("");
	const [interests, setInterests] = useState(null);
	const [languages, setLanguages] = useState(null);
	const [age, setAge] = useState(null);
	const [countries, setCountries] = useState(null);
	const [states, setStates] = useState(null);
	const [cities, setCities] = useState(null);
	const [friends, setFriends] = useState(null);
	const [connections, setConnections] = useState(null);
	const [instagramFollowers, setInstagramFollowers] = useState(null);
	const [tiktokFollowers, setTiktokFollowers] = useState(null);
	const [youtubeFollowers, setYoutubeFollowers] = useState(null);

	const { t } = useTranslation();

	const onChangeSearchQuery = (e) => setSearchInput(e.target.value);

	const formatFilters = useCallback(
		() =>
			staticCriteria +
			(Object.keys(search.filters).length > 0 ? " AND " : "") +
			Object.keys(search.filters)
				.map((filter) =>
					Array.isArray(search.filters[filter].value) &&
					search.filters[filter].operator === "<>"
						? `${filter}:${search.filters[filter].value[0]} TO ${search.filters[filter].value[1]}`
						: Array.isArray(search.filters[filter].value) &&
						  search.filters[filter].value.length > 0
						? search.filters[filter].value
								.map(
									(element) =>
										filter +
										search.filters[filter].operator +
										(typeof element === "string" ? `"${element}"` : element)
								)
								.join(" AND ")
						: filter +
						  search.filters[filter].operator +
						  (typeof search.filters[filter].value === "string"
								? `"${search.filters[filter].value}"`
								: search.filters[filter].value)
				)
				.join(" AND "),
		[search.filters, staticCriteria]
	);

	const onChangeFilter = (key, value, operator) => {
		const copySearch = { ...search, filters: { ...search.filters } };
		if (Array.isArray(value) && value.length === 0) {
			delete copySearch.filters[key];
		} else {
			copySearch.filters[key] = { value: value, operator: operator };
		}
		setSearch({
			...copySearch,
		});
	};

	const deleteFilter = (key) => {
		const copySearch = { ...search, filters: { ...search.filters } };
		delete copySearch.filters[key];
		setSearch({ ...copySearch, filters: { ...copySearch.filters } });
	};

	const queryAloglia = useCallback(async () => {
		const searchResult = await index.search(search.query, {
			page: currentPage - 1,
			hitsPerPage: pageSize,
			filters: formatFilters(),
		});
		setTotalResults(searchResult.nbHits);
		setInfluencers(Object.values(searchResult.hits));
	}, [
		formatFilters,
		search.query,
		setInfluencers,
		currentPage,
		pageSize,
		setTotalResults,
	]);

	const loadFacets = useCallback(async () => {
		const interests = await index.searchForFacetValues(
			"interests",
			search.query,
			{
				filters: formatFilters(),
			}
		);
		const languages = await index.searchForFacetValues(
			"language",
			search.query,
			{
				filters: formatFilters(),
			}
		);
		const countries = await index.searchForFacetValues(
			"country",
			search.query,
			{
				filters: formatFilters(),
			}
		);
		const states = await index.searchForFacetValues("state", search.query, {
			filters: formatFilters(),
		});
		const cities = await index.searchForFacetValues("city", search.query, {
			filters: formatFilters(),
		});

		setInterests(Object.values(interests.facetHits));
		setLanguages(Object.values(languages.facetHits));
		setCountries(Object.values(countries.facetHits));
		setStates(Object.values(states.facetHits));
		setCities(Object.values(cities.facetHits));
	}, [formatFilters, search.query]);

	useEffect(() => {
		queryAloglia();
		loadFacets();
	}, [search, loadFacets, queryAloglia]);

	useEffect(() => {
		queryAloglia();
	}, [queryAloglia, currentPage, pageSize]);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (search.query !== searchInput)
				setSearch({ ...search, query: searchInput });
		}, 400);

		return () => clearTimeout(delayDebounceFn);
	}, [searchInput, search]);

	useEffect(() => {
		if (interests === null || languages === null) loadFacets();
	}, [loadFacets, interests, languages]);

	const Filters = () => (
		<Row gutter={[10, 10]}>
			<Col md={6} xs={24} sm={24}>
				<FiltersGeneral
					search={search}
					onChangeFilter={onChangeFilter}
					interests={interests}
					languages={languages}
					age={age}
					setAge={setAge}
				/>
			</Col>
			<Col md={6} xs={24} sm={24}>
				<FiltersLocation
					search={search}
					onChangeFilter={onChangeFilter}
					countries={countries}
					states={states}
					cities={cities}
				/>
			</Col>
			<Col md={6} xs={24} sm={24}>
				<Space className="filter-space" direction="vertical">
					<FiltersSmallNetwork
						network="instagram"
						search={search}
						deleteFilter={deleteFilter}
						onChangeFilter={onChangeFilter}
						followers={instagramFollowers}
						setFollowers={setInstagramFollowers}
						followersMin={100}
						followersMax={100000}
					/>
					<FiltersSmallNetwork
						network="tiktok"
						search={search}
						deleteFilter={deleteFilter}
						onChangeFilter={onChangeFilter}
						followers={tiktokFollowers}
						setFollowers={setTiktokFollowers}
						followersMin={100}
						followersMax={100000}
					/>
					<FiltersSmallNetwork
						network="youtube"
						search={search}
						deleteFilter={deleteFilter}
						onChangeFilter={onChangeFilter}
						followers={youtubeFollowers}
						setFollowers={setYoutubeFollowers}
						followersMin={1000}
						followersMax={500000}
					/>
				</Space>
			</Col>
			<Col md={6} xs={24} sm={24}>
				<Space className="filter-space" direction="vertical">
					<FiltersSmallNetwork
						network="linkedin"
						search={search}
						deleteFilter={deleteFilter}
						onChangeFilter={onChangeFilter}
						followers={connections}
						setFollowers={setConnections}
						followersMin={100}
						followersMax={20000}
					/>
					<FiltersSmallNetwork
						network="facebook"
						search={search}
						deleteFilter={deleteFilter}
						onChangeFilter={onChangeFilter}
						followers={friends}
						setFollowers={setFriends}
						followersMin={100}
						followersMax={8000}
					/>
				</Space>
			</Col>
		</Row>
	);

	return (
		<Space direction="vertical" className="search-wrapper" size={16}>
			<Input
				size="large"
				placeholder={t("influencers.searchInfluencers")}
				className="search-input"
				suffix={<SearchOutlined />}
				onChange={onChangeSearchQuery}
			/>

			<Collapse accordion>
				<Panel header={t("influencers.filters")} key="1">
					<Filters />
				</Panel>
				<Panel
					header={
						<>
							{t("influencers.audience")}{" "}
							{audience !== null && (
								<Tag style={{ marginLeft: 12 }}>{audience.length}</Tag>
							)}
						</>
					}
					key="2"
				>
					<Audience
						toggleInfluencer={toggleInfluencer}
						audience={audience}
						setAudience={setAudience}
					/>
				</Panel>
			</Collapse>
		</Space>
	);
};

export default SearchInfluencers;
