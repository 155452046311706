import { Input, Space, Typography, Button, Form } from "antd";
import {
	EmailAuthProvider,
	reauthenticateWithCredential,
	updatePassword,
} from "firebase/auth";
import { auth } from "../../data/Firebase";
import { useState } from "react";
import { useSelector } from "react-redux";
import useTranslation from "../../intl/useTranslation";
import { getUser } from "../login/loginSlice";

const { Title } = Typography;
const { Password } = Input;

const ChangePassword = ({ success, error }) => {
	const { t } = useTranslation();
	const user = useSelector(getUser);
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const changePassword = async ({ oldPassword, newPassword }) => {
		try {
			setLoading(true);
			await reauthenticateWithCredential(
				auth.currentUser,
				EmailAuthProvider.credential(user.email, oldPassword)
			);
			await updatePassword(auth.currentUser, newPassword);
			form.resetFields();
			setLoading(false);
			success(t("settings.passwordTitle"));
		} catch (error) {
			setLoading(false);
			error();
		}
	};

	return (
		<Form form={form} onFinish={changePassword}>
			<Space className="settings-section" direction="vertical" size={12}>
				<Title level={5}>{t("settings.passwordTitle")}</Title>
				<Space
					direction="horizontal"
					size={32}
					className="settings-section-space"
					wrap
				>
					<Form.Item
						name="oldPassword"
						rules={[{ required: true, message: t("login.errorPassword") }]}
					>
						<Password
							size="large"
							type="password"
							className="settings-input"
							placeholder={t("settings.oldPassword")}
						/>
					</Form.Item>
					<Form.Item
						name="newPassword"
						rules={[{ required: true, message: t("login.errorPassword") }]}
					>
						<Password
							size="large"
							type="password"
							className="settings-input"
							placeholder={t("settings.newPassword")}
						/>
					</Form.Item>
				</Space>
				<div className="settings-section-button">
					<Form.Item>
						<Button type="primary" htmlType="submit" loading={loading}>
							{t("settings.changePassword")}
						</Button>
					</Form.Item>
				</div>
			</Space>
		</Form>
	);
};

export default ChangePassword;
