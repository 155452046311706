import { useSelector } from "react-redux";
import { Card, Skeleton, PageHeader, Tag, Space, Avatar } from "antd";
import {
	MailOutlined,
	PhoneOutlined,
	EnvironmentOutlined,
	DollarCircleOutlined,
} from "@ant-design/icons";
import { parsePhoneNumber } from "libphonenumber-js";

import countries from "../../intl/countries";
import { getUser } from "../login/loginSlice";

const { Meta } = Card;

const displayAttribute = [
	{ key: "email", icon: <MailOutlined /> },
	{ key: "phone", icon: <PhoneOutlined /> },
	{ key: "country", icon: <EnvironmentOutlined /> },
];

const ClientCard = ({ client = null, loading = false, onClick = null }) => {
	const user = useSelector(getUser);

	return (
		<Card bordered={false} className="search-card" hoverable onClick={onClick}>
			<Skeleton loading={loading} active avatar>
				<Meta
					title={
						<PageHeader
							title={client !== null && client.name}
							className="influencer-title-small search-card-title"
						/>
					}
					avatar={
						<Avatar
							size="large"
							src={client !== null && client.avatar !== "" && client.avatar}
						/>
					}
					description={
						<Space wrap>
							{client !== null && client.hasOwnProperty("deals") && (
								<Tag icon={<DollarCircleOutlined />}>
									{`${client.deals} Deals`}
								</Tag>
							)}
							{displayAttribute.map(
								(attribute, key) =>
									client !== null &&
									client.hasOwnProperty(attribute.key) && (
										<Tag key={key} icon={attribute.icon}>
											{attribute.key === "country"
												? countries.getName(
														client[attribute.key],
														user.language,
														{
															select: "official",
														}
												  )
												: attribute.key === "phone"
												? parsePhoneNumber(
														client[attribute.key]
												  ).formatInternational()
												: client[attribute.key]}
										</Tag>
									)
							)}
						</Space>
					}
				/>
			</Skeleton>
		</Card>
	);
};

export default ClientCard;
