import flatten from "flat";

import login from "./login.json";
import apply from "./apply.json";
import settings from "./settings.json";
import influencers from "./influencers.json";
import onboarding from "./onboarding.json";
import influencer from "./influencer.json";
import notifications from "./notifications.json";
import rewards from "./rewards.json";
import clients from "./clients.json";
import missions from "./missions.json";
import layout from "./layout.json";
import payments from "./payments.json";
import reset from "./reset.json";

const fr = {
	login,
	apply,
	settings,
	influencers,
	onboarding,
	influencer,
	notifications,
	rewards,
	clients,
	missions,
	layout,
	payments,
	reset,
};

export default flatten(fr);
