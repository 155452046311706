import algoliasearch from "algoliasearch/lite";

const client = algoliasearch("7MV8QR81JY", "0787e8e7ee71043e93a3760ff7ef75ca");
const influencersIndex = client.initIndex("Influencers");
const clientsIndex = client.initIndex("Clients");
const audiencesIndex = client.initIndex("Audiences");
const missionsIndex = client.initIndex("Missions");
const paymentsIndex = client.initIndex("Payments");

export {
	missionsIndex,
	audiencesIndex,
	influencersIndex,
	clientsIndex,
	paymentsIndex,
	client,
};
