import {
	Avatar,
	PageHeader,
	Space,
	Tag,
	Typography,
	Collapse,
	Button,
	Modal,
	Select,
	Rate,
	Input,
	Radio,
	Alert,
} from "antd";
import {
	ClockCircleOutlined,
	CheckCircleOutlined,
	LinkOutlined,
	CheckCircleFilled,
	PlusOutlined,
	UserOutlined,
	CloseCircleOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

import useTranslation from "../../intl/useTranslation";
import moment from "moment";
import { useState } from "react";

import { influencersIndex as index } from "../../data/Algolia";
import { httpsCallable } from "firebase/functions";
import { fbFunctions } from "../../data/Firebase";
import StatsChart from "./StatsChart";
import returnStatValue from "../../lib/returnStatValue";
import networks from "../../data/networks";
import profileUrl from "../../lib/profileUrl.json";
import TaskReminder from "./TaskReminder";

const { Title, Text } = Typography;
const { Panel } = Collapse;
const { Button: RadioButton, Group } = Radio;
const { TextArea } = Input;

const MissionAdminParticipants = ({ mission }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [addOpen, setAddOpen] = useState(false);
	const [addLoading, setAddLoading] = useState(false);
	const [influencersResults, setInfluencersResults] = useState([]);
	const [influencers, setInfluencers] = useState([]);
	const [searchValue, setSearchValue] = useState("");
	const [validateMissionLoading, setValidateMissionLoading] = useState(false);
	const [ratingOpen, setRatingOpen] = useState(false);
	const [influencerRate, setInfluencerRate] = useState(null);
	const [customAmount, setCustomAmount] = useState(0);
	const [tasksRemarks, setTasksRemarks] = useState({});

	const searchInfluencers = async (value) => {
		setSearchValue(value);
		setInfluencersResults([
			...Object.values(
				(await index.search(value, { filters: "status:active" })).hits
			)
				.filter(
					(influencer) =>
						!mission.influencers
							.map((inf) => inf.id)
							.includes(influencer.objectID)
				)
				.map((influencer: any) => ({
					label: (
						<>
							<Avatar
								src={influencer.avatar}
								size="small"
								icon={<UserOutlined />}
							/>
							{` ${influencer.firstname} ${influencer.lastname}`}
						</>
					),
					value: influencer.objectID,
				})),
		]);
	};

	const onChangeInfluencers = (value) => {
		setInfluencers([...value]);
	};

	const addInfluencers = async () => {
		setAddLoading(true);
		const applyFirebase = httpsCallable(fbFunctions, "inviteInfluencers");
		await applyFirebase({
			influencersId: influencers,
			missionId: mission.id,
		});
		setAddLoading(false);
		setAddOpen(false);
	};

	const validateMission = async (influencerId) => {
		setValidateMissionLoading(true);
		const applyFirebase = httpsCallable(fbFunctions, "validateMission");
		await applyFirebase({
			influencerId: influencerId,
			missionId: mission.id,
			rate: influencerRate,
			amount: customAmount,
			tasksRemarks: tasksRemarks,
		});
		setInfluencerRate(null);
		setRatingOpen(false);
		setValidateMissionLoading(false);
	};

	const onCheckTask = (taskId, value) => {
		if (!value) {
			setTasksRemarks({ ...tasksRemarks, [taskId]: "" });
		} else {
			const newTasksRemarks = { ...tasksRemarks };
			delete newTasksRemarks[taskId];
			setTasksRemarks(newTasksRemarks);
		}
	};

	const onChangeRemark = (taskId, value) =>
		setTasksRemarks({ ...tasksRemarks, [taskId]: value });

	return (
		<Space direction="vertical" className="participants-wrapper" size={16}>
			<Button onClick={(e) => setAddOpen(true)} icon={<PlusOutlined />}>
				{t("missions.addParticipant")}
			</Button>
			<Collapse bordered={false} accordion className="mission-tasks">
				{mission.influencers.map((participant, index) => (
					<Panel
						header={
							<>
								<Avatar
									size="small"
									className="participants-avatar"
									src={participant.avatar}
								/>
								<Title className="task-title" level={5}>
									{`${participant.firstname} ${participant.lastname}`}
								</Title>
								<Tag
									className={
										"mission-tag task-tag title-tag " +
										(participant.status === "declined" &&
											"mission-tag-declined") +
										" " +
										(participant.status === "invited" && "mission-tag-invited")
									}
								>
									<PageHeader
										title={
											participant.status === "invited"
												? t("missions.participantInvited")
												: participant.status === "declined"
												? t("missions.participantDeclined")
												: participant.status === "completed"
												? t("missions.completed")
												: participant.status === "answered"
												? t("missions.answered")
												: participant.status === "validating"
												? t("missions.validating")
												: participant.tasks.reduce(
														(last, task) =>
															task.status === "completed" ? last + 1 : last,
														0
												  ) +
												  "/" +
												  participant.tasks.length
										}
										className="task-title-small metrics-title"
									/>
								</Tag>
							</>
						}
						key={index}
					>
						<Space
							direction="vertical"
							size={8}
							className="participants-wrapper"
						>
							<Space>
								{participant?.networks &&
									Object.keys(participant.networks).map((network) => (
										<Button
											target="_BLANK"
											rel="noreferrer"
											key={network}
											href={
												network === "facebook" || network === "linkedin"
													? participant.networks[network]
													: profileUrl[network] + participant.networks[network]
											}
										>
											{networks.find((net) => net.id === network).label}
										</Button>
									))}
								<Button
									onClick={() => navigate(`/influencers/${participant.id}`)}
									icon={<LinkOutlined />}
								>
									Profile
								</Button>
							</Space>
							{mission.type === "proposal" && mission?.questions ? (
								participant.questions.map((question, index) => (
									<Space
										key={index}
										className="question-wrapper"
										direction="vertical"
										size={8}
									>
										<Title level={5}>{mission.questions[index].question}</Title>
										{question.answer ? question.answer : t("missions.noAnswer")}
									</Space>
								))
							) : (
								<Collapse
									bordered={false}
									accordion
									className="participants-tasks"
								>
									{participant.tasks.map((task, index) => (
										<Panel
											header={
												<Space direction="vertical" style={{ width: "100%" }}>
													<span>
														<Avatar
															size="small"
															className="participants-avatar"
															style={{
																backgroundColor:
																	task.status === "completed"
																		? "#3e9812"
																		: task.status === "refused" && "#8c0000",
															}}
															icon={
																task.status === "pending" ? (
																	<ClockCircleOutlined />
																) : task.status === "refused" ? (
																	<CloseCircleOutlined />
																) : (
																	<CheckCircleOutlined />
																)
															}
														/>
														<Title className="task-title" level={5}>
															{`${t("missions.taskTitle")} #${index + 1}`}
														</Title>
														<Tag className="mission-tag task-tag title-tag">
															<PageHeader
																title={
																	task.status === "pending"
																		? t("missions.participantPending")
																		: moment
																				.unix(task.date)
																				.format("DD/MM/YY HH:mm")
																}
																className="task-title-small metrics-title"
															/>
														</Tag>
													</span>
													{task.remark && (
														<Alert
															description={
																task.remark === ""
																	? t("missions.noRemarks")
																	: task.remark
															}
															type="error"
															showIcon
														/>
													)}
												</Space>
											}
											key={index}
										>
											<Space direction="horizontal" className="metrics-title">
												<Title level={5}>{t("missions.tasksMetrics")}</Title>
												{task.stats && (
													<Tag className="total-visists">
														<Space>
															<Text>Total</Text>
															<Text strong>
																{returnStatValue(
																	Object.values(task.stats).reduce(
																		(a: number, b: number) => a + b
																	)
																)}
															</Text>
														</Space>
													</Tag>
												)}
											</Space>
											<StatsChart
												data={
													task.stats
														? Object.keys(task.stats)
																.map((country) => ({
																	value: task.stats[country],
																	label: country,
																}))
																.sort((a, b) => a.value - b.value)
														: undefined
												}
											/>
											{task.status === "pending" && (
												<TaskReminder
													taskNumber={index + 1}
													missionId={mission.id}
													influencerId={participant.id}
												/>
											)}
										</Panel>
									))}
								</Collapse>
							)}
							{participant.status === "validating" && (
								<Button
									type="primary"
									className="btn-accept button-accept-mission"
									icon={<CheckCircleFilled />}
									disabled={participant.status !== "validating"}
									onClick={() => {
										setRatingOpen(true);
										setCustomAmount(mission.reward);
									}}
								>
									{t("missions.validateMission")}
								</Button>
							)}
							<Modal
								title={t("missions.validateInfluencerMissionTitle")}
								open={ratingOpen}
								okText={t("missions.validateMission")}
								okButtonProps={{ disabled: influencerRate === null }}
								onCancel={() => {
									setRatingOpen(false);
									setInfluencerRate(null);
									setTasksRemarks({});
								}}
								onOk={() => validateMission(participant.id)}
								confirmLoading={validateMissionLoading}
							>
								<Space direction="vertical" size={18} style={{ width: "100%" }}>
									<Space
										direction="vertical"
										size={0}
										style={{ width: "100%" }}
									>
										<Title level={5}>{t("missions.tasks")}</Title>
										<Collapse
											bordered={false}
											accordion
											className="participants-tasks"
										>
											{participant.tasks &&
												participant.tasks.map((task, index) => (
													<Panel
														header={
															<Space
																direction="vertical"
																style={{ width: "100%" }}
															>
																<span>
																	<Avatar
																		size="small"
																		className="participants-avatar"
																		style={{
																			backgroundColor:
																				task.status === "completed" &&
																				"#3e9812",
																		}}
																		icon={
																			task.status === "pending" ? (
																				<ClockCircleOutlined />
																			) : (
																				<CheckCircleOutlined />
																			)
																		}
																	/>
																	<Title className="task-title" level={5}>
																		{`${t("missions.taskTitle")} #${index + 1}`}
																	</Title>
																	<Group
																		value={
																			!(
																				typeof tasksRemarks[task?.id] ===
																				"string"
																			)
																		}
																		onChange={(e) =>
																			onCheckTask(task?.id, e.target.value)
																		}
																		className="task-tag"
																	>
																		<RadioButton value={true}>
																			<CheckCircleOutlined />
																		</RadioButton>
																		<RadioButton value={false}>
																			<CloseCircleOutlined />
																		</RadioButton>
																	</Group>
																</span>
																{typeof tasksRemarks[task?.id] === "string" && (
																	<TextArea
																		name="description"
																		size="large"
																		value={tasksRemarks[task?.id]}
																		placeholder={`${t("missions.remarks")} ${t(
																			"missions.taskTitle"
																		)} #${index + 1}`}
																		onChange={(e) =>
																			onChangeRemark(task?.id, e.target.value)
																		}
																		rows={2}
																	/>
																)}
															</Space>
														}
														key={index}
													>
														<Space
															direction="horizontal"
															className="metrics-title"
														>
															<Title level={5}>
																{t("missions.tasksMetrics")}
															</Title>
															{task.stats && (
																<Tag className="total-visists">
																	<Space>
																		<Text>Total</Text>
																		<Text strong>
																			{returnStatValue(
																				Object.values(task.stats).reduce(
																					(a: number, b: number) => a + b
																				)
																			)}
																		</Text>
																	</Space>
																</Tag>
															)}
														</Space>
														<StatsChart
															data={
																task.stats
																	? Object.keys(task.stats)
																			.map((country) => ({
																				value: task.stats[country],
																				label: country,
																			}))
																			.sort((a, b) => a.value - b.value)
																	: undefined
															}
														/>
													</Panel>
												))}
										</Collapse>
									</Space>
									<Space direction="vertical" size={0}>
										<Title level={5}>{t("missions.ratingLabel")}</Title>
										<Rate
											allowHalf
											value={influencerRate}
											onChange={(val) => setInfluencerRate(val)}
										/>
									</Space>
									<Space direction="vertical" size={0}>
										<Title level={5}>{t("missions.rewardLabel")}</Title>
										<Input
											type="number"
											placeholder="Custom amount"
											value={customAmount}
											onChange={(e) =>
												setCustomAmount(parseInt(e.target.value))
											}
											suffix={mission.currency}
											size="large"
										/>
									</Space>
								</Space>
							</Modal>
						</Space>
					</Panel>
				))}
			</Collapse>
			<Modal
				onCancel={(e) => {
					setAddOpen(false);
					setInfluencersResults([]);
					setSearchValue("");
					setInfluencers([]);
				}}
				onOk={addInfluencers}
				confirmLoading={addLoading}
				title={t("missions.addParticipant")}
				open={addOpen}
			>
				<Select
					mode="multiple"
					allowClear
					showSearch
					style={{ width: "100%" }}
					placeholder={t("missions.searchParticipant")}
					onSearch={searchInfluencers}
					searchValue={searchValue}
					options={influencersResults}
					filterOption={false}
					onChange={(value) => onChangeInfluencers(value)}
					value={influencers}
				/>
			</Modal>
		</Space>
	);
};

export default MissionAdminParticipants;
