import React from "react";
import { Layout } from "antd";

import Menu from "./Menu";

const { Header } = Layout;

const MobileNav = () => {
	return (
		<Header className="mobile-appbar">
			<Menu type="mobile" />
		</Header>
	);
};

export default MobileNav;
