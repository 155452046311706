import { Space, Typography } from "antd";
import db from "../../data/Firebase";
import { useSelector } from "react-redux";
import useTranslation from "../../intl/useTranslation";
import { getUser } from "../login/loginSlice";
import { doc, updateDoc } from "firebase/firestore";
import UploadAvatar from "../../lib/components/UploadAvatar";

const { Title } = Typography;

const ChangeAvatar = ({ success }) => {
	const { t } = useTranslation();
	const user = useSelector(getUser);

	const changeAvatar = async ({ target: { value } }) => {
		try {
			await updateDoc(doc(db, "influencers", user.uid), { avatar: value });
			success(t("settings.avatarTitle"));
		} catch (error) {
			error();
		}
	};

	return (
		<Space className="settings-section" direction="vertical" size={12}>
			<Title level={5}>{t("settings.avatarTitle")}</Title>

			<UploadAvatar
				path={"/influencers/" + user.uid}
				img={user.avatar ? user.avatar : "/no-avatar.png"}
				setImg={changeAvatar}
			/>
		</Space>
	);
};

export default ChangeAvatar;
