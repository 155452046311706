import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { httpsCallable } from "firebase/functions";
import {
	collection,
	query,
	where,
	limit,
	onSnapshot,
} from "firebase/firestore";

import { Row, Drawer, Modal, Typography, PageHeader } from "antd";

import db, { fbFunctions } from "../../data/Firebase";
import {
	editWaitingInfluencers,
	getWaitingInfluencers,
} from "./influencersSlice";
import { useLocation, useNavigate } from "react-router-dom";
import useTranslation from "../../intl/useTranslation";
import WaitingInfluencer from "./WaitingInfluencer";
import InfluencerCard from "./InfluencerCard";
import NotFound from "./NotFound";

const { Text } = Typography;

const Waiting = ({ boarding = false }) => {
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const influencers = useSelector(getWaitingInfluencers);

	const [accept, setAccept] = useState(null);
	const [reject, setReject] = useState(null);
	const [accepting, setAccepting] = useState("");
	const [rejecting, setRejecting] = useState("");

	const matchInfluencer = () => {
		if (typeof location.pathname.split("/")[3] === "undefined") return false;
		const influencerId = location.pathname.split("/")[3];
		return (
			Array.isArray(influencers) &&
			influencers.findIndex((inf) => inf.id === influencerId) !== -1
		);
	};

	const acceptInfluencer = async (influencer) => {
		setAccepting(influencer.id);
		setAccept(null);
		const onBoard = httpsCallable(fbFunctions, "onBoardInfluencer");
		await onBoard({ influencerId: influencer.id });
		navigate("/influencers/waiting");
		setAccepting("");
	};

	const rejectInfluencer = async (influencer) => {
		setRejecting(influencer.id);
		setReject(null);
		const reject = httpsCallable(fbFunctions, "rejectInfluencer");
		await reject({ influencerId: influencer.id });
		navigate("/influencers/waiting");
		setRejecting("");
	};

	const influencersLimit = 100;

	useEffect(() => {
		const influencerQuery = query(
			collection(db, "influencers"),
			where("status", "==", boarding ? "boarding" : "waiting"),
			limit(influencersLimit)
		);
		onSnapshot(influencerQuery, (querySnapshot) => {
			const waitingInfluencers = [];
			querySnapshot.forEach((doc) => {
				waitingInfluencers.push({ ...doc.data(), id: doc.id });
			});
			dispatch(editWaitingInfluencers(waitingInfluencers));
		});
	}, [dispatch, boarding]);

	const ProfileTitle = ({ name, avatar }) => (
		<PageHeader
			title={name}
			className="influencer-title-small"
			avatar={{ src: avatar }}
		/>
	);

	if (influencers === null)
		return (
			<Row gutter={[16, 16]}>
				{[...Array(limit)].map((item, key) => (
					<InfluencerCard loading={influencers === null} key={key} />
				))}
			</Row>
		);

	if (Array.isArray(influencers) && influencers.length === 0) {
		return <NotFound />;
	}

	return (
		<>
			<Row gutter={[16, 16]}>
				{influencers.map((influencer, key) => (
					<InfluencerCard
						key={key}
						influencer={influencer}
						setAccept={() => setAccept(influencer)}
						accepting={accepting}
						rejecting={rejecting}
						setReject={() => setReject(influencer)}
						onClick={() => navigate(influencer.id)}
						boarding={boarding}
					/>
				))}
			</Row>
			<Drawer
				placement="right"
				width=""
				closable={false}
				open={matchInfluencer()}
				className="influencer-details  mobile-drawer"
			>
				<WaitingInfluencer
					influencerId={location.pathname.split("/")[3]}
					boarding={boarding}
					acceptInfluencer={acceptInfluencer}
					rejectInfluencer={rejectInfluencer}
					accepting={accepting}
					rejecting={rejecting}
				/>
			</Drawer>
			<Modal
				open={accept !== null || reject !== null}
				onOk={() =>
					accept !== null ? acceptInfluencer(accept) : rejectInfluencer(reject)
				}
				onCancel={() => {
					setAccept(null);
					setReject(null);
				}}
				okText={t("influencers.confirm")}
				cancelText={t("influencers.cancel")}
				className="influencer-confirm-modal"
			>
				<Text>{`${t("influencers.confirmQuestion")} ${t(
					"influencers." +
						(accept === null ? "rejectQuestion" : "acceptQuestion")
				)}?`}</Text>
				<ProfileTitle
					avatar={
						accept !== null
							? accept.avatar
							: reject !== null
							? reject.avatar
							: null
					}
					name={
						accept !== null
							? accept.displayName
							: reject !== null
							? reject.displayName
							: null
					}
				/>
			</Modal>
		</>
	);
};

export default Waiting;
