import {
	Typography,
	Card,
	Row,
	Col,
	Button,
	Space,
	Tag,
	PageHeader,
	Empty,
} from "antd";

import useTranslation from "../../intl/useTranslation";
import Title from "../layout/Title";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../login/loginSlice";
import { getRewards, setRewards } from "./rewardsSlice";
import { useEffect } from "react";
import db from "../../data/Firebase";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import RewardIcon from "../../icons/RewardIcon";
import { useNavigate } from "react-router-dom";
import moment from "moment";

const TitleAntd = Typography.Title;
const { Text } = Typography;

const InfluencerRewards = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const user = useSelector(getUser);
	const rewards = useSelector(getRewards);
	const navigate = useNavigate();

	useEffect(() => {
		const rewardsQuery = query(
			collection(db, "payments"),
			where("recipient.id", "==", user.uid)
		);
		onSnapshot(rewardsQuery, (querySnapshot) => {
			const rewards = [];
			querySnapshot.forEach((doc) => {
				rewards.push({ ...doc.data(), id: doc.id });
			});
			dispatch(setRewards(rewards));
		});
	}, [dispatch, user.uid]);

	const RewardsMenu = (
		<div className="settings-menu-wrapper">
			<Card className="rewards-menu" bordered={false}>
				<Space direction="vertical" size={10}>
					<Space direction="vertical" size={0}>
						<Typography>
							{t("rewards.totalRewardsTitle")} <RewardIcon />
						</Typography>
						<TitleAntd level={2}>{`${
							user.totalRewards ? user.totalRewards : 0
						} CHF`}</TitleAntd>
					</Space>
					<Button
						type="primary"
						size="middle"
						onClick={() => navigate("/settings/rewards")}
					>
						{t("rewards.labelAction")}
					</Button>
				</Space>
			</Card>
		</div>
	);

	const Reward = ({ reward }) => (
		<Row style={{ marginBottom: 0 }}>
			<Col flex="auto">
				<Space wrap size={2} className="payment-title-space">
					<TitleAntd className="payment-title" level={5}>
						{`${reward.title} - ${reward.reference}`}
					</TitleAntd>
					<div className="payment-title-details-wrapper">
						<Tag
							className={`mission-tag task-tag title-tag ${
								reward.status === "paid" && "payment-tag-paid"
							}`}
						>
							<PageHeader
								title={t(`payments.${reward.status}`)}
								className="task-title-small metrics-title"
							/>
						</Tag>
						<Text type="secondary">
							{reward.executed &&
								moment.unix(reward.executed).format("DD/MM/YY HH:mm")}
						</Text>
						<div className="payment-title-spacer" />
						<TitleAntd className="payment-title" level={5}>
							{`+${reward.amount} ${reward.currency}`}
						</TitleAntd>
					</div>
				</Space>
			</Col>
		</Row>
	);

	if (rewards === null) return <>Loading</>;

	return (
		<>
			<Title title={t("rewards.title")} />
			<Row gutter={[24, 24]}>
				<Col span={6} md={6} sm={24} xs={24}>
					{RewardsMenu}
				</Col>
				<Col span={18} md={18} sm={24} xs={24}>
					<Card className="rewards-menu" bordered={false}>
						<Row style={{ marginBottom: 0 }} className="rewards-table">
							{rewards.length === 0 ? (
								<Empty
									style={{ margin: "auto" }}
									imageStyle={{
										fontSize: 30,
										height: 70,
										display: "flex",
										justifyContent: "center",
									}}
									image={<RewardIcon />}
									description={
										<>
											<TitleAntd level={5}>{t("rewards.noRewards")}</TitleAntd>
										</>
									}
								/>
							) : (
								rewards.map((reward) => (
									<Col span={24} key={reward.title}>
										<Reward reward={reward} />
									</Col>
								))
							)}
						</Row>
					</Card>
				</Col>
			</Row>
		</>
	);
};

export default InfluencerRewards;
