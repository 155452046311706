import { Statistic, Tabs, Row, Col, Tag, Typography } from "antd";
import numeral from "numeral";
import {
	VictoryBar,
	VictoryChart,
	VictoryAxis,
	VictoryContainer,
	VictoryLabel,
	VictoryPie,
	VictoryLegend,
	VictoryGroup,
} from "victory";
import useTranslation from "../../intl/useTranslation";
import languages from "../../lib/languages";

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const chartGradient = ["#5489cf", "#6897d4", "#7ca5da", "#90b2e0", "#a4c0e5"];

const AuditReport = ({ audit, influencer }) => {
	const { t } = useTranslation();

	const audienceTypeColors = (types) =>
		Object.keys(types).map((type) => {
			switch (type) {
				case "mass":
					return "#9ba7af";
				case "infs":
					return "#4fbc7e";
				case "susp":
					return "#d0d8de";
				default:
					return "#289155";
			}
		});

	const genderColors = (genders) =>
		genders.map((gender) => (gender.gender === "male" ? "#5489cf" : "#f895ae"));

	const BarChart = ({
		data,
		height = undefined,
		width = undefined,
		paddingLeft = undefined,
	}) => (
		<VictoryChart
			containerComponent={<VictoryContainer />}
			padding={{
				left: typeof paddingLeft !== "undefined" ? paddingLeft : 170,
				top: 20,
				bottom: 50,
				right: 70,
			}}
			height={typeof height !== "undefined" ? height : 300}
			width={typeof width !== "undefined" ? width : 450}
		>
			<VictoryBar
				barRatio={1.5}
				data={data}
				x="name"
				y="value"
				horizontal
				style={{
					data: { fill: ({ index }) => chartGradient[index] },
					labels: {
						fill: "rgba(235, 227, 217, 0.85)",
						fontSize: 18,
						fontFamily:
							"Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
					},
				}}
				labels={({ datum }) => `${datum.value}%`}
				sortOrder="descending"
				sortKey="x"
			/>
			<VictoryAxis
				tickLabelComponent={
					<VictoryLabel verticalAnchor="middle" textAnchor="start" x={0} />
				}
				style={{
					tickLabels: {
						fill: "rgba(235, 227, 217, 0.85)",
						fontSize: 18,
						fontFamily:
							"Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
					},
				}}
			/>
		</VictoryChart>
	);

	const SimpleBarChart = ({ data, labelKey }) => (
		<VictoryChart
			containerComponent={<VictoryContainer />}
			height={150}
			padding={{ left: 30, top: 20, bottom: 20, right: 30 }}
		>
			<VictoryBar
				barWidth={25}
				x={labelKey}
				y="value"
				data={data}
				style={{
					data: { fill: () => chartGradient[0] },
					labels: {
						fill: "rgba(235, 227, 217, 0.85)",
						fontSize: 8,
						fontFamily:
							"Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
					},
				}}
				labels={({ datum }) => `${datum.value}%`}
			/>
			<VictoryAxis
				tickLabelComponent={<VictoryLabel />}
				tickFormat={(t) =>
					labelKey === "code"
						? languages.find((lang) => lang.code === t)?.name || t
						: t
				}
				style={{
					tickLabels: {
						fill: "rgba(235, 227, 217, 0.85)",
						fontSize: 8,
						fontFamily:
							"Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
					},
				}}
			/>
		</VictoryChart>
	);

	return (
		<Row gutter={[6, 20]}>
			<Col span={6}>
				<Statistic
					className="audit-rate rate-high"
					title={audit?.aqs_name}
					value={`${audit?.aqs}/100`}
				/>
			</Col>
			<Col span={18}>
				<Row gutter={[6, 6]}>
					{audit?.aqs_description.split(";").map((desc, key) => (
						<Col key={key}>
							<Tag className="audit-tag">{desc}</Tag>
						</Col>
					))}
				</Row>
			</Col>
			<Col lg={12} span={24}>
				<Tabs>
					<TabPane tab="Country" key="country">
						<BarChart data={audit?.audience_geography.countries} />
					</TabPane>{" "}
					{audit?.audience_geography?.cities &&
						audit?.audience_geography?.cities.length > 0 && (
							<TabPane tab="City" key="city">
								<BarChart data={audit?.audience_geography.cities} />
							</TabPane>
						)}
				</Tabs>
			</Col>
			<Col lg={12} span={24}>
				<VictoryChart>
					<VictoryLegend
						orientation="horizontal"
						gutter={20}
						itemsPerRow={2}
						colorScale={audienceTypeColors(audit?.audience_type)}
						style={{
							labels: {
								fill: "rgba(235, 227, 217, 0.85)",
								fontSize: 18,
								fontFamily:
									"Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
							},
						}}
						data={Object.keys(audit?.audience_type).map((value: string) => {
							return {
								name: `${t("influencers." + value)} ${
									audit?.audience_type[value]
								}%`,
							};
						})}
					/>
					<VictoryPie
						colorScale={audienceTypeColors(audit?.audience_type)}
						innerRadius={40}
						radius={90}
						data={Object.values(audit?.audience_type)}
						padAngle={5}
					/>
					<VictoryAxis
						style={{
							axis: { stroke: "transparent" },
							ticks: { stroke: "transparent" },
							tickLabels: { fill: "transparent" },
						}}
					/>
				</VictoryChart>
			</Col>
			<Col span={24}>
				<Tabs>
					<TabPane tab="Age & Gender" key="ageGender">
						<VictoryChart
							containerComponent={<VictoryContainer />}
							height={150}
							padding={{ left: 0, top: 40, bottom: 20, right: 0 }}
						>
							<VictoryLegend
								orientation="horizontal"
								gutter={20}
								itemsPerRow={2}
								colorScale={genderColors(audit?.demography_by_age)}
								style={{
									labels: {
										fill: "rgba(235, 227, 217, 0.85)",
										fontSize: 9,
										fontFamily:
											"Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
									},
								}}
								data={audit?.demography_by_age.map((gender: any) => {
									return {
										name: `${
											gender.gender.charAt(0).toUpperCase() +
											gender.gender.slice(1)
										} ${numeral(gender.value).format("0.0")}%`,
									};
								})}
							/>
							<VictoryGroup offset={25}>
								<VictoryBar
									barWidth={25}
									x="group"
									y="value"
									data={audit?.demography_by_age[0].by_age_group}
									style={{
										data: { fill: () => chartGradient[0] },
										labels: {
											fill: "rgba(235, 227, 217, 0.85)",
											fontSize: 8,
											fontFamily:
												"Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
										},
									}}
									labels={({ datum }) => `${datum.value}%`}
								/>
								<VictoryBar
									barWidth={25}
									x="group"
									y="value"
									data={audit?.demography_by_age[1].by_age_group}
									style={{
										data: { fill: "#f895ae" },
										labels: {
											fill: "rgba(235, 227, 217, 0.85)",
											fontSize: 8,
											fontFamily:
												"Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
										},
									}}
									labels={({ datum }) => `${datum.value}%`}
								/>
							</VictoryGroup>
							<VictoryAxis
								tickLabelComponent={<VictoryLabel />}
								tickFormat={(label: string) => label.substring("age".length)}
								style={{
									tickLabels: {
										fill: "rgba(235, 227, 217, 0.85)",
										fontSize: 8,
										fontFamily:
											"Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
									},
								}}
							/>
						</VictoryChart>
					</TabPane>
					<TabPane tab="Ethnicity" key="ethnicity">
						<SimpleBarChart labelKey="name" data={audit?.audience_ethnicity} />
					</TabPane>
					<TabPane tab="Language" key="language">
						<SimpleBarChart labelKey="code" data={audit?.audience_languages} />
					</TabPane>
				</Tabs>
			</Col>
			{audit?.audience_interests && (
				<>
					<Col lg={4} span={24}>
						<Title level={5}>Audience Top Interests</Title>
					</Col>
					<Col lg={20} span={24}>
						<BarChart
							data={JSON.parse(JSON.stringify(audit?.audience_interests))
								.map((interest) => {
									interest.value = interest.value * 100;
									return interest;
								})
								.slice(0, 5)}
							height={300}
							width={700}
							paddingLeft={240}
						/>
					</Col>
				</>
			)}
			<Col lg={4} span={24}>
				<Title level={5}>Audience Reachability</Title>
			</Col>
			<Col lg={20} span={24}>
				<Tag className="audit-tag">{audit?.audience_reachability.title}</Tag>
				<Text>
					<b>{numeral(audit?.audience_reachability.value).format("0.0")}%</b> of
					audience have less than 1,500 followings, similar accounts have{" "}
					<b>{numeral(audit?.audience_reachability.avg).format("0.0")}%</b> on
					average.
				</Text>
			</Col>
			<Col lg={4} span={24}>
				<Title level={5}>Audience Authenticity</Title>
			</Col>
			<Col lg={20} span={24}>
				<Tag className="audit-tag">{audit?.audience_authenticity.title}</Tag>
				<Text>
					<b>{numeral(audit?.audience_authenticity.value).format("0.0")}%</b> of
					audience look authentic, similar accounts have{" "}
					<b>{numeral(audit?.audience_authenticity.avg).format("0.0")}%</b> of
					authentic audience on average.
				</Text>
			</Col>
			{audit?.yearly_growth && (
				<>
					<Col lg={4} span={24}>
						<Title level={5}>Yearly Growth</Title>
					</Col>
					<Col lg={20} span={24}>
						<Tag className="audit-tag">{audit?.yearly_growth.title}</Tag>
						<Text>
							The influencer has grown by{" "}
							<b>{numeral(audit?.yearly_growth.value).format("0.0")}%</b>,
							similar accounts have{" "}
							<b>{numeral(audit?.yearly_growth.avg).format("0.0")}%</b> of
							growth on average.
						</Text>
					</Col>
				</>
			)}
			<Col lg={4} span={24}>
				<Title level={5}>Engagement Rate</Title>
			</Col>
			<Col lg={20} span={24}>
				<Tag className="audit-tag">{audit?.er.title}</Tag>
				<Text>
					<b>{numeral(audit?.er.value).format("0.0")}%</b> of audience like or
					comment the content.
				</Text>
			</Col>
		</Row>
	);
};

export default AuditReport;
