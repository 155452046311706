import { createSlice } from "@reduxjs/toolkit";

export const loginSlice = createSlice({
  name: "login",
  initialState: null,
  reducers: {
    editUser: (state, action) => {
      state = action.payload

      return state;
    }
  },
});

export const {
  editUser
} = loginSlice.actions;

export const getUser = (state) => state.user;

export default loginSlice.reducer;
