import { useSelector } from "react-redux";
import { Row, Col, Typography, Space, Avatar, Progress, Button } from "antd";
import { StarFilled, UserOutlined, PlusOutlined } from "@ant-design/icons";

import Title from "../layout/Title";
import useTranslation from "../../intl/useTranslation";
import { getUser } from "../login/loginSlice";
import returnStatValue from "../../lib/returnStatValue";
import YoutubeIcon from "../../icons/YoutubeIcon";
import TiktokIcon from "../../icons/TiktokIcon";
import LinkedinIcon from "../../icons/LinkedinIcon";
import FacebookIcon from "../../icons/FacebookIcon";
import InstagramIcon from "../../icons/InstagramIcon";
import { getCurrentLvl, getLvlProgress } from "../../lib/gameService";
import formatRating from "../../lib/formatRating";
import { activeNetworks } from "../../data/networks";
import { useNavigate } from "react-router-dom";
import DashboardMissions from "./DashboardMissions";

const TitleAntd = Typography.Title;

const Influencer = () => {
	const { t } = useTranslation();
	const influencer = useSelector(getUser);
	const navigate = useNavigate();

	const headerStats = [
		{
			name: "instagram",
			icon: <InstagramIcon className="header-color" />,
		},
		{
			name: "tiktok",
			icon: <TiktokIcon className="header-color" />,
		},
		{
			name: "youtube",
			icon: <YoutubeIcon className="header-color" />,
		},
		{
			name: "linkedin",
			icon: <LinkedinIcon className="header-color" />,
		},
		{
			name: "facebook",
			icon: <FacebookIcon className="header-color" />,
		},
	];

	const Header = (
		<Title
			title={t("influencer.title")}
			subTitle={`${t("influencer.level")} ${Math.floor(
				getCurrentLvl(influencer.xp)
			)}`}
		/>
	);

	return (
		<>
			{Header}
			<Row>
				<Col className="profile-info">
					<Row>
						<Col className="influencer-header" span={24}>
							<Space size={0} direction="vertical">
								<Progress
									className="header-level"
									type="circle"
									strokeColor={{
										"0%": influencer.color,
										//"100%": "#87d068",
										"100%": "#DD634A",
									}}
									percent={Math.floor(getLvlProgress(influencer.xp))}
									width={95}
									strokeWidth={10}
								/>
								<TitleAntd
									className="influencer-title header-color"
									level={1}
									style={{ color: influencer.color }}
								>
									{influencer.firstname}
								</TitleAntd>
								<Typography
									className="header-color"
									style={{ color: influencer.color }}
								>
									<StarFilled /> {formatRating(influencer.rating)}
								</Typography>
							</Space>
							<Avatar
								className="influencer-avatar"
								src={influencer.avatar}
								icon={<UserOutlined />}
							/>
						</Col>
						<Col span={24}>
							<Row className="header-stats" justify="space-around">
								{headerStats.map(
									(stat) =>
										influencer.hasOwnProperty(stat.name) && (
											<Col className="header-stat" key={stat.name}>
												<Space
													direction="vertical"
													size={1}
													style={{ color: influencer.color }}
												>
													{stat.icon}
													<Typography
														className="header-color"
														style={{ color: influencer.color }}
													>
														{returnStatValue(influencer[stat.name].followers)}
													</Typography>
												</Space>
											</Col>
										)
								)}
								{activeNetworks(influencer).length < 4 && (
									<Button
										onClick={() => navigate("settings/profile")}
										type="primary"
										icon={<PlusOutlined />}
									>
										{t("influencer.network")}
									</Button>
								)}
							</Row>
						</Col>
					</Row>
				</Col>
				<Col className="dashboard-content">
					<DashboardMissions />
				</Col>
			</Row>
		</>
	);
};

export default Influencer;
