import { createSlice } from "@reduxjs/toolkit";

export const clientsSlice = createSlice({
	name: "clients",
	initialState: [],
	reducers: {
		setClient: (state, action) => {
			const newClients = [...state].filter(
				(client) => client.name !== action.payload.name
			);
			return [...newClients, action.payload];
		},
	},
});

export const { setClient } = clientsSlice.actions;

export const getClients = (state) => state.clients;

export default clientsSlice.reducer;
