import moment from "moment";
import {
	Button,
	Row,
	Col,
	Space,
	Input,
	DatePicker,
	Form,
	Typography,
	Select,
	Alert,
} from "antd";
import PasswordValidator from "password-validator";
import { isValidIBAN } from "ibantools";

import useTranslation from "../../intl/useTranslation";

const { Password } = Input;
const { Item } = Form;
const { Title } = Typography;
const { Option } = Select;

const Step1 = ({ influencer, setInfluencer, activeStep, setActiveStep }) => {
	const { t } = useTranslation();

	const passwordTemplate = new PasswordValidator()
		.is()
		.min(8)
		.has()
		.uppercase()
		.has()
		.lowercase()
		.has()
		.digits()
		.has()
		.not()
		.spaces();

	const disabledDate = (current) => current > moment().subtract(18, "years");

	const validate = () =>
		influencer.firstname === "" ||
		influencer.lastname === "" ||
		!isValidIBAN(influencer.iban) ||
		influencer.address === "" ||
		influencer.zip === "" ||
		influencer.city === "" ||
		influencer.country === "" ||
		!influencer.dateOfBirth ||
		!passwordTemplate.validate(influencer.password);

	const changeValue = (e) =>
		setInfluencer({
			...influencer,
			[e.target.name]: e.target.value,
		});

	return (
		<Row className="step-content" align="middle" justify="space-around">
			<Col>
				<Space className="onboarding-form" direction="vertical" size={16}>
					<Input
						name="firstname"
						size="large"
						type="text"
						defaultValue={influencer.firstname}
						placeholder={t("onboarding.labelFirstname")}
						onChange={changeValue}
					/>
					<Input
						name="lastname"
						size="large"
						type="text"
						defaultValue={influencer.lastname}
						placeholder={t("onboarding.labelLastname")}
						onChange={changeValue}
					/>
					<DatePicker
						className="onboarding-datepick"
						name="dateOfBirth"
						size="large"
						format="DD/MM/YYYY"
						value={influencer.dateOfBirth}
						disabledDate={disabledDate}
						defaultPickerValue={moment().subtract(18, "years")}
						placeholder={t("onboarding.labelBirthday")}
						onChange={(e) =>
							changeValue({ target: { name: "dateOfBirth", value: e } })
						}
					/>
					<Item
						validateStatus={
							influencer.password &&
							!passwordTemplate.validate(influencer.password) &&
							"error"
						}
						help={
							influencer.password &&
							!passwordTemplate.validate(influencer.password) &&
							t(
								"onboarding." +
									passwordTemplate.validate(influencer.password, {
										list: true,
									})[0]
							)
						}
					>
						<Password
							placeholder={t("onboarding.labelPassword")}
							size="large"
							name="password"
							value={influencer.password}
							onChange={changeValue}
						/>
					</Item>
					<Alert message={t("onboarding.helpReward")} type="info" />
					<Space
						className="reward-prompt"
						direction="vertical"
						align="center"
						size={8}
					>
						<Title level={5}>{t("onboarding.titleReward")}</Title>
						<Form.Item
							name="iban"
							validateStatus={
								influencer.iban === ""
									? ""
									: isValidIBAN(influencer.iban)
									? "success"
									: "error"
							}
							hasFeedback
						>
							<Input
								name="iban"
								size="large"
								type="text"
								defaultValue={influencer.iban}
								placeholder={t("onboarding.labelIban")}
								onChange={(e) => {
									changeValue({
										target: {
											value: e.target.value.toUpperCase().replace(/\s/g, ""),
											name: "iban",
										},
									});
								}}
							/>
						</Form.Item>
						<Input
							name="address"
							size="large"
							type="text"
							defaultValue={influencer.address}
							placeholder={t("onboarding.labelAddress")}
							onChange={changeValue}
						/>
						<Space>
							<Input
								name="zip"
								size="large"
								type="text"
								defaultValue={influencer.zip}
								placeholder={t("onboarding.labelZip")}
								onChange={changeValue}
							/>
							<Input
								name="city"
								size="large"
								type="text"
								defaultValue={influencer.city}
								placeholder={t("onboarding.labelCity")}
								onChange={changeValue}
							/>
						</Space>
						<Select
							placeholder={t("onboarding.labelCountry")}
							onChange={(value) =>
								changeValue({ target: { name: "country", value: value } })
							}
							value={influencer.country === "" ? undefined : influencer.country}
							size="large"
							className="onboarding-select"
						>
							<Option value="ch">
								{t("onboarding.labelCountrySwitzerland")}
							</Option>
							<Option value="fr">{t("onboarding.labelCountryFrance")}</Option>
						</Select>
					</Space>

					<Button
						size="large"
						type="primary"
						disabled={validate()}
						onClick={() => setActiveStep(activeStep + 1)}
					>
						{t("onboarding.next")}
					</Button>
				</Space>
			</Col>
		</Row>
	);
};

export default Step1;
