import { Card, Typography, PageHeader, Tag, Space, Avatar } from "antd";
import {
	SyncOutlined,
	DollarOutlined,
	CheckCircleOutlined,
	ClockCircleOutlined,
} from "@ant-design/icons";
import useTranslation from "../../intl/useTranslation";
import stripHtml from "../../lib/stripHtml";

const { Meta } = Card;
const { Title, Paragraph } = Typography;

const MissionCardInfluencer = ({ mission, onClick = null }) => {
	const { t } = useTranslation();

	return (
		<Card
			className={`mission-card ${
				mission.status === "completed" && "mission-card-completed"
			}`}
			bordered={false}
			cover={
				<img
					src={mission.image}
					className="mission-cover"
					alt="mission cover"
				/>
			}
			hoverable
			onClick={(_) => onClick()}
		>
			<Meta
				title={
					<Title ellipsis level={5}>
						{mission.title}
					</Title>
				}
				description={
					<Paragraph ellipsis={{ rows: 2 }}>
						{stripHtml(mission.description)}
					</Paragraph>
				}
			/>
			<Space>
				<Tag className="mission-tag">
					{mission.influencers[0].status === "completed" ? (
						<Avatar
							icon={<CheckCircleOutlined className="timeline-icon" />}
							style={{ backgroundColor: "#3e9812" }}
						/>
					) : mission.influencers[0].status === "validating" ? (
						<Avatar icon={<ClockCircleOutlined />} />
					) : mission.influencers[0].status === "invited" ? (
						<Avatar icon={<ClockCircleOutlined />} />
					) : (
						<Avatar icon={<SyncOutlined />} />
					)}
					<PageHeader
						title={t(
							`missions.${
								mission.influencers[0].status === "validating"
									? "validating"
									: mission.influencers[0].status === "invited"
									? "participantInvited"
									: mission.influencers[0].status === "completed"
									? "completed"
									: mission.status
							}`
						)}
						className="influencer-title-small metrics-title"
					/>
				</Tag>
				<Tag className="mission-tag">
					<Avatar icon={<DollarOutlined />} />
					<PageHeader
						title={`${mission.reward} ${mission.currency}`}
						className="influencer-title-small metrics-title"
					/>
				</Tag>
			</Space>
		</Card>
	);
};

export default MissionCardInfluencer;
