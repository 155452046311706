import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, message, Modal, PageHeader, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { fbFunctions } from "../../data/Firebase";
import useTranslation from "../../intl/useTranslation";
import removeTrailingParam from "../../lib/removeTrailingParam";

const { Text } = Typography;

const DeleteInfluencer = ({ influencer }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const location = useLocation();

	const [deleting, setDeleting] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);

	const deleteInfluencer = async () => {
		setDeleting(true);
		const deleteFunction = httpsCallable(fbFunctions, "deleteInfluencer");
		await deleteFunction({ influencerId: influencer.id });
		setDeleting(false);
		message.success(t("influencers.deleteSuccess"));
		navigate(removeTrailingParam(location.pathname));
	};

	return (
		<>
			<Button
				type="primary"
				className="btn-reject"
				onClick={() => setDeleteModal(true)}
				loading={deleting}
			>
				{t("influencers.delete")}
			</Button>
			<Modal
				open={deleteModal}
				onOk={deleteInfluencer}
				confirmLoading={deleting}
				onCancel={() => setDeleteModal(false)}
				okText={t("influencers.confirm")}
				cancelText={t("influencers.cancel")}
				className="influencer-confirm-modal"
			>
				<Text>{t("influencers.delete")}</Text>
				<PageHeader
					title={
						influencer.firstname === "" || influencer.lastname === ""
							? influencer.email
							: `${influencer.firstname} ${influencer.lastname}`
					}
					className="influencer-title-small"
					avatar={{ src: influencer.avatar, icon: <UserOutlined /> }}
				/>
			</Modal>
		</>
	);
};

export default DeleteInfluencer;
