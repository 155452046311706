import { useLocation, useNavigate } from "react-router-dom";
import { Tabs } from "antd";

const TabNav = ({ routes, classNames = null }) => {
	const location = useLocation();
	const navigate = useNavigate();

	const matchTab = routes.find((route) =>
		route.to === "" ? false : location.pathname.includes(route.to)
	);

	const changeRoute = (to) => navigate(to);

	return (
		<Tabs
			className={`tab-nav ${classNames}`}
			activeKey={matchTab ? matchTab.key : routes[0].key}
			onTabClick={changeRoute}
			items={routes}
		/>
	);
};

export default TabNav;
