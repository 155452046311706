const tasksTypes = [
	"instagramPost",
	"instagramReel",
	"instagramStory",
	"instagramLive",
	"tiktokPost",
	"tiktokStory",
	"tiktokLive",
	"facebookPost",
	"facebookStory",
	"facebookReel",
	"facebookLive",
	"linkedinPost",
	"youtubeVideo",
	"youtubeShort",
	"youtubeLive",
	"youtubeStory",
	"survey",
	"googleReview",
	"link",
	"other",
];

export default tasksTypes;
