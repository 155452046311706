import { createSlice } from "@reduxjs/toolkit";

export const influencersSlice = createSlice({
	name: "influencers",
	initialState: { waiting: null, active: null },
	reducers: {
		editWaitingInfluencers: (state, action) => {
			return { ...state, waiting: action.payload };
		},
		addWaitingInfluencers: (state, action) => {
			return { ...state, waiting: [...state.waiting, ...action.payload] };
		},
		editActiveInfluencers: (state, action) => {
			return { ...state, active: action.payload };
		},
	},
});

export const {
	editWaitingInfluencers,
	addWaitingInfluencers,
	editActiveInfluencers,
} = influencersSlice.actions;

export const getWaitingInfluencers = (state) => state.influencers.waiting;
export const getActiveInfluencers = (state) => state.influencers.active;
export const getInfluencer = (state, id) =>
	Array.isArray(state.influencers.active) &&
	state.influencers.active.findIndex(
		(influencer) => influencer && influencer.id === id
	) !== -1
		? state.influencers.active.find((influencer) => influencer.id === id)
		: null;

export default influencersSlice.reducer;
