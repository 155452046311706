import { useEffect, useMemo, useState } from "react";
import {
	Card,
	Col,
	Drawer,
	Pagination,
	Row,
	Space,
	Tag,
	Typography,
} from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import Title from "../layout/Title";
import useTranslation from "../../intl/useTranslation";
import MissionCardInfluencer from "./MissionCardInfluencer";
import MissionInfluencer from "./MissionInfluencer";
import SearchMissionsInfluencer from "./SearchMissionsInfluencer";
import { useSelector } from "react-redux";
import { getUser } from "../login/loginSlice";
import EmptyMissions from "./EmptyMissions";
import TabNav from "../navigation/TabNav";
import returnStatValue from "../../lib/returnStatValue";

const { Meta } = Card;
const { Text } = Typography;

const MissionsInfluencer = () => {
	const [missions, setMissions] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalResults, setTotalResults] = useState(0);
	const [pageSize, setPageSize] = useState(50);
	const location = useLocation();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const user = useSelector(getUser);

	const routes = [
		{
			label: t("missions.confirmations"),
			key: "",
			to: "",
		},
		{
			label: t("missions.proposals"),
			key: "proposals",
			to: "proposals",
		},
	];

	const type = useMemo(() => {
		if (location.pathname.includes("proposals")) return "proposal";
		if (location.pathname.includes("drafts")) return "draft";
		return null;
	}, [location.pathname]);

	const navigationType = useMemo(() => {
		if (location.pathname.includes("proposals")) return "proposals/";
		if (location.pathname.includes("drafts")) return "drafts/";
		return "";
	}, [location.pathname]);

	useEffect(() => {
		setCurrentPage(1);
	}, [type]);

	const matchMission = () => {
		if (typeof location.pathname.split("/")[2] === "undefined") return false;
		const missionId = location.pathname.split("/").slice(-1)[0];
		return (
			Array.isArray(missions) &&
			missions.findIndex((inf) => inf.id === missionId) !== -1
		);
	};

	const changePage = (page, pageSize) => {
		setCurrentPage(page);
		setPageSize(pageSize);
	};

	return (
		<>
			<Title
				title={t("missions.pageTitleInfluencer")}
				extra={<TabNav routes={routes} classNames="spacer" />}
			/>
			<SearchMissionsInfluencer
				setMissions={setMissions}
				influencerId={user.id}
				currentPage={currentPage}
				setTotalResults={setTotalResults}
				pageSize={pageSize}
				type={type}
			/>
			{missions === null ? (
				<Row gutter={[16, 16]}>
					{[...Array(20)].map((item, key) => (
						<Col key={key} xs={24} sm={12} md={12} lg={8} xl={6}>
							<Card className="mission-card" loading bordered={false}>
								<Meta
									title="Card title"
									description="This is the description"
								/>
							</Card>
						</Col>
					))}
				</Row>
			) : missions.length === 0 ? (
				<EmptyMissions />
			) : (
				<Row gutter={[16, 16]}>
					{missions.map((mission, key) => (
						<Col key={key} xs={24} sm={12} md={12} lg={8} xl={6}>
							<MissionCardInfluencer
								mission={mission}
								onClick={() => navigate(`${navigationType}${mission.id}`)}
							/>
						</Col>
					))}
				</Row>
			)}
			<Space style={{ paddingTop: 25, paddingBottom: 25 }}>
				<Pagination
					total={totalResults}
					onChange={changePage}
					current={currentPage}
					pageSize={pageSize}
					showSizeChanger
				/>
				<Tag className="total-influencers">
					<Space>
						<Text strong>{returnStatValue(totalResults)}</Text>
						<Text>Missions</Text>
					</Space>
				</Tag>
			</Space>
			<Drawer
				placement="right"
				width=""
				closable={false}
				open={matchMission()}
				className="influencer-details mobile-drawer"
			>
				<MissionInfluencer
					missionId={location.pathname.split("/").slice(-1)[0]}
				/>
			</Drawer>
		</>
	);
};

export default MissionsInfluencer;
