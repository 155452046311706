import { Input, Space, Typography, Button, Form } from "antd";
import {
	EmailAuthProvider,
	reauthenticateWithCredential,
	updateEmail,
} from "firebase/auth";
import db, { auth } from "../../data/Firebase";
import { useState } from "react";
import { useSelector } from "react-redux";
import useTranslation from "../../intl/useTranslation";
import { getUser } from "../login/loginSlice";
import { doc, updateDoc } from "firebase/firestore";

const { Title } = Typography;
const { Password } = Input;

const ChangeEmail = ({ success, error }) => {
	const { t } = useTranslation();
	const user = useSelector(getUser);
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const changeEmail = async ({ email, password }) => {
		try {
			setLoading(true);
			await reauthenticateWithCredential(
				auth.currentUser,
				EmailAuthProvider.credential(user.email, password)
			);
			await updateEmail(auth.currentUser, email);
			await updateDoc(
				doc(
					db,
					user.role === "administrator" || user.role === "manager"
						? "managers"
						: "influencers",
					user.uid
				),
				{ email: email }
			);
			form.resetFields();
			setLoading(false);
			success(t("settings.emailTitle"));
		} catch (error) {
			setLoading(false);
			error();
		}
	};

	return (
		<Form
			form={form}
			onFinish={changeEmail}
			initialValues={{ email: user.email }}
		>
			<Space className="settings-section" direction="vertical" size={12}>
				<Title level={5}>{t("settings.emailTitle")}</Title>
				<Space
					direction="horizontal"
					size={32}
					className="settings-section-space"
					wrap
				>
					<Form.Item
						rules={[
							{
								required: true,
								message: t("settings.requireEmail"),
							},
							{
								type: "email",
								message: t("settings.requireEmail"),
							},
						]}
						name="email"
						help={t("settings.changeMail")}
						hasFeedback
					>
						<Input
							size="large"
							type="email"
							prefix="@"
							className="settings-input"
							placeholder={"john.doe@gmail.com"}
						/>
					</Form.Item>
					<Form.Item
						name="password"
						rules={[{ required: true, message: t("login.errorPassword") }]}
						help={t("settings.confirmPassword")}
					>
						<Password
							size="large"
							type="password"
							className="settings-input"
							placeholder={t("login.password")}
						/>
					</Form.Item>
				</Space>
				<div className="settings-section-button">
					<Form.Item>
						<Button type="primary" htmlType="submit" loading={loading}>
							{t("settings.applyLabel")}
						</Button>
					</Form.Item>
				</div>
			</Space>
		</Form>
	);
};

export default ChangeEmail;
