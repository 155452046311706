import { Avatar, PageHeader, Tag, Typography } from "antd";

const { Title } = Typography;

const TaskTag = ({ tag, tagValue }) => {
	if (!tagValue) return null;

	return (
		<Tag key={tag.id} className="mission-tag task-tag">
			<Avatar icon={tag.icon} />
			<PageHeader
				title={
					<Title
						level={5}
						className="task-tag-title"
						copyable
						ellipsis={tag.ellipsis}
					>
						{tagValue}
					</Title>
				}
				className="influencer-title-small metrics-title"
			/>
		</Tag>
	);
};

export default TaskTag;
