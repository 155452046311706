import { Space } from "antd";
import ChangeEmail from "./ChangeEmail";
import ChangePassword from "./ChangePassword";
import MyAccount from "./MyAccount";

const Account = ({ success, error }) => {
	return (
		<Space direction="vertical" size={24} className="settings-wrapper">
			<MyAccount success={success} error={error} />
			<ChangeEmail success={success} error={error} />
			<ChangePassword success={success} error={error} />
		</Space>
	);
};

export default Account;
