import { Card, PageHeader, Divider, Space } from "antd";

const { Meta } = Card;

const FilterCard = ({ title, children }) => (
	<Card bordered={false} className="search-card filter-card">
		<Meta
			className="search-filter-title"
			title={
				<PageHeader
					title={title}
					className="influencer-title-small search-card-title"
				/>
			}
		/>
		<Divider />
		<Space size={0} direction="vertical">
			{children}
		</Space>
	</Card>
);

export default FilterCard;
