import { useState } from "react";
import { Drawer, Row, Col, Space, Pagination, Tag, Typography } from "antd";
import { useNavigate, useLocation, Route, Routes } from "react-router-dom";

import Title from "../layout/Title";
import useTranslation from "../../intl/useTranslation";
import SearchClients from "./SearchClients";
import EditClient from "./EditClient";
import ClientCard from "./ClientCard";
import ClientInfo from "./ClientInfo";
import returnStatValue from "../../lib/returnStatValue";

const { Text } = Typography;

const Clients = () => {
	const [clients, setClients] = useState(null);
	const [editClient, setEditClient] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalResults, setTotalResults] = useState(0);
	const [pageSize, setPageSize] = useState(50);

	const navigate = useNavigate();
	const location = useLocation();
	const { t } = useTranslation();

	const matchClient = () => {
		if (typeof location.pathname.split("/")[2] === "undefined") return false;
		return (
			Array.isArray(clients) &&
			clients.findIndex(
				(client) => client.objectID === location.pathname.split("/")[2]
			) !== -1
		);
	};

	const changePage = (page, pageSize) => {
		setCurrentPage(page);
		setPageSize(pageSize);
	};

	return (
		<>
			<Title title={t("clients.pageTitle")} extraSpacer />
			<SearchClients
				setClients={setClients}
				editClient={editClient}
				currentPage={currentPage}
				setTotalResults={setTotalResults}
				pageSize={pageSize}
				setEditClient={setEditClient}
			/>
			<Space
				direction="vertical"
				size={16}
				style={{ minWidth: "100%", maxWidth: "100%", paddingBottom: 16 }}
			>
				{clients === null ? (
					<Row gutter={[16, 16]}>
						{[...Array(20)].map((item, key) => (
							<Col key={key} xs={24} sm={12} md={12} lg={8} xl={6}>
								<ClientCard loading={true} />
							</Col>
						))}
					</Row>
				) : clients.length === 0 ? null : (
					<Row gutter={[16, 16]}>
						{clients.map((client, key) => (
							<Col key={key} xs={24} sm={12} md={12} lg={8} xl={6}>
								<ClientCard
									client={client}
									onClick={() => navigate(client.objectID)}
								/>
							</Col>
						))}
					</Row>
				)}
				<Space>
					<Pagination
						total={totalResults}
						onChange={changePage}
						current={currentPage}
						pageSize={pageSize}
						showSizeChanger
					/>
					<Tag className="total-influencers">
						<Space>
							<Text strong>{returnStatValue(totalResults)}</Text>
							<Text>Clients</Text>
						</Space>
					</Tag>
				</Space>
			</Space>
			<Drawer
				placement="right"
				closable={false}
				width=""
				open={matchClient()}
				className="influencer-details mobile-drawer"
			>
				<Routes>
					<Route
						path={`/:id`}
						element={<ClientInfo setEditClient={setEditClient} />}
					/>
				</Routes>
			</Drawer>
			<Drawer
				placement="right"
				closable={false}
				width=""
				open={editClient !== null}
				className="influencer-details mobile-drawer"
			>
				<EditClient client={editClient} setClient={setEditClient} />
			</Drawer>
		</>
	);
};

export default Clients;
