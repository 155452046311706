import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
	collection,
	query,
	limit,
	onSnapshot,
	orderBy,
	doc,
} from "firebase/firestore";
import { Spin, Row, Col } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import languageDetector, { isLocale } from "../../intl/languageDetector";
import db, { auth } from "../../data/Firebase";
import { editLoadingStatus, editLocale } from "./loadingSlice";
import { editUser } from "../login/loginSlice";
import { editNotifications } from "../notifications/notificationsSlice";

const Loading = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		const loadNotifications = async (userId, type) => {
			const notificationsQuery = query(
				collection(db, `${type}/${userId}/notifications`),
				limit(20),
				orderBy("time", "desc")
			);
			onSnapshot(notificationsQuery, (notifications) => {
				const notificationsArray = [];
				notifications.forEach((notification) =>
					notificationsArray.push({
						...notification.data(),
						time: notification.data().time.seconds,
						id: notification.id,
					})
				);
				dispatch(editNotifications(notificationsArray));
			});
		};

		auth.onAuthStateChanged((user) => {
			if (user) {
				const influencerDoc = doc(db, "influencers", user.uid);
				onSnapshot(influencerDoc, async (influencer) => {
					if (influencer.exists()) {
						dispatch(
							editUser({
								...influencer.data(),
								email: user.email,
								uid: user.uid,
								role: "influencer",
							})
						);
						dispatch(
							editLocale(
								isLocale(influencer.data().language)
									? influencer.data().language
									: languageDetector()
							)
						);
						await loadNotifications(user.uid, "influencers");
						dispatch(editLoadingStatus("loaded"));
					}
				});

				const managerDoc = doc(db, "managers", user.uid);
				onSnapshot(managerDoc, async (manager) => {
					if (manager.exists()) {
						const data = manager.data();
						dispatch(
							editUser({
								email: user.email,
								uid: user.uid,
								firstname: data.firstname,
								lastname: data.lastname,
								role: data.role,
								language: data.language,
								phoneNumber: data.phoneNumber,
							})
						);
						dispatch(
							editLocale(
								isLocale(manager.data().language)
									? manager.data().language
									: languageDetector()
							)
						);
						await loadNotifications(user.uid, "managers");
						dispatch(editLoadingStatus("loaded"));
					}
				});
			} else {
				dispatch(editLocale(languageDetector()));
				dispatch(editUser("disconnected"));
				dispatch(editLoadingStatus("loaded"));
			}
		});
	}, [dispatch]);

	return (
		<Row className="loading-row" align="middle" justify="space-around">
			<Col>
				<img
					style={{
						height: 80,
						marginBottom: -10,
					}}
					src="/SocialifeLogoDark.svg"
					alt="Socialife Logo"
				/>
				<Spin
					style={{ display: "block" }}
					indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
				/>
			</Col>
		</Row>
	);
};

export default Loading;
