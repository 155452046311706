import { Skeleton, Card, Avatar, Col, PageHeader, Button } from "antd";
import {
	CheckCircleFilled,
	CloseCircleFilled,
	UserOutlined,
} from "@ant-design/icons";

import useTranslation from "../../intl/useTranslation";
import NetworkButtons from "./NetworkButtons";

const { Meta } = Card;

const InfluencerCard = ({
	influencer = null,
	setAccept = (influencer) => null,
	accepting = null,
	rejecting = null,
	setReject = (influencer) => null,
	onClick = () => null,
	loading = false,
	boarding = false,
}) => {
	const { t } = useTranslation();

	return (
		<Col span={24} sm={24} md={12} xl={8}>
			<Card
				className="influencer-card"
				actions={
					accepting !== null &&
					rejecting !== null &&
					!boarding && [
						<Button
							type="primary"
							icon={<CheckCircleFilled />}
							onClick={() => setAccept(influencer)}
							loading={
								influencer !== null && influencer.id === accepting
									? true
									: false
							}
						>
							{t("influencers.accept")}
						</Button>,
						<Button
							type="primary"
							icon={<CloseCircleFilled />}
							onClick={() => setReject(influencer)}
							loading={
								influencer !== null && influencer.id === rejecting
									? true
									: false
							}
						>
							{t("influencers.reject")}
						</Button>,
					]
				}
			>
				<Skeleton loading={loading} avatar active>
					{influencer !== null && (
						<>
							<span onClick={onClick}>
								<Meta
									style={{ cursor: "pointer" }}
									avatar={
										<Avatar
											size="large"
											src={influencer.avatar ? influencer.avatar : undefined}
											icon={<UserOutlined />}
										/>
									}
									title={
										<>
											<PageHeader
												title={
													influencer.displayName
														? influencer.displayName
														: influencer.email
												}
												className="influencer-title"
											/>
										</>
									}
								/>
							</span>

							<NetworkButtons influencer={influencer} />
						</>
					)}
				</Skeleton>
			</Card>
		</Col>
	);
};

export default InfluencerCard;
