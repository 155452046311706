import { Button, Input, Space, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import useTranslation from "../../intl/useTranslation";
import generateId from "../../lib/generateId";
import emptyQuestion from "../../data/emptyQuestion";

const { Title: AntdTitle } = Typography;

const NewMissionQuestions = ({ mission, setMission }) => {
	const { t } = useTranslation();

	const onChangeQuestion = (e, index) => {
		const questions = [...mission.questions];
		questions[index][e.target.name] = e.target.value;
		setMission({ ...mission, questions });
	};

	const removeQuestion = (id) => {
		const questions = mission.questions;
		const updatedQuestions = questions.filter((question) => question.id !== id);
		setMission({
			...mission,
			questions: updatedQuestions,
		});
	};

	return (
		<Space size={24} direction="vertical" className="mission-form">
			{mission?.questions.map((question, index) => (
				<Space
					key={index}
					className="mission-form"
					direction="vertical"
					size={16}
				>
					<AntdTitle level={4}>
						{`${t("missions.questionTitle")} #${index + 1}`}
						{mission?.questions.length > 1 && (
							<Button
								type="link"
								shape="circle"
								icon={<DeleteOutlined />}
								onClick={() => removeQuestion(question?.id)}
							/>
						)}
					</AntdTitle>
					<Input
						name="question"
						size="large"
						type="text"
						value={question.question}
						placeholder={t("missions.questionTitle")}
						onChange={(e) => onChangeQuestion(e, index)}
					/>
				</Space>
			))}
			<Button
				size="large"
				type="ghost"
				onClick={() =>
					setMission({
						...mission,
						questions: [
							...mission?.questions,
							{ ...emptyQuestion, id: generateId(10) },
						],
					})
				}
			>
				{t("missions.addQuestion")}
			</Button>
		</Space>
	);
};

export default NewMissionQuestions;
