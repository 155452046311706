import { Space, Typography, Rate, Checkbox, Tag, Slider } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";

import FilterCard from "./FilterCard";
import useTranslation from "../../intl/useTranslation";

const { Group } = Checkbox;
const { Text } = Typography;

const FiltersGeneral = ({
	search,
	onChangeFilter,
	languages,
	interests,
	age,
	setAge,
}) => {
	const { t } = useTranslation();

	return (
		<FilterCard title={t("influencers.general")}>
			<Space direction="vertical" size={16}>
				<Space direction="vertical" size={0}>
					<Text strong>{t("influencers.rating")}</Text>
					<Rate
						allowHalf
						defaultValue={0}
						value={
							typeof search.filters["rating"] !== "undefined"
								? search.filters["rating"].value
								: 0
						}
						onChange={(value) => onChangeFilter("rating", value, ">=")}
					/>
				</Space>
				<Space direction="vertical" size={8}>
					<Text strong>{t("influencers.interests")}</Text>
					<Group
						onChange={(value) => onChangeFilter("interests", value, ":")}
						value={
							typeof search.filters["interests"] !== "undefined"
								? search.filters["interests"].value
								: []
						}
					>
						<Space size={4} direction="vertical" className="filter-interests">
							{interests !== null &&
								(interests.length === 0 ? (
									<span>
										<SearchOutlined /> No interests
									</span>
								) : (
									interests.map((interest) => (
										<Checkbox key={interest.value} value={interest.value}>
											{t("onboarding." + interest.value)}{" "}
											<Tag className="count-tag">{interest.count}</Tag>
										</Checkbox>
									))
								))}
						</Space>
					</Group>
				</Space>

				<Space direction="vertical" size={8}>
					<Text strong>{t("influencers.languages")}</Text>
					<Group
						onChange={(value) => onChangeFilter("language", value, ":")}
						value={
							typeof search.filters["language"] !== "undefined"
								? search.filters["language"].value
								: []
						}
					>
						<Space size={4} direction="vertical" className="filter-interests">
							{languages !== null &&
								(languages.length === 0 ? (
									<span>
										<SearchOutlined /> No languages
									</span>
								) : (
									languages.map((language) => (
										<Checkbox key={language.value} value={language.value}>
											{t("login." + language.value)}{" "}
											<Tag className="count-tag">{language.count}</Tag>
										</Checkbox>
									))
								))}
						</Space>
					</Group>
				</Space>

				<Space direction="vertical" style={{ width: "100%" }} size={8}>
					<Text strong>
						{t("influencers.age")} {age !== null && `${age[0]} - ${age[1]}`}
					</Text>
					<Slider
						range
						min={18}
						max={90}
						defaultValue={age === null ? [18, 90] : age}
						onAfterChange={(val) => {
							setAge(val);
							onChangeFilter(
								"dateOfBirth",
								[
									moment().subtract(val[1], "years").unix(),
									moment().subtract(val[0], "years").unix(),
								],
								"<>"
							);
						}}
						marks={{ 18: "18", 90: "90" }}
					/>
				</Space>
			</Space>
		</FilterCard>
	);
};

export default FiltersGeneral;
