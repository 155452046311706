import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Layout as AntdLayout, Steps, Result, Button, Row, Col } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";
import { httpsCallable } from "firebase/functions";
import {
	updatePassword,
	reauthenticateWithCredential,
	EmailAuthProvider,
} from "firebase/auth";

import { auth, fbFunctions } from "../../data/Firebase";
import { getUser } from "../login/loginSlice";
import useTranslation from "../../intl/useTranslation";
import Loading from "../loading/Loading";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import MobileHeader from "../navigation/MobileHeader";
import DesktopHeader from "../navigation/DesktopHeader";
import NotificationIcon from "../../icons/NotificationIcon";
import { signInWithEmailAndPassword } from "firebase/auth";

const { Step } = Steps;
const { Content } = AntdLayout;

const Onboarding = () => {
	const { t } = useTranslation();
	const params = useParams();
	const user = useSelector(getUser);
	const [activeStep, setActiveStep] = useState(-1);
	const [influencer, setInfluencer] = useState({
		...user,
		iban: "",
		address: "",
		zip: "",
		city: "",
		country: "",
	});
	const [loading, setLoading] = useState(false);

	const login = () => {
		if (params.token === "" || params.email === "/") return false;
		const password = params.token;
		const email = decodeURI(params.email);
		signInWithEmailAndPassword(auth, email, password)
			.then((user) => {})
			.catch((error) => {});
	};

	if (user === null || user === "disconnected") {
		login();
		return <Loading />;
	}

	const steps = [
		{
			title: t("onboarding.step1"),
			content: (
				<Step1
					influencer={influencer}
					setInfluencer={setInfluencer}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
				/>
			),
		},
		{
			title: t("onboarding.step2"),
			content: (
				<Step2
					influencer={influencer}
					setInfluencer={setInfluencer}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
				/>
			),
		},
		{
			title: t("onboarding.step3"),
			content: (
				<Step3
					influencer={influencer}
					setInfluencer={setInfluencer}
					activeStep={activeStep}
					setActiveStep={setActiveStep}
				/>
			),
		},
	];

	const getSteps = () => steps;

	const confirmBoarding = async () => {
		setLoading(true);
		await reauthenticateWithCredential(
			auth.currentUser,
			EmailAuthProvider.credential(user.email, influencer.tempPassword)
		);
		await updatePassword(auth.currentUser, influencer.password);
		const confirmOnboard = httpsCallable(fbFunctions, "confirmOnboarding");
		await confirmOnboard({
			influencerId: influencer.uid,
			firstname: influencer.firstname,
			lastname: influencer.lastname,
			interests: influencer.interests,
			facebook: influencer.facebook,
			linkedin: influencer.linkedin,
			instagram: influencer.instagram,
			youtube: influencer.youtube,
			tiktok: influencer.tiktok,
			iban: influencer.iban,
			address: influencer.address,
			zip: influencer.zip,
			city: influencer.city,
			country: influencer.country,
			dateOfBirth: influencer.dateOfBirth.unix(),
		});
	};

	return (
		<AntdLayout>
			<MobileHeader logoOnly />
			<DesktopHeader logoOnly />
			<Content className="content onboarding-content">
				{activeStep < 0 ? (
					<Row className="onboarding-row" align="middle" justify="space-around">
						<Col>
							<Result
								title={t("onboarding.welcomeTitle")}
								subTitle={t("onboarding.welcomeDesc")}
								icon={<NotificationIcon />}
								extra={
									<Button
										onClick={() => setActiveStep(0)}
										type="primary"
										key="console"
									>
										{t("onboarding.welcomeAction")}
									</Button>
								}
							/>
						</Col>
					</Row>
				) : (
					<>
						<Steps
							className="onboarding-stepper"
							size="small"
							current={activeStep}
						>
							{getSteps().map((item) => (
								<Step key={item.title} title={item.title} />
							))}
						</Steps>
						<div>
							{activeStep > 2 ? (
								<Row
									className="onboarding-row"
									align="middle"
									justify="space-around"
								>
									<Col>
										<Result
											title={t("onboarding.finishTitle")}
											subTitle={t("onboarding.finishDesc")}
											icon={<CheckCircleFilled />}
											extra={
												<Button
													onClick={confirmBoarding}
													type="primary"
													key="console"
													loading={loading}
												>
													{t("onboarding.finishAction")}
												</Button>
											}
										/>
									</Col>
								</Row>
							) : (
								getSteps()[activeStep].content
							)}
						</div>
					</>
				)}
			</Content>
		</AntdLayout>
	);
};

export default Onboarding;
