import { Input, Space, Typography, Button, Form, Select } from "antd";
import db from "../../data/Firebase";
import { useState } from "react";
import { useSelector } from "react-redux";
import useTranslation from "../../intl/useTranslation";
import { getUser } from "../login/loginSlice";
import LangStrings from "../../intl/LangStrings";
import { doc, updateDoc } from "firebase/firestore";
import { PhoneOutlined } from "@ant-design/icons";
import { AsYouType, CountryCode, parsePhoneNumber } from "libphonenumber-js";

const { Title } = Typography;
const { Option } = Select;

const MyAccount = ({ success, error }) => {
	const { t } = useTranslation();
	const user = useSelector(getUser);
	const [loading, setLoading] = useState(false);
	const [phoneLocale, setPhoneLocale] = useState<CountryCode>(
		parsePhoneNumber(user.phoneNumber).country as CountryCode
	);
	const [form] = Form.useForm();

	const changeAccount = async ({
		firstname,
		lastname,
		language,
		phoneNumber,
	}) => {
		try {
			setLoading(true);
			await updateDoc(
				doc(
					db,
					user.role === "administrator" || user.role === "manager"
						? "managers"
						: "influencers",
					user.uid
				),
				{
					firstname: firstname,
					lastname: lastname,
					language: language,
					phoneNumber: parsePhoneNumber(
						phoneNumber,
						phoneLocale as CountryCode
					).format("E.164"),
				}
			);
			form.resetFields();
			setLoading(false);
			success(t("settings.account"));
		} catch (error) {
			setLoading(false);
			error();
		}
	};

	const onPhoneChange = (value) =>
		value.phoneNumber &&
		form.setFieldValue(
			"phoneNumber",
			parsePhoneNumber(
				value.phoneNumber,
				phoneLocale as CountryCode
			).formatNational()
		);

	const countrySelect = (
		<Select
			defaultValue={parsePhoneNumber(user.phoneNumber).country}
			className="select-before"
			onChange={(e: CountryCode) => setPhoneLocale(e)}
		>
			<Option value="CH">+41</Option>
			<Option value="FR">+33</Option>
		</Select>
	);

	return (
		<Form
			form={form}
			onFinish={changeAccount}
			initialValues={{
				firstname: user.firstname,
				lastname: user.lastname,
				language: user.language,
				phoneNumber: new AsYouType(phoneLocale).input(
					parsePhoneNumber(user.phoneNumber).formatNational()
				),
			}}
			onValuesChange={onPhoneChange}
		>
			<Space className="settings-section" direction="vertical" size={12}>
				<Title level={5}>{t("settings.generalTitle")}</Title>
				<Space
					direction="horizontal"
					size={32}
					className="settings-section-space"
					wrap
				>
					<Form.Item name="firstname" rules={[{ required: true }]}>
						<Input
							size="large"
							className="settings-input"
							placeholder={t("settings.firstname")}
						/>
					</Form.Item>
					<Form.Item name="lastname" rules={[{ required: true }]}>
						<Input
							size="large"
							className="settings-input"
							placeholder={t("settings.lastname")}
						/>
					</Form.Item>
				</Space>
				<Space
					direction="horizontal"
					size={32}
					className="settings-section-space"
					wrap
				>
					<Form.Item
						name="phoneNumber"
						rules={[
							{
								validator: (_, value) =>
									parsePhoneNumber(
										typeof value === "string" ? value : "1",
										phoneLocale as CountryCode
									).isValid()
										? Promise.resolve()
										: Promise.reject(new Error(t("settings.errorPhoneNumber"))),
							},
						]}
					>
						<Input
							prefix={<PhoneOutlined className="site-form-item-icon" />}
							addonBefore={countrySelect}
							type="tel"
							size="large"
							className="settings-input"
							placeholder={t("clients.labelPhone")}
						/>
					</Form.Item>
					<Form.Item name="language" rules={[{ required: true }]}>
						<Select size="large">
							{Object.keys(LangStrings).map((lang, key) => (
								<Option key={key} value={lang}>
									{t("login." + lang)}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Space>
				<div className="settings-section-button">
					<Form.Item>
						<Button type="primary" htmlType="submit" loading={loading}>
							{t("settings.applyLabel")}
						</Button>
					</Form.Item>
				</div>
			</Space>
		</Form>
	);
};

export default MyAccount;
