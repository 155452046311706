import { Tag } from "antd";
import numeral from "numeral";

const InfluencerInfo = ({ icon, data, extra = undefined }) => {
	return (
		<div className="influencer-info">
			<div className="influencer-info-icon">{icon}</div>
			<div className="influencer-info-data">
				{data}
				{extra !== undefined && (
					<Tag className="influencer-info-tag">
						{numeral(extra).format("0.[00]a")}
					</Tag>
				)}
			</div>
		</div>
	);
};

export default InfluencerInfo;
