import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Avatar, Tabs, Row, Col, Button, Space } from "antd";
import {
	MailOutlined,
	MobileOutlined,
	CheckCircleFilled,
	UserOutlined,
	CloseCircleFilled,
	GlobalOutlined,
} from "@ant-design/icons";
import { parsePhoneNumber } from "libphonenumber-js";

import Title from "../layout/Title";
import InfluencerInfo from "./InfluencerInfo";
import useTranslation from "../../intl/useTranslation";
import { getWaitingInfluencers } from "./influencersSlice";
import NetworkButtons from "./NetworkButtons";
import DeleteInfluencer from "./DeleteInfluencer";
import RemindInfluencer from "./RemindInfluencer";

const WaitingInfluencer = ({
	influencerId,
	boarding = false,
	acceptInfluencer,
	rejectInfluencer,
	accepting,
	rejecting,
}) => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const influencer = useSelector(getWaitingInfluencers).find(
		(inf) => inf.id === influencerId
	);

	if (typeof influencer === "undefined") return <></>;

	return (
		<>
			<Title
				title={
					<>
						<Avatar
							size="large"
							src={influencer.avatar ? influencer.avatar : undefined}
							icon={<UserOutlined />}
						/>
						{influencer.displayName ? influencer.displayName : influencer.email}
					</>
				}
				onBack={() => navigate(boarding ? "../boarding" : "../waiting")}
				small
			/>
			<div className="influencer-informations">
				<Row gutter={[16, 16]}>
					<Col>
						<a href={"mailto:" + influencer.email}>
							<InfluencerInfo icon={<MailOutlined />} data={influencer.email} />
						</a>
					</Col>
					<Col>
						<a href={"tel:" + influencer.phoneNumber}>
							<InfluencerInfo
								icon={<MobileOutlined />}
								data={parsePhoneNumber(
									influencer.phoneNumber
								).formatInternational()}
							/>
						</a>
					</Col>
					<Col>
						<InfluencerInfo
							icon={<GlobalOutlined />}
							data={t("login." + influencer.language)}
						/>
					</Col>
				</Row>
			</div>
			<Tabs
				className="influencer-tab"
				defaultActiveKey="networks"
				items={[
					{
						label: t("influencers.networks"),
						key: "networks",
						children: <NetworkButtons influencer={influencer} />,
					},
				]}
			/>
			{!boarding && (
				<Row style={{ marginTop: 24 }} gutter={[14, 16]}>
					<Col span={12}>
						<Button
							type="primary"
							className="btn-accept"
							icon={<CheckCircleFilled />}
							onClick={() => acceptInfluencer(influencer)}
							loading={
								influencer !== null && influencer.id === accepting
									? true
									: false
							}
						>
							{t("influencers.accept")}
						</Button>
					</Col>
					<Col span={12}>
						<Button
							type="primary"
							className="btn-reject"
							icon={<CloseCircleFilled />}
							onClick={() => rejectInfluencer(influencer)}
							loading={
								influencer !== null && influencer.id === rejecting
									? true
									: false
							}
						>
							{t("influencers.reject")}
						</Button>
					</Col>
				</Row>
			)}
			{boarding && (
				<Space className="influencer-actions">
					<DeleteInfluencer influencer={influencer} />
					<RemindInfluencer influencer={influencer} />
				</Space>
			)}
		</>
	);
};

export default WaitingInfluencer;
