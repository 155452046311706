import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
	Avatar,
	PageHeader,
	Space,
	Typography,
	Tag,
	Button,
	Timeline,
	Collapse,
	Image,
	Modal,
	Rate,
	Alert,
	Input,
	message,
} from "antd";
import {
	TeamOutlined,
	SyncOutlined,
	DollarOutlined,
	CheckCircleFilled,
	NumberOutlined,
	LinkOutlined,
	AppstoreAddOutlined,
	ClockCircleOutlined,
	CheckCircleOutlined,
	CloseCircleFilled,
	WarningOutlined,
	PlusCircleOutlined,
	MinusCircleOutlined,
	CloseCircleOutlined,
} from "@ant-design/icons";
import { onSnapshot, doc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

import db, { fbFunctions } from "../../data/Firebase";
import Title from "../layout/Title";
import { getMissions, editMission } from "./missionsSlice";
import removeTrailingParam from "../../lib/removeTrailingParam";
import { getClients, setClient } from "../clients/clientsSlice";
import useTranslation from "../../intl/useTranslation";
import moment from "moment";
import { getUser } from "../login/loginSlice";
import TaskTag from "./TaskTag";

const { Title: AntdTitle, Paragraph } = Typography;
const { Panel } = Collapse;

const taskTags = [
	{ id: "hashtags", icon: <NumberOutlined />, ellipsis: false },
	{ id: "tags", icon: "@", ellipsis: false },
	{ id: "link", icon: <LinkOutlined />, ellipsis: true },
];

const MissionInfluencer = ({ missionId }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const { t } = useTranslation();

	const [acceptLoading, setAcceptLoading] = useState(false);
	const [rejectLoading, setRejectLoading] = useState(false);
	const [taskLoading, setTaskLoading] = useState(false);
	const [ratingOpen, setRatingOpen] = useState(false);
	const [missionRate, setMissionRate] = useState(null);
	const [valdiationLoading, setValidationLoading] = useState(false);
	const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
	const [showButton, setShowButton] = useState(true);
	const [countdowns, setCountdowns] = useState([]);
	const [questionsOpen, setQuestionsOpen] = useState(false);
	const [answers, setAnswers] = useState({});

	const descriptionRef = useRef(null);

	const missions = useSelector(getMissions);
	const clients = useSelector(getClients);
	const user = useSelector(getUser);

	const mission =
		missions !== null &&
		missions.findIndex((miss) => miss.id === missionId) !== -1
			? missions.find((miss) => miss.id === missionId)
			: null;
	const client =
		mission !== null &&
		clients !== null &&
		clients.findIndex((client) => client.id === mission.client) !== -1
			? clients.find((client) => client.id === mission.client)
			: null;

	const influencer =
		mission === null
			? null
			: mission.influencers.find((inf) => inf.id === user.uid);

	useEffect(() => {
		if (mission === null && typeof missionId !== "undefined") {
			onSnapshot(doc(db, "missions", missionId), (mission) => {
				if (mission.exists) {
					const influencerRef = doc(
						db,
						`missions/${mission.id}/influencers`,
						user.uid
					);
					onSnapshot(influencerRef, (influencer) => {
						dispatch(
							editMission({
								...mission.data(),
								id: mission.id,
								influencers: [{ ...influencer.data(), id: influencer.id }],
							})
						);
					});
				}
			});
		}
	}, [mission, missions, dispatch, missionId, clients, user.uid]);

	useEffect(() => {
		if (
			(mission !== null && client === null) ||
			(mission !== null &&
				clients.findIndex((client) => client.id === mission.client) === -1)
		) {
			onSnapshot(doc(db, "clients", mission.client), (document) => {
				if (document.exists) {
					dispatch(setClient({ ...document.data(), id: document.id }));
				}
			});
		}
	}, [client, clients, dispatch, mission]);

	const acceptMission = async () => {
		setAcceptLoading(true);
		const applyFirebase = httpsCallable(fbFunctions, "acceptMission");
		await applyFirebase({
			influencerId: user.uid,
			missionId: missionId,
			answers: answers,
		});
		setAcceptLoading(false);
		if (mission.type === "proposal") {
			setAnswers({});
			message.success(t("missions.proposalAccepted"));
			navigate(removeTrailingParam(".."));
		}
	};

	const onChangeAnswer = (value, id) => {
		setAnswers({ ...answers, [id]: value });
	};

	const rejectMission = async () => {
		setRejectLoading(true);
		const applyFirebase = httpsCallable(fbFunctions, "rejectMission");
		await applyFirebase({
			influencerId: user.uid,
			missionId: missionId,
		});
		setRejectLoading(false);
	};

	const validateTask = async (taskId) => {
		setTaskLoading(true);
		const applyFirebase = httpsCallable(fbFunctions, "validateTask");
		await applyFirebase({
			influencerId: user.uid,
			missionId: missionId,
			taskId: taskId,
		});
		setTaskLoading(false);
	};

	const validateMission = async () => {
		setValidationLoading(true);
		const applyFirebase = httpsCallable(
			fbFunctions,
			"influencerValidateMission"
		);
		await applyFirebase({
			influencerId: user.uid,
			missionId: missionId,
			rate: missionRate,
		});
		setValidationLoading(false);
		setRatingOpen(false);
	};

	const toggleDescription = () => {
		setIsDescriptionExpanded(!isDescriptionExpanded);
	};

	const resetToggleDescription = () => {
		setIsDescriptionExpanded(false);
		setShowButton(true);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			if (descriptionRef.current) {
				const descriptionHeight = descriptionRef.current.scrollHeight;
				const containerHeight = 250; // Adjust as needed
				if (descriptionHeight <= containerHeight) {
					setShowButton(false);
					setIsDescriptionExpanded(true);
				} else {
					setShowButton(true);
				}
			}
		}, 0);

		// Clear the timeout when the component unmounts or dependencies change
		return () => clearTimeout(timer);
	}, [mission?.description]); // Depend on mission.description or any identifier that changes when missions change

	useEffect(() => {
		if (mission === null) return;
		if (mission.type === "proposal") return;
		// Function to calculate the countdown string
		const calculateCountdown = (task) => {
			const now = moment();
			const start = moment.unix(task.startDate);
			const end = moment.unix(task.dueDate);

			const formatTimePart = (value) => String(value).padStart(2, "0");

			const calculateDuration = (fromMoment, toMoment) => {
				// Ensure positive duration by swapping if first moment is after the second
				const duration = moment.duration(Math.abs(fromMoment.diff(toMoment)));
				let parts = [];

				// Only add day part if it's greater than 0
				if (duration.days() > 0) {
					parts.push(duration.days() + "days");
				}
				// Include hours if days are present or if hours are greater than 0
				if (duration.hours() > 0 || duration.days() > 0) {
					parts.push(formatTimePart(duration.hours()));
				}
				parts.push(formatTimePart(duration.minutes()));
				parts.push(formatTimePart(duration.seconds()));

				return parts.join(":");
			};

			if (now.isBefore(start)) {
				// Task hasn't started yet
				return {
					print: calculateDuration(now, start),
					status: "waiting",
				};
			} else if (now.isBetween(start, end)) {
				// Task is ongoing
				return {
					print: calculateDuration(now, end),
					status: "ongoing",
				};
			} else {
				// Task is over
				return {
					print: calculateDuration(end, now),
					status: "overdue",
				}; // Swapped end and now for a past event
			}
		};

		// Setup initial countdowns
		const initialCountdowns =
			mission.tasks && mission?.tasks.map((task) => calculateCountdown(task));
		setCountdowns(initialCountdowns);

		// Setup an interval to update countdowns every second
		const interval = setInterval(() => {
			const updatedCountdowns = mission?.tasks.map((task) =>
				calculateCountdown(task)
			);
			setCountdowns(updatedCountdowns);
		}, 1000);
		// Cleanup interval on component unmount or when tasks change
		return () => clearInterval(interval);
	}, [mission]); // Depend on mission.tasks or any identifier that changes when tasks change

	const replaceDays = (time) =>
		typeof time === "string" &&
		time.replace(
			"days:",
			parseInt(time.split(":")[0]) > 1
				? ` ${t("missions.days")} `
				: ` ${t("missions.day")} `
		);

	if (mission === null)
		return (
			<>
				<Title
					title={<>Mission</>}
					onBack={() => {
						navigate(removeTrailingParam(location.pathname));
						resetToggleDescription();
					}}
				/>
				Loading
			</>
		);

	return (
		<>
			<Title
				title={
					<>
						<Avatar size="large" src={mission.image} />
						{mission.title}
					</>
				}
				onBack={() => {
					navigate(removeTrailingParam(location.pathname));
					setAnswers({});
					resetToggleDescription();
				}}
				small
			/>
			<Space direction="vertical" className="mission-wrapper" size={22}>
				<Space wrap>
					<Tag className="mission-tag">
						{influencer.status === "completed" ? (
							<Avatar
								icon={<CheckCircleOutlined className="timeline-icon" />}
								style={{ backgroundColor: "#3e9812" }}
							/>
						) : influencer.status === "validating" ? (
							<Avatar icon={<ClockCircleOutlined />} />
						) : (
							<Avatar icon={<SyncOutlined />} />
						)}
						<PageHeader
							title={t(
								`missions.${
									influencer.status === "validating"
										? "validating"
										: influencer.status === "completed"
										? "completed"
										: mission.status
								}`
							)}
							className="influencer-title-small metrics-title"
						/>
					</Tag>
					<Tag className="mission-tag">
						<Avatar icon={<TeamOutlined />} />
						<PageHeader
							title={`${mission.enrolled}/${mission.participants}`}
							className="influencer-title-small metrics-title"
						/>
					</Tag>
					<Tag className="mission-tag">
						<Avatar icon={<DollarOutlined />} />
						<PageHeader
							title={`${mission.reward} ${mission.currency}`}
							className="influencer-title-small metrics-title"
						/>
					</Tag>
				</Space>
				{mission.type === "proposal" && (
					<Alert
						message={t("missions.proposalAlertTitle")}
						description={t("missions.proposalAlertText")}
						type="info"
						showIcon
					/>
				)}
				<Space
					className={`reward-prompt description-preview ${
						isDescriptionExpanded ? "expanded" : "collapsed"
					}`}
					direction="vertical"
					align="center"
					size={8}
				>
					<AntdTitle level={5}>{t("missions.descriptionLabel")}</AntdTitle>
					<div
						className="mission-description"
						ref={descriptionRef}
						style={{ paddingBottom: showButton ? 40 : 0 }}
						dangerouslySetInnerHTML={{ __html: mission.description }}
					/>
					{showButton && (
						<div className="show-more-button">
							<Button
								type="primary"
								size="small"
								className="btn-showmore btn-accept"
								icon={
									isDescriptionExpanded ? (
										<MinusCircleOutlined />
									) : (
										<PlusCircleOutlined />
									)
								}
								onClick={toggleDescription}
							>
								{isDescriptionExpanded
									? t("missions.showLess")
									: t("missions.showMore")}
							</Button>
						</div>
					)}
				</Space>

				<Timeline>
					{influencer.status === "invited" &&
					mission.status !== "completed" &&
					mission.enrolled >= mission.participants ? (
						<Timeline.Item
							dot={
								<Avatar
									size="small"
									icon={<WarningOutlined className="timeline-icon" />}
									style={{ backgroundColor: "#ff4d4f" }}
								/>
							}
						>
							<Space
								className="reward-prompt"
								direction="vertical"
								align="center"
								size={8}
							>
								<AntdTitle level={5}>{t("missions.full")}</AntdTitle>
								<Paragraph>{t("missions.fullText")}</Paragraph>
							</Space>
						</Timeline.Item>
					) : influencer.status === "invited" &&
					  mission.status !== "completed" &&
					  (mission.type === "confirmation" || !mission.type) ? (
						<Timeline.Item
							dot={
								<Avatar
									size="small"
									icon={<SyncOutlined className="timeline-icon" />}
								/>
							}
						>
							<Space>
								<Button
									type="primary"
									className="btn-accept button-accept-mission"
									icon={<CheckCircleFilled />}
									onClick={acceptMission}
									loading={acceptLoading}
								>
									{t("missions.acceptMission")}
								</Button>
								<Button
									type="primary"
									className="btn-reject"
									icon={<CloseCircleFilled />}
									onClick={rejectMission}
									loading={rejectLoading}
								>
									{t("influencers.reject")}
								</Button>
							</Space>
						</Timeline.Item>
					) : (
						influencer.status === "invited" &&
						mission.status !== "completed" &&
						mission.type === "proposal" && (
							<Space>
								<Button
									type="primary"
									className="btn-accept button-accept-mission"
									icon={<CheckCircleFilled />}
									onClick={() => setQuestionsOpen(true)}
									loading={acceptLoading}
								>
									{t("missions.acceptProposal")}
								</Button>
								<Button
									type="primary"
									className="btn-reject"
									icon={<CloseCircleFilled />}
									onClick={rejectMission}
									loading={rejectLoading}
								>
									{t("influencers.reject")}
								</Button>
							</Space>
						)
					)}
					{mission.tasks &&
						mission.tasks.map(
							(task, index) =>
								influencer.tasks[index] && (
									<Timeline.Item
										key={index}
										dot={
											influencer.tasks[index]?.status === "completed" ? (
												<Avatar
													size="small"
													icon={
														<CheckCircleOutlined className="timeline-icon" />
													}
													style={{ backgroundColor: "#3e9812" }}
												/>
											) : influencer.tasks[index]?.status === "refused" ? (
												<Avatar
													size="small"
													icon={
														<CloseCircleOutlined className="timeline-icon" />
													}
													style={{ backgroundColor: "#8c0000" }}
												/>
											) : task.startDate < moment().unix() ? (
												<Avatar
													size="small"
													icon={<SyncOutlined className="timeline-icon" />}
												/>
											) : (
												<Avatar
													size="small"
													icon={
														<ClockCircleOutlined className="timeline-icon" />
													}
												/>
											)
										}
									>
										<Collapse
											bordered={false}
											accordion
											className="mission-tasks"
										>
											<Panel
												key={index}
												header={
													<Space direction="vertical" style={{ width: "100%" }}>
														<span>
															<AntdTitle className="task-title" level={5}>
																{`${t("missions.taskTitle")} #${index + 1}`}
															</AntdTitle>
															<Tag
																className={`${
																	mission.influencers.find(
																		(inf) => inf.id === user.uid
																	).tasks[index].status === "completed"
																		? ""
																		: countdowns[index]?.status === "ongoing"
																		? "task-tag-ongoing"
																		: countdowns[index]?.status === "overdue" &&
																		  "task-tag-overdue"
																} mission-tag task-tag title-tag`}
															>
																<PageHeader
																	title={
																		["completed", "refused"].includes(
																			mission.influencers.find(
																				(inf) => inf.id === user.uid
																			).tasks[index].status
																		)
																			? `${t("missions.validated")} ${moment
																					.unix(
																						mission.influencers.find(
																							(inf) => inf.id === user.uid
																						).tasks[index].date
																					)
																					.format("DD/MM/YY HH:mm")}`
																			: mission.status !== "completed" &&
																			  `${
																					countdowns[index]?.status ===
																					"waiting"
																						? t("missions.startsIn")
																						: countdowns[index]?.status ===
																						  "ongoing"
																						? t("missions.endsIn")
																						: countdowns[index]?.status ===
																								"overdue" &&
																						  t("missions.overdueSince")
																			  } ${replaceDays(
																					countdowns[index]?.print
																			  )}`
																	}
																	className="task-title-small metrics-title"
																/>
															</Tag>
														</span>
														{mission.influencers.find(
															(inf) => inf.id === user.uid
														).tasks[index].remark && (
															<Alert
																description={
																	mission.influencers.find(
																		(inf) => inf.id === user.uid
																	).tasks[index].remark === ""
																		? t("missions.noRemarks")
																		: mission.influencers.find(
																				(inf) => inf.id === user.uid
																		  ).tasks[index].remark
																}
																type="error"
																showIcon
															/>
														)}
													</Space>
												}
											>
												<Space direction="vertical" size={22}>
													<Space wrap>
														<Tag className="mission-tag task-tag">
															<Avatar icon={<AppstoreAddOutlined />} />
															<PageHeader
																title={t(
																	`missions.${
																		task.type === "link" ? "other" : task.type
																	}`
																)}
																className="influencer-title-small metrics-title"
															/>
														</Tag>
														{taskTags.map((tag, key) =>
															tag.id !== "link" &&
															task[tag.id].split(" ").length > 1 ? (
																task[tag.id]
																	.split(" ")
																	.map((value, key) => (
																		<TaskTag
																			key={key}
																			tag={tag}
																			tagValue={value}
																		/>
																	))
															) : (
																<TaskTag
																	key={key}
																	tag={tag}
																	tagValue={
																		tag.id === "link" && task[tag.id] !== ""
																			? task.type === "other" ||
																			  task.type === "googleReview" ||
																			  task.type === "survey"
																				? task[tag.id]
																				: `https://socialife.ch/redirect/${mission.id}/${user.uid}/${index}`
																			: task[tag.id]
																	}
																/>
															)
														)}
													</Space>
													<Paragraph>
														<div
															dangerouslySetInnerHTML={{
																__html: task.description,
															}}
														/>
													</Paragraph>
													<Space wrap>
														{task.examples.map((example) => (
															<Image width={150} key={example} src={example} />
														))}
													</Space>
													{influencer.status === "accepted" &&
														mission.status !== "completed" &&
														influencer.tasks[index].status === "pending" && (
															<Button
																type="primary"
																className="btn-accept button-accept-mission"
																icon={<CheckCircleFilled />}
																onClick={() => validateTask(index)}
																loading={taskLoading}
																disabled={task.startDate > moment().unix()}
															>
																{t("missions.validateTask")}
															</Button>
														)}
												</Space>
											</Panel>
										</Collapse>
									</Timeline.Item>
								)
						)}
					{influencer.status === "accepted" && (
						<Timeline.Item
							dot={
								<Avatar
									size="small"
									icon={<SyncOutlined className="timeline-icon" />}
								/>
							}
						>
							<Button
								type="primary"
								className="btn-accept button-accept-mission"
								icon={<CheckCircleFilled />}
								onClick={() => setRatingOpen(true)}
								disabled={
									mission.influencers
										.find((inf) => inf.id === user.uid)
										.tasks.reduce(
											(last, task) =>
												task.status === "completed" ? last + 1 : last,
											0
										) !== mission.tasks.length
								}
							>
								{t("missions.validateMission")}
							</Button>
						</Timeline.Item>
					)}
					{influencer.status === "validating" && (
						<Timeline.Item
							dot={
								<Avatar
									size="small"
									icon={<ClockCircleOutlined className="timeline-icon" />}
								/>
							}
						>
							<Space
								className="reward-prompt"
								direction="vertical"
								align="center"
								size={8}
							>
								<AntdTitle level={5}>{t("missions.validating")}</AntdTitle>
								<Paragraph>{t("missions.validatingText")}</Paragraph>
							</Space>
						</Timeline.Item>
					)}
					{influencer.status === "completed" && (
						<Timeline.Item
							dot={
								<Avatar
									size="small"
									icon={<CheckCircleOutlined className="timeline-icon" />}
									style={{ backgroundColor: "#3e9812" }}
								/>
							}
						>
							<Space
								className="reward-prompt"
								direction="vertical"
								align="center"
								size={8}
							>
								<AntdTitle level={5}>{t("missions.completed")}</AntdTitle>
								<Paragraph>{t("missions.completedText")}</Paragraph>
							</Space>
						</Timeline.Item>
					)}
					{influencer.status !== "completed" &&
						influencer.status !== "validating" &&
						mission.status === "completed" && (
							<Timeline.Item
								dot={
									<Avatar
										size="small"
										icon={<WarningOutlined className="timeline-icon" />}
										style={{ backgroundColor: "#ff4d4f" }}
									/>
								}
							>
								<Space
									className="reward-prompt"
									direction="vertical"
									align="center"
									size={8}
								>
									<AntdTitle level={5}>
										{t("missions.completedInfluencer")}
									</AntdTitle>
									<Paragraph>{t("missions.completedTextInfluencer")}</Paragraph>
								</Space>
							</Timeline.Item>
						)}
				</Timeline>
				<Space
					className="reward-prompt"
					direction="vertical"
					align="center"
					size={8}
				>
					<AntdTitle level={5}>{t("missions.termsLabel")}</AntdTitle>
					<div dangerouslySetInnerHTML={{ __html: mission.terms }} />
				</Space>
				<Modal
					title={t("missions.rateMissionTitle")}
					open={ratingOpen}
					okText={t("missions.validateMission")}
					okButtonProps={{ disabled: missionRate === null }}
					onCancel={() => setRatingOpen(false)}
					onOk={validateMission}
					confirmLoading={valdiationLoading}
				>
					<Rate
						allowHalf
						value={missionRate}
						onChange={(val) => setMissionRate(val)}
					/>
				</Modal>
				{mission.type === "proposal" && (
					<Modal
						title={t("missions.proposalModalTitle")}
						open={questionsOpen}
						okText={t("missions.acceptProposal")}
						okButtonProps={{
							disabled:
								Object.keys(answers).length < mission.questions.length ||
								Object.values(answers)
									.map((answer) => answer)
									.includes(""),
						}}
						onCancel={() => setQuestionsOpen(false)}
						onOk={acceptMission}
						confirmLoading={acceptLoading}
					>
						<Space direction="vertical" size={20} style={{ width: "100%" }}>
							{mission.questions.map((question, index) => (
								<div key={index}>
									<Paragraph>{question.question}</Paragraph>
									<Input
										name="title"
										size="large"
										type="text"
										value={answers[question.id] || ""}
										onChange={(e) =>
											onChangeAnswer(e.target.value, question.id)
										}
									/>
								</div>
							))}
						</Space>
					</Modal>
				)}
			</Space>
		</>
	);
};

export default MissionInfluencer;
