import { Select, Modal, Space } from "antd";
import { useCallback, useEffect, useState } from "react";

import {
	audiencesIndex,
	clientsIndex,
	influencersIndex,
} from "../../data/Algolia";
import useTranslation from "../../intl/useTranslation";

const { Option } = Select;

const AudienceLoad = ({
	setMetaAudience,
	setAudience,
	loadVisible,
	setLoadVisible,
}) => {
	const [clients, setClients] = useState([]);
	const [client, setClient] = useState("general");
	const [audiences, setAudiences] = useState([]);
	const [selectedAudience, setSelectedAudience] = useState(null);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const queryClientsAloglia = useCallback(
		async () =>
			setClients(
				Object.values(
					(
						await clientsIndex.search("", {
							cacheable: false,
							hitsPerPage: 1000,
						})
					).hits
				)
			),
		[setClients]
	);

	const queryAudiencesAloglia = useCallback(async () => {
		setAudiences(
			Object.values(
				(await audiencesIndex.search(client, { cacheable: false })).hits
			)
		);
	}, [setAudiences, client]);

	useEffect(() => {
		queryClientsAloglia();
	}, [queryClientsAloglia, loadVisible]);

	useEffect(() => {
		queryAudiencesAloglia();
	}, [queryAudiencesAloglia, client, loadVisible]);

	const loadAudience = async () => {
		setLoading(true);
		setMetaAudience({
			...audiences.find((a) => a.objectID === selectedAudience),
			influencers: undefined,
		});
		const influencers = (
			await influencersIndex.search("", {
				filters: `objectID:${audiences
					.find((a) => a.objectID === selectedAudience)
					.influencers.join(" OR objectID:")}`,
				hitsPerPage: 1000,
			})
		).hits;
		setAudience(influencers);
		setLoadVisible(false);
		setLoading(false);
	};

	return (
		<Modal
			title={t("influencers.loadAudience")}
			open={loadVisible}
			onOk={loadAudience}
			okButtonProps={{
				disabled: selectedAudience === null,
				loading,
			}}
			okText={t("influencers.load")}
			onCancel={() => {
				setClient("general");
				setSelectedAudience(null);
				setLoadVisible(false);
			}}
			cancelText={t("influencers.cancel")}
		>
			<Space style={{ width: "100%" }} direction="vertical">
				<Select
					showSearch
					size="large"
					placeholder={t("missions.clientLabel")}
					optionFilterProp="children"
					className="client-select"
					onChange={(value) => {
						setClient(value);
					}}
					value={client}
				>
					<Option value={"general"}>{t("missions.general")}</Option>
					{clients.map((client) => (
						<Option key={client.objectID} value={client.objectID}>
							{client.name}
						</Option>
					))}
				</Select>
				<Select
					showSearch
					size="large"
					placeholder={t("missions.audienceLabel")}
					optionFilterProp="label"
					className="client-select"
					onChange={(value) => setSelectedAudience(value)}
					value={selectedAudience}
					options={audiences.map((audience) => ({
						label: `${audience.name} (${audience.influencers.length})`,
						value: audience.objectID,
					}))}
				/>
			</Space>
		</Modal>
	);
};
export default AudienceLoad;
