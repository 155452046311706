import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getFunctions } from "firebase/functions";
import { getPerformance } from "firebase/performance";
import { getStorage } from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyCDY1Kc6s5MRQlXU23UEdDZDdhuV8v-vIE",
	authDomain: "socialife-ch.firebaseapp.com",
	databaseURL:
		"https://socialife-ch-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "socialife-ch",
	storageBucket: "socialife-ch.appspot.com",
	messagingSenderId: "837093571698",
	appId: "1:837093571698:web:d2a27c46de4ffd6e7e16eb",
	measurementId: "G-W52HZW2CHP",
};

const app = initializeApp(firebaseConfig);

export const appCheck = initializeAppCheck(app, {
	provider: new ReCaptchaV3Provider("6LcAPdwaAAAAAK-9120ec3erk1tmWZ5-Fo4SuhQ1"),
	isTokenAutoRefreshEnabled: true,
});

export default getFirestore();
export const analytics = getAnalytics();
export const auth = getAuth();
export const connectedRef = getDatabase(app);
export const fbFunctions = getFunctions(app, "europe-west6");
export const fbPerf = getPerformance(app);
export const storageRef = getStorage(app);
