import { createSlice } from "@reduxjs/toolkit";

export const layoutSlice = createSlice({
	name: "theme",
	initialState: "dark",
	reducers: {
		editTheme: (state, action) => {
			state = action.payload;

			return state;
		},
	},
});

export const { editTheme } = layoutSlice.actions;

export const getTheme = (state) => state.theme;

export default layoutSlice.reducer;
