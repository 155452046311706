import { createSlice } from "@reduxjs/toolkit";

export const missionsSlice = createSlice({
	name: "missions",
	initialState: null,
	reducers: {
		editMission: (state, action) => {
			if (state === null) return [action.payload];
			return [
				...state.filter((currMission) => action.payload.id !== currMission.id),
				action.payload,
			];
		},
		editMissions: (state, action) => {
			return action.payload;
		},
		editInfluencers: (state, action) => {
			const missions = state;
			const { influencers, missionId } = action.payload;
			const mission = missions.find((mission) => mission.id === missionId);
			mission.influencers = influencers;

			return [
				...missions.filter((mission) => mission.id !== missionId),
				mission,
			];
		},
	},
});

export const { editMission, editMissions, editInfluencers } =
	missionsSlice.actions;

export const getMissions = (state) => state.missions;

export const getMission = (state, id) =>
	Array.isArray(state.missions) &&
	state.missions.find((mission) => mission.id === id);

export default missionsSlice.reducer;
