import { useEffect, useState, useCallback } from "react";
import { Button, Col, Divider, Input, Row, Space } from "antd";
import { SearchOutlined, PlusOutlined, SyncOutlined } from "@ant-design/icons";

import { missionsIndex as index } from "../../data/Algolia";
import useTranslation from "../../intl/useTranslation";
import { useNavigate } from "react-router-dom";

const SearchMissions = ({
	setMissions,
	currentPage,
	setTotalResults,
	pageSize,
	type = null,
}) => {
	const [search, setSearch] = useState({ query: "" });
	const [searchInput, setSearchInput] = useState("");
	const [refreshLoading, setRefreshLoading] = useState(false);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const onChangeSearchQuery = (e) => setSearchInput(e.target.value);

	const queryAloglia = useCallback(async () => {
		const searchResult = await index.search(search.query, {
			page: currentPage - 1,
			hitsPerPage: pageSize,
			filters: type ? `type:${type}` : "NOT type:draft AND NOT type:proposal",
			cacheable: false,
		});
		setTotalResults(searchResult.nbHits);
		setMissions(Object.values(searchResult.hits));
	}, [search.query, setMissions, currentPage, pageSize, setTotalResults, type]);

	useEffect(() => {
		queryAloglia();
	}, [search, queryAloglia]);

	useEffect(() => {
		if (refreshLoading) {
			queryAloglia();
			setRefreshLoading(false);
		}
	}, [refreshLoading, queryAloglia]);

	useEffect(() => {
		queryAloglia();
	}, [queryAloglia, currentPage, pageSize]);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (search.query !== searchInput)
				setSearch({ ...search, query: searchInput });
		}, 400);

		return () => clearTimeout(delayDebounceFn);
	}, [searchInput, search]);

	return (
		<Space direction="vertical" className="search-wrapper" size={16}>
			<Row>
				<Col lg={12}>
					<Input
						size="large"
						placeholder={t("missions.searchPlaceholder")}
						className="search-input"
						suffix={<SearchOutlined />}
						onChange={onChangeSearchQuery}
					/>
				</Col>
				<Col flex="auto" />
				<Col>
					<Space>
						<Button
							size="large"
							loading={refreshLoading}
							onClick={() => setRefreshLoading(true)}
							icon={<SyncOutlined />}
						/>
						<Button
							size="large"
							onClick={() => navigate("new")}
							icon={<PlusOutlined />}
						>
							{t("missions.actionNew")}
						</Button>
					</Space>
				</Col>
			</Row>

			<Divider />
		</Space>
	);
};

export default SearchMissions;
