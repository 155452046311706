import { Space, Typography, Button, Form, Switch } from "antd";
import db from "../../data/Firebase";
import { useState } from "react";
import { useSelector } from "react-redux";
import useTranslation from "../../intl/useTranslation";
import { getUser } from "../login/loginSlice";
import { doc, updateDoc } from "firebase/firestore";

const { Title } = Typography;

const Notifications = ({ success, error }) => {
	const { t } = useTranslation();
	const user = useSelector(getUser);
	const [loading, setLoading] = useState(false);

	const changeNotifications = async ({ sms, email, whatsapp }) => {
		try {
			setLoading(true);
			await updateDoc(doc(db, "influencers", user.uid), {
				notificationSettings: { sms: sms, email: email, whatsapp: whatsapp },
			});
			setLoading(false);
			success(t("settings.notificationsTitle"));
		} catch (error) {
			setLoading(false);
			error();
		}
	};

	return (
		<Space direction="vertical" size={24} className="settings-wrapper">
			<Form onFinish={changeNotifications}>
				<Space className="settings-section" direction="vertical" size={12}>
					<Title level={5}>{t("settings.notificationsTitle")}</Title>
					<Form.Item
						name="email"
						label={t("settings.notificationsEmail")}
						valuePropName="checked"
						initialValue={user.notificationSettings.email}
					>
						<Switch />
					</Form.Item>
					<Form.Item
						name="sms"
						label={t("settings.notificationsSms")}
						valuePropName="checked"
						initialValue={user.notificationSettings.sms}
					>
						<Switch />
					</Form.Item>
					<Form.Item
						name="whatsapp"
						label={t("settings.notificationsWhatsapp")}
						valuePropName="checked"
						initialValue={user.notificationSettings.whatsapp}
					>
						<Switch />
					</Form.Item>
					<div className="settings-section-button">
						<Form.Item>
							<Button type="primary" htmlType="submit" loading={loading}>
								{t("settings.applyLabel")}
							</Button>
						</Form.Item>
					</div>
				</Space>
			</Form>
		</Space>
	);
};

export default Notifications;
