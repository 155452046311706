import {
	Button,
	Col,
	Divider,
	Input,
	InputNumber,
	Row,
	Select,
	Space,
	Steps,
	Typography,
	message,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import useTranslation from "../../intl/useTranslation";
import UploadAvatar from "../../lib/components/UploadAvatar";
import Title from "../layout/Title";
import db from "../../data/Firebase";
import { clientsIndex, audiencesIndex } from "../../data/Algolia";
import {
	collection,
	deleteDoc,
	doc,
	setDoc,
	updateDoc,
} from "firebase/firestore";
import RichTextEditor from "../../lib/components/RichTextEditor";
import moment from "moment";
import generateId from "../../lib/generateId";
import emptyQuestion from "../../data/emptyQuestion";
import emptyTask from "../../data/emptyTask";
import NewMissionQuestions from "./NewMissionQuestions";
import NewMissionTasks from "./NewMissionTasks";

const { Step } = Steps;
const { Option } = Select;
const { Title: AntdTitle } = Typography;

const NewMission = ({ editMission = null, setOpenEditMission = null }) => {
	const [step, setStep] = useState(0);
	const [mission, setMission] = useState(
		(editMission && editMission.type === "confirmation") ||
			(editMission && editMission.creationType === "confirmation")
			? {
					...editMission,
					tasks: editMission.tasks.map((task) => ({
						...task,
						startDate: task.startDate ? moment.unix(task.startDate) : null,
						dueDate: task.dueDate ? moment.unix(task.dueDate) : null,
					})),
			  }
			: (editMission && editMission.type === "proposal") ||
			  (editMission && editMission.creationType === "proposal")
			? {
					...editMission,
			  }
			: {
					title: "",
					description: "",
					client: null,
					audience: null,
					image: "",
					reward: null,
					currency: "CHF",
					terms: "",
					participants: null,
					status: "new",
					type: "new",
					creationType: "confirmation",
					tasks: [
						{
							...emptyTask,
							id: generateId(10),
						},
					],
					questions: [
						{
							...emptyQuestion,
							id: generateId(10),
						},
					],
			  }
	);
	const [docId] = useState(
		editMission?.id || doc(collection(db, "missions")).id
	);
	const [clients, setClients] = useState([]);
	const [audiences, setAudiences] = useState([]);
	const [generalAudience, setGeneralAudience] = useState([]);
	const [Loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const navigate = useNavigate();

	const createMission = async () => {
		setLoading(true);
		const tasks = mission.tasks.map((task) => ({
			...task,
			dueDate: task?.dueDate?.unix() || null,
			startDate: task?.startDate?.unix() || null,
		}));

		const newMission = {
			...mission,
			tasks,
			id: docId,
			type: mission.creationType,
			status: "inprogress",
		};
		if (newMission.creationType === "proposal") {
			delete newMission.tasks;
		} else {
			delete newMission.questions;
		}
		delete newMission.creationType;

		const missionDoc = doc(collection(db, "missions"), docId);

		await setDoc(missionDoc, newMission);
		message.success(t("missions.createMissionSuccess"));
		setLoading(false);
		if (newMission.type === "proposal") {
			navigate(`../proposals`);
		} else {
			navigate(`..`);
		}
	};

	const createDraft = async () => {
		setLoading(true);
		const tasks = mission.tasks.map((task) => ({
			...task,
			dueDate: task?.dueDate?.unix() || null,
			startDate: task?.startDate?.unix() || null,
		}));

		const missionDoc = doc(collection(db, "missions"), docId);

		await setDoc(missionDoc, {
			...mission,
			tasks,
			id: docId,
			type: "draft",
			status: "draft",
		});
		message.success(t("missions.saveDraftSuccess"));
		setLoading(false);
		navigate(`../drafts`);
	};

	const createMissionDraft = async () => {
		setLoading(true);
		const tasks =
			mission.tasks &&
			mission.tasks.map((task) => ({
				...task,
				dueDate: task?.dueDate?.unix() || null,
				startDate: task?.startDate?.unix() || null,
			}));

		const missionDoc = doc(
			collection(db, "missions"),
			doc(collection(db, "missions")).id
		);

		const newMission = {
			...mission,
			tasks,
			id: missionDoc.id,
			type: mission.creationType,
			status: "inprogress",
		};
		if (newMission.creationType === "proposal") {
			delete newMission.tasks;
		} else {
			delete newMission.questions;
		}
		delete newMission.creationType;

		await setDoc(missionDoc, newMission);
		await deleteDoc(doc(db, "missions", editMission.id));
		message.success(t("missions.createMissionSuccess"));
		setLoading(false);
		setOpenEditMission(null);
		if (newMission.type === "proposal") {
			navigate(`../proposals`);
		} else {
			navigate(`..`);
		}
	};

	const updateMission = async () => {
		setLoading(true);
		const newMission = { ...mission };
		if (
			newMission?.creationType === "confirmation" ||
			newMission?.type === "confirmation"
		) {
			const tasks = mission.tasks.map((task) => ({
				...task,
				dueDate: task?.dueDate?.unix() || null,
				startDate: task?.startDate?.unix() || null,
			}));
			newMission.tasks = tasks;
		}

		const missionDoc = doc(collection(db, "missions"), docId);

		await updateDoc(missionDoc, {
			...newMission,
			id: docId,
			influencers: mission.influencers
				.filter((influencer) => influencer.status !== "declined")
				.map((influencer) => influencer.id),
			lastmodified: moment().valueOf(),
		});
		message.success(t("missions.missionUpdatedSuccess"));
		setLoading(false);
		setOpenEditMission(null);
	};

	const queryClientsAloglia = useCallback(
		async () =>
			setClients(
				Object.values(
					(
						await clientsIndex.search("", {
							cacheable: false,
							hitsPerPage: 1000,
						})
					).hits
				)
			),
		[setClients]
	);

	const queryAudiencesAloglia = useCallback(async () => {
		setAudiences(
			Object.values((await audiencesIndex.search(mission.client)).hits)
		);
		setGeneralAudience(
			Object.values((await audiencesIndex.search("general")).hits)
		);
	}, [setAudiences, mission.client]);

	useEffect(() => {
		queryClientsAloglia();
	}, [queryClientsAloglia]);

	useEffect(() => {
		queryAudiencesAloglia();
	}, [queryAudiencesAloglia, mission.client]);

	const onChange = (e) =>
		setMission({ ...mission, [e.target.name]: e.target.value });

	const steps = [
		{
			title: t("missions.newStep1"),
			content: (
				<Row className="step-content" align="middle" justify="space-around">
					<Col span={24} lg={editMission ? 24 : 12}>
						<Space className="mission-form" direction="vertical" size={16}>
							{(!editMission || editMission.type === "draft") && (
								<Select
									size="large"
									onChange={(value) => {
										setMission({
											...mission,
											creationType: value,
										});
									}}
									value={mission.creationType}
								>
									<Option value="confirmation">
										{t("missions.confirmation")}
									</Option>
									<Option value="proposal">{t("missions.proposal")}</Option>
								</Select>
							)}
							<Input
								name="title"
								size="large"
								type="text"
								defaultValue={mission.title}
								placeholder={t("missions.titleLabel")}
								onChange={onChange}
							/>
							<span>
								<AntdTitle level={5}>
									{t("missions.descriptionLabel")}
								</AntdTitle>
								<RichTextEditor
									value={mission.description}
									onChange={onChange}
									name={"description"}
								/>
							</span>
							{(!editMission || editMission.type === "draft") && (
								<>
									<Select
										showSearch
										size="large"
										placeholder={t("missions.clientLabel")}
										optionFilterProp="children"
										className="client-select"
										onChange={(value) => {
											setMission({
												...mission,
												audience: null,
												client: value,
											});
										}}
										value={mission.client}
									>
										{clients.map((client) => (
											<Option key={client.objectID} value={client.objectID}>
												{client.name}
											</Option>
										))}
									</Select>
									<Select
										showSearch
										size="large"
										placeholder={t("missions.audienceLabel")}
										optionFilterProp="label"
										className="client-select"
										disabled={mission.client === ""}
										onChange={(value) =>
											onChange({ target: { name: "audience", value: value } })
										}
										value={mission.audience}
										options={[
											{ label: t("missions.allInfluencers"), value: "all" },
											{
												label: t("missions.general"),
												options: generalAudience.map((audience) => ({
													label: `${audience.name} (${audience.influencers.length})`,
													value: audience.objectID,
												})),
											},
											{
												label: "Client",
												options: audiences.map((audience) => ({
													label: `${audience.name} (${audience.influencers.length})`,
													value: audience.objectID,
												})),
											},
										]}
									/>
								</>
							)}
							<UploadAvatar
								path={`/missions/${docId}`}
								img={mission.image}
								setImg={onChange}
								name="image"
							/>
							<Space size={4}>
								<InputNumber
									size="large"
									name="reward"
									min={0}
									style={{ width: "100%" }}
									value={mission.reward}
									onChange={(value) =>
										onChange({ target: { name: "reward", value: value } })
									}
									placeholder={t("missions.rewardLabel")}
								/>
								<Select
									onChange={(value) =>
										onChange({ target: { name: "currency", value: value } })
									}
									value={mission.currency}
									size="large"
									className="onboarding-select"
								>
									<Option value="CHF">CHF</Option>
									<Option value="EUR">EUR</Option>
								</Select>
							</Space>
							<InputNumber
								size="large"
								name="participants"
								min={1}
								style={{ width: "100%" }}
								value={mission.participants}
								onChange={(value) =>
									onChange({ target: { name: "participants", value: value } })
								}
								placeholder={t("missions.participantsLabel")}
							/>
							<span>
								<AntdTitle level={5}>{t("missions.termsLabel")}</AntdTitle>
								<RichTextEditor
									value={mission.terms}
									onChange={onChange}
									name={"terms"}
								/>
							</span>
							<Divider />
							<Row>
								<Col flex="auto"></Col>
								<Col>
									<Space>
										{(mission.type === "new" || mission.type === "draft") && (
											<Button
												size="large"
												type="ghost"
												onClick={() =>
													mission.type === "new"
														? createDraft()
														: updateMission()
												}
											>
												{t("missions.saveDraft")}
											</Button>
										)}
										<Button
											size="large"
											type="primary"
											disabled={
												mission.title === "" ||
												mission.description === "" ||
												mission.client === "" ||
												mission.client === null ||
												mission.audience === null ||
												mission.image === "" ||
												mission.reward === null ||
												mission.participants === null
											}
											onClick={() => setStep(step + 1)}
										>
											{t("missions.next")}
										</Button>
									</Space>
								</Col>
							</Row>
						</Space>
					</Col>
				</Row>
			),
		},
		{
			title: t("missions.newStep2"),
			content: (
				<Row className="step-content" align="middle" justify="space-around">
					<Col span={24} lg={editMission ? 24 : 12}>
						<Space className="mission-form" direction="vertical" size={24}>
							{mission.creationType === "confirmation" ||
							mission.type === "confirmation" ? (
								<NewMissionTasks
									mission={mission}
									setMission={setMission}
									docId={docId}
								/>
							) : (
								<NewMissionQuestions
									mission={mission}
									setMission={setMission}
								/>
							)}
							<Divider />
							<Row>
								<Col>
									<Button
										size="large"
										type="ghost"
										onClick={() => setStep(step - 1)}
									>
										{t("missions.back")}
									</Button>
								</Col>
								<Col flex="auto"></Col>
								<Col>
									<Space>
										{(mission.type === "new" || mission.type === "draft") && (
											<Button
												size="large"
												type="ghost"
												onClick={() =>
													mission.type === "new"
														? createDraft()
														: updateMission()
												}
											>
												{t("missions.saveDraft")}
											</Button>
										)}
										<Button
											size="large"
											type="primary"
											loading={Loading}
											disabled={
												mission.creationType === "confirmation" ||
												mission.type === "confirmation"
													? mission.tasks
															.map(
																(task) =>
																	task.type === null ||
																	task.description === "" ||
																	task.dueDate === null
															)
															.includes(true)
													: mission?.questions
															.map((question) => question.question === "")
															.includes(true)
											}
											onClick={() => {
												if (editMission && mission.type !== "draft") {
													updateMission();
												} else if (editMission && mission.type === "draft") {
													createMissionDraft();
												} else {
													createMission();
												}
											}}
										>
											{editMission && mission.type !== "draft"
												? t("missions.save")
												: t("missions.create")}
										</Button>
									</Space>
								</Col>
							</Row>
						</Space>
					</Col>
				</Row>
			),
		},
	];

	return (
		<>
			{!editMission && (
				<Title
					title={t("missions.newMissionTitle")}
					onBack={() => navigate("..")}
				/>
			)}
			<Steps className="missions-stepper" size="small" current={step}>
				{steps.map((item) => (
					<Step key={item.title} title={item.title} />
				))}
			</Steps>
			{steps[step].content}
		</>
	);
};

export default NewMission;
