import { useState } from "react";
import { Upload, message } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { UploadRequestOption } from "rc-upload/lib/interface";

import { storageRef } from "../../data/Firebase";
import moment from "moment";
import generateId from "../generateId";
import useTranslation from "../../intl/useTranslation";

const fileTypes = [
	"image/jpeg",
	"image/png",
	"image/gif",
	"video/webm",
	"video/mp4",
	"video/ogg",
	"video/mpeg",
];

const videoTypes = ["video/webm", "video/mp4", "video/ogg", "video/mpeg"];

function UploadImages({ path, images, setImages, name = "avatar", index = 0 }) {
	const [files, setFiles] = useState(
		images.map((url, key) => ({ url, status: "done", uid: key }))
	);
	const { t } = useTranslation();

	const beforeUpload = (file) => {
		const isAllowed = fileTypes.includes(file.type);
		if (!isAllowed) {
			message.error(t("missions.wrongFileFormat"));
		}
		const isLt2M = file.size / 1024 / 1024 < 10;
		if (!isLt2M) {
			message.error(t("missions.fileTooLarge"));
		}
		return isAllowed && isLt2M;
	};

	const upload = async ({
		onError,
		onSuccess,
		onProgress,
		file,
	}: UploadRequestOption<any>) => {
		try {
			const fileObject: File = file as File;
			onProgress({ percent: 0 });
			const imgRef = ref(
				storageRef,
				`${path}/${moment().unix()}-${generateId(5)}-${fileObject.name}`
			);
			const imgUrl = await getDownloadURL(
				(
					await uploadBytes(imgRef, file as Blob)
				).ref
			);
			setImages({ target: { value: [...images, imgUrl], name: name } }, index);
			setFiles([
				...files,
				{
					url: videoTypes.includes((file as Blob).type)
						? createThumbnail(imgUrl)
						: imgUrl,
					status: "done",
				},
			]);
			onSuccess(null);
		} catch (error) {
			onError(error);
		}
	};

	const createThumbnail = (src) => {
		const canvas = document.createElement("canvas");
		const video = document.createElement("video");
		video.src = src;
		video.width = 300;
		video.height = 300;
		canvas
			.getContext("2d")
			.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
		return canvas.toDataURL("image/jpeg");
	};

	const remove = async (file) => {
		setFiles(files.filter((f) => f.url !== file.url));
		setImages(
			{
				target: {
					value: [...images.filter((item) => item !== file.url)],
					name: name,
				},
			},
			index
		);
	};

	const uploadButton = (
		<div>
			<PlusOutlined />
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);

	return (
		<Upload
			listType="picture-card"
			className="avatar-uploader"
			fileList={files}
			customRequest={upload}
			beforeUpload={beforeUpload}
			onRemove={remove}
		>
			{uploadButton}
		</Upload>
	);
}

export default UploadImages;
