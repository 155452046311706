const emptyTask = {
	type: null,
	description: "",
	dos: "",
	donts: "",
	hashtags: "",
	tags: "",
	link: "",
	startDate: null,
	dueDate: null,
	examples: [],
};

export default emptyTask;
