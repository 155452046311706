import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Layout, Button } from "antd";

import SettingsIcon from "../../icons/SettingsIcon";

const { Header } = Layout;

const MobileHeader = ({ logoOnly = false }) => {
	const location = useLocation();

	return (
		<Header className="mobile-header">
			<img
				src="/SocialifeIcon.svg"
				alt="Socialife Icon"
				className="mobile-header-logo"
			/>
			<div className="header-spacer" />
			{!logoOnly && (
				<Link to="/settings" state={{ prevPath: location.pathname }}>
					<Button
						type="text"
						icon={<SettingsIcon />}
						size="large"
						className="menu-icon"
					/>
				</Link>
			)}
		</Header>
	);
};

export default MobileHeader;
