import { Space } from "antd";
import { Instagram, Facebook, YouTube, LinkedIn } from "@mui/icons-material";

import networks, { activeNetworks } from "../../data/networks";
import InfluencerInfo from "./InfluencerInfo";
import { TiktokBoldIcon } from "../../icons/TiktokIcon";
import profileUrl from "../../lib/profileUrl.json";

const networkIcons = {
	instagram: <Instagram fontSize="inherit" />,
	facebook: <Facebook fontSize="inherit" />,
	youtube: <YouTube fontSize="inherit" />,
	linkedin: <LinkedIn fontSize="inherit" />,
	tiktok: <TiktokBoldIcon />,
};

const NetworkButtons = ({ influencer, stats = false }) => {
	const influencerNetworks = activeNetworks(influencer);

	return (
		<Space className="influencer-card-content" wrap align="start" size="large">
			{networks
				.filter((network) => influencerNetworks.includes(network.id))
				.map((network) => (
					<a
						href={
							network.id === "facebook" || network.id === "linkedin"
								? influencer[network.id].url
								: network.id === "youtube"
								? profileUrl[network.id] + influencer[network.id].id
								: profileUrl[network.id] + influencer[network.id].username
						}
						target="_BLANK"
						rel="noreferrer"
						key={network.id}
					>
						<InfluencerInfo
							icon={networkIcons[network.id]}
							data={
								network.id === "facebook" || network.id === "linkedin"
									? network.label
									: influencer[network.id].username
							}
							extra={stats ? influencer[network.id].followers : undefined}
						/>
					</a>
				))}
		</Space>
	);
};

export default NetworkButtons;
