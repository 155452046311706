import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, message, Modal, PageHeader, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";

import { fbFunctions } from "../../data/Firebase";
import useTranslation from "../../intl/useTranslation";
import removeTrailingParam from "../../lib/removeTrailingParam";

const { Text } = Typography;

const CompleteMission = ({ mission }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const location = useLocation();

	const [completing, setCompleting] = useState(false);
	const [completeModal, setCompleteModal] = useState(false);

	const completeMission = async () => {
		setCompleting(true);
		const completeFunction = httpsCallable(fbFunctions, "completeMission");
		await completeFunction({ missionId: mission.id });
		setCompleting(false);
		message.success(t("missions.completeSuccess"));
		navigate(removeTrailingParam(location.pathname));
	};

	return (
		<>
			<Button
				type="primary"
				className="btn-reject"
				onClick={() => setCompleteModal(true)}
				loading={completing}
			>
				{t("missions.complete")}
			</Button>
			<Modal
				open={completeModal}
				onOk={completeMission}
				confirmLoading={completing}
				onCancel={() => setCompleteModal(false)}
				okText={t("missions.confirm")}
				cancelText={t("missions.cancel")}
				className="influencer-confirm-modal"
			>
				<Text>{t("missions.complete")}</Text>
				<PageHeader
					title={mission.title}
					className="influencer-title-small"
					avatar={{ src: mission.image, icon: <UserOutlined /> }}
				/>
			</Modal>
		</>
	);
};

export default CompleteMission;
