import { Alert, Button, Card, Empty, Space, Typography } from "antd";
import { BulbFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import useTranslation from "../../intl/useTranslation";
import MissionIcon from "../../icons/MissionIcon";

const { Title } = Typography;

const EmptyMissions = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<Card className="mission-card" bordered={false} hoverable>
			<Empty
				imageStyle={{
					fontSize: 30,
					height: 70,
					display: "flex",
					justifyContent: "center",
				}}
				image={<MissionIcon />}
				description={
					<>
						<Title level={5}>{t("influencer.noMissionsTitle")}</Title>
						{t("influencer.noMissionsDescription")}
					</>
				}
			>
				<Space direction="vertical" size={24}>
					<Alert
						message={t("influencer.noMissionsTips")}
						type="info"
						icon={<BulbFilled />}
						showIcon
					/>
					<Button onClick={(_) => navigate("settings/profile")} type="primary">
						{t("influencer.updateAction")}
					</Button>
				</Space>
			</Empty>
		</Card>
	);
};

export default EmptyMissions;
