import { Button, Result, Space, Tag } from "antd";
import InfluencerInfo from "./InfluencerInfo";
import profileUrl from "../../lib/profileUrl.json";
import { Instagram, Facebook, YouTube, LinkedIn } from "@mui/icons-material";
import { TiktokBoldIcon } from "../../icons/TiktokIcon";
import AuditReport from "./AuditReport";
import { LoadingOutlined, DownloadOutlined } from "@ant-design/icons";
import useTranslation from "../../intl/useTranslation";
import moment from "moment";

const networkIcons = {
	instagram: <Instagram fontSize="inherit" />,
	facebook: <Facebook fontSize="inherit" />,
	youtube: <YouTube fontSize="inherit" />,
	linkedin: <LinkedIn fontSize="inherit" />,
	tiktok: <TiktokBoldIcon />,
};

const NetworkTab = ({ influencer, network }) => {
	const { t } = useTranslation();

	return (
		<Space direction="vertical" style={{ width: "100%" }}>
			<Space
				className="influencer-card-content"
				wrap
				align="center"
				style={{ width: "100%" }}
				size="small"
			>
				<a
					href={
						network.id === "facebook" || network.id === "linkedin"
							? influencer[network.id].url
							: network.id === "youtube"
							? profileUrl[network.id] + influencer[network.id].id
							: profileUrl[network.id] + influencer[network.id].username
					}
					target="_BLANK"
					rel="noreferrer"
					key={network.id}
				>
					<InfluencerInfo
						icon={networkIcons[network.id]}
						data={
							network.id === "facebook" || network.id === "linkedin"
								? network.label
								: influencer[network.id].username
						}
						extra={influencer[network.id].followers}
					/>
				</a>
				{influencer[network.id].updatedAt && (
					<Tag className="date-tag">
						{moment
							.unix(influencer[network.id].updatedAt.seconds)
							.format("DD/MM/YY")}
					</Tag>
				)}
				<Button disabled icon={<DownloadOutlined />}>
					PDF
				</Button>
			</Space>
			{influencer[network.id].hasOwnProperty("audit") &&
				(influencer[network.id].audit === "loading" ? (
					<Result
						icon={<LoadingOutlined spin />}
						title={t("influencers.auditLoadingTitle")}
						subTitle={t("influencers.auditLoadingSubtitle")}
					/>
				) : influencer[network.id].audit === "notfound" ? (
					<Result
						title={t("influencers.auditNotFoundTitle")}
						subTitle={t("influencers.auditNotFoundSubtitle")}
					/>
				) : influencer[network.id].audit === "notenoughfollowers" ? (
					<Result
						title={t("influencers.auditNotEnoughFollowersTitle")}
						subTitle={t("influencers.auditNotEnoughFollowersSubtitle")}
					/>
				) : influencer[network.id].audit === "private" ? (
					<Result
						title={t("influencers.auditPrivateProfileTitle")}
						subTitle={t("influencers.auditPrivateProfileSubtitle")}
					/>
				) : influencer[network.id].audit === "restricted" ? (
					<Result
						title={t("influencers.auditRestrictedProfileTitle")}
						subTitle={t("influencers.auditRestrictedProfileSubtitle")}
					/>
				) : (
					<AuditReport audit={influencer[network.id]} influencer={influencer} />
				))}
		</Space>
	);
};

export default NetworkTab;
