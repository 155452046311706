import { Space, Typography, Checkbox, Tag } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import FilterCard from "./FilterCard";
import useTranslation from "../../intl/useTranslation";
import { getName } from "i18n-iso-countries";
import { useSelector } from "react-redux";
import { getUser } from "../login/loginSlice";

const { Group } = Checkbox;
const { Text } = Typography;

const FiltersLocation = ({
	search,
	onChangeFilter,
	countries,
	states,
	cities,
}) => {
	const { t } = useTranslation();
	const user = useSelector(getUser);

	return (
		<FilterCard title={t("influencers.location")}>
			<Space direction="vertical" size={16}>
				<Space direction="vertical" size={8}>
					<Text strong>{t("influencers.countries")}</Text>
					<Group
						onChange={(value) => onChangeFilter("country", value, ":")}
						value={
							typeof search.filters["country"] !== "undefined"
								? search.filters["country"].value
								: []
						}
					>
						<Space size={4} direction="vertical" className="filter-interests">
							{countries !== null &&
								(countries.length === 0 ? (
									<span>
										<SearchOutlined /> No countries
									</span>
								) : (
									countries.map((country) => (
										<Checkbox key={country.value} value={country.value}>
											{getName(country.value, user.language)}{" "}
											<Tag className="count-tag">{country.count}</Tag>
										</Checkbox>
									))
								))}
						</Space>
					</Group>
				</Space>
				<Space direction="vertical" size={8}>
					<Text strong>{t("influencers.states")}</Text>
					<Group
						onChange={(value) => onChangeFilter("state", value, ":")}
						value={
							typeof search.filters["state"] !== "undefined"
								? search.filters["state"].value
								: []
						}
					>
						<Space size={4} direction="vertical" className="filter-interests">
							{states !== null &&
								(states.length === 0 ? (
									<span>
										<SearchOutlined /> No states
									</span>
								) : (
									states.map((state) => (
										<Checkbox key={state.value} value={state.value}>
											{state.value}{" "}
											<Tag className="count-tag">{state.count}</Tag>
										</Checkbox>
									))
								))}
						</Space>
					</Group>
				</Space>
				<Space direction="vertical" size={8}>
					<Text strong>{t("influencers.cities")}</Text>
					<Group
						onChange={(value) => onChangeFilter("city", value, ":")}
						value={
							typeof search.filters["city"] !== "undefined"
								? search.filters["city"].value
								: []
						}
					>
						<Space size={4} direction="vertical" className="filter-interests">
							{cities !== null &&
								(cities.length === 0 ? (
									<span>
										<SearchOutlined /> No cities
									</span>
								) : (
									cities.map((city) => (
										<Checkbox key={city.value} value={city.value}>
											{city.value} <Tag className="count-tag">{city.count}</Tag>
										</Checkbox>
									))
								))}
						</Space>
					</Group>
				</Space>
			</Space>
		</FilterCard>
	);
};

export default FiltersLocation;
