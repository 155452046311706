import { Result } from "antd";

import InfluencersIcon from "../../icons/InfluencersIcon";
import useTranslation from "../../intl/useTranslation";

const NotFound = () => {
	const { t } = useTranslation();
	return (
		<Result icon={<InfluencersIcon />} title={t("influencers.notFound")} />
	);
};

export default NotFound;
