import JoditEditor from "jodit-react";

const buttons =
	"bold, italic, underline, brush, |, ul, ol, |, undo, redo, |, fullsize";

const RichTextEditor = ({ value, name, onChange, index = null }) => {
	return (
		<JoditEditor
			value={value}
			onBlur={(value) =>
				index !== null
					? onChange({ target: { name: name, value: value } }, index)
					: onChange({ target: { name: name, value: value } })
			}
			config={{
				readonly: false,
				theme: "dark",
				buttons: buttons,
				buttonsMD: buttons,
				buttonsSM: buttons,
				buttonsXS: buttons,
				defaultActionOnPaste: "insert_clear_html",
				disablePlugins: "paste",
			}}
		/>
	);
};

export default RichTextEditor;
