import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { Provider } from "react-redux";

import store from "./data/store";
import App from "./App";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
	<React.StrictMode>
		<Provider store={store}>
			<Router>
				<App />
			</Router>
		</Provider>
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

serviceWorkerRegistration.unregister();

// Unregister all service workers and then clear all caches
navigator.serviceWorker
	.getRegistrations()
	.then(function (registrations) {
		// Unregister all service workers
		for (let registration of registrations) {
			registration.unregister();
		}
	})
	.then(function () {
		// After all service workers are unregistered, clear all caches
		return caches.keys().then(function (cacheNames) {
			return Promise.all(
				cacheNames.map(function (cacheName) {
					return caches.delete(cacheName);
				})
			);
		});
	});
