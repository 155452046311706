import { Card, Col, Row, Typography, Badge } from "antd";
import { RightOutlined } from "@ant-design/icons";
import {
	collection,
	doc,
	limit,
	onSnapshot,
	query,
	where,
} from "firebase/firestore";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
	editMission,
	editMissions,
	getMissions,
} from "../missions/missionsSlice";
import db from "../../data/Firebase";
import { getUser } from "../login/loginSlice";
import useTranslation from "../../intl/useTranslation";
import { useNavigate } from "react-router-dom";
import MissionCardInfluencer from "../missions/MissionCardInfluencer";
import EmptyMissions from "../missions/EmptyMissions";

const { Meta } = Card;
const { Title } = Typography;
const { Ribbon } = Badge;

const DashboardMissions = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const missions = useSelector(getMissions);
	const user = useSelector(getUser);

	useEffect(() => {
		const missionsQuery = query(
			collection(db, "missions"),
			where("influencers", "array-contains", user.uid),
			where("status", "==", "inprogress"),
			limit(6)
		);
		onSnapshot(missionsQuery, (querySnapshot) => {
			if (querySnapshot.empty) dispatch(editMissions([]));
			querySnapshot.forEach((mission) => {
				const influencerRef = doc(
					db,
					`missions/${mission.id}/influencers`,
					user.uid
				);
				onSnapshot(influencerRef, (influencer) => {
					dispatch(
						editMission({
							...mission.data(),
							id: mission.id,
							influencers: [{ ...influencer.data(), id: influencer.id }],
						})
					);
				});
			});
		});
	}, [dispatch, user.uid]);

	if (missions === null)
		return (
			<Row gutter={[16, 16]}>
				{[...Array(4)].map((_, index) => (
					<Col key={index} xs={24} sm={24} md={24} lg={12} xl={12}>
						<Card className="mission-card" loading bordered={false}>
							<Meta title="Card title" description="This is the description" />
						</Card>
					</Col>
				))}
			</Row>
		);

	if (missions.length === 0)
		return (
			<Row gutter={[16, 16]}>
				<Col xs={24}>
					<EmptyMissions />
				</Col>
			</Row>
		);

	return (
		<Row gutter={[16, 16]}>
			{missions.map((mission, index) => (
				<Col key={index} xs={24} sm={24} md={24} lg={12} xl={12}>
					{mission.influencers[0].status === "invited" ? (
						<Ribbon text="New" color="red">
							<MissionCardInfluencer
								mission={mission}
								onClick={() =>
									navigate(
										`missions/${
											mission.type === "proposal" ? "proposals/" : ""
										}${mission.id}`
									)
								}
							/>
						</Ribbon>
					) : (
						<MissionCardInfluencer
							mission={mission}
							onClick={() =>
								navigate(
									`missions/${mission.type === "proposal" ? "proposals/" : ""}${
										mission.id
									}`
								)
							}
						/>
					)}
				</Col>
			))}
			<Col xs={24} sm={24} md={24} lg={24} xl={24}>
				<Card
					onClick={() => navigate("missions")}
					className="mission-card"
					hoverable
					bordered={false}
				>
					<Title style={{ marginBottom: 0, textAlign: "center" }} level={5}>
						{t("influencer.allMissions")}{" "}
						<RightOutlined style={{ fontSize: 14 }} />
					</Title>
				</Card>
			</Col>
		</Row>
	);
};

export default DashboardMissions;
