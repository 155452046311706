import { useState } from "react";
import {
	Button,
	Space,
	Row,
	Col,
	Card,
	PageHeader,
	Tag,
	Avatar,
	Modal,
	message,
	Typography,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import numeral from "numeral";
import { addDoc, collection, deleteDoc, doc, setDoc } from "firebase/firestore";

import networks, { activeNetworks } from "../../data/networks";
import AudienceSave from "./AudienceSave";
import db from "../../data/Firebase";
import useTranslation from "../../intl/useTranslation";
import AudienceLoad from "./AudienceLoad";

const { Text } = Typography;

const Audience = ({ audience, setAudience, toggleInfluencer }) => {
	const [saveVisible, setSaveVisible] = useState(false);
	const [loadVisible, setLoadVisible] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [deleteModal, setDeleteModal] = useState(false);
	const [metaAudience, setMetaAudience] = useState({});
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const saveAudience = async () => {
		setLoading(true);
		const metricsBreakdown = {};
		networks.forEach((network) => {
			metricsBreakdown[network.id] = audience.reduce(
				(acc, influencer) =>
					typeof influencer[network.id] === "undefined"
						? acc
						: acc + influencer[network.id].followers,
				0
			);
		});

		const newAudience = {
			name: metaAudience["name"],
			client: metaAudience["client"],
			influencers: audience.map((inf) => inf.id),
			metrics: {
				total: audience.reduce(
					(acc, influencer) =>
						acc +
						networks
							.filter((network) =>
								activeNetworks(influencer).includes(network.id)
							)
							.reduce(
								(acc, network) => acc + influencer[network.id].followers,
								0
							),
					0
				),
				...metricsBreakdown,
			},
		};
		// Check if we have an existing audience ID
		if (metaAudience["objectID"]) {
			// Path to the existing document
			const docRef = doc(db, "audiences", metaAudience["objectID"]);
			await setDoc(docRef, newAudience); // This updates or creates a new document with the specified ID
		} else {
			// No existing ID, so we add a new document
			const collectionRef = collection(db, "audiences");
			await addDoc(collectionRef, newAudience);
		}

		setLoading(false);
		setAudience(null);
		message.success(t("influencers.saveAudienceSuccess"));
		setMetaAudience({});
		setSaveVisible(false);
	};

	const deleteAudience = async () => {
		setDeleting(true);
		const documentRef = doc(db, "audiences", metaAudience["objectID"]);
		await deleteDoc(documentRef);
		setMetaAudience({});
		setAudience(null);
		message.success(t("influencers.deleteAudienceSuccess"));
		setDeleteModal(false);
		setDeleting(false);
	};

	if (audience === null)
		return (
			<Space
				style={{ width: "100%", justifyContent: "center", padding: 32 }}
				size={12}
			>
				<Button onClick={() => setAudience([])}>
					{t("influencers.createAudience")}
				</Button>
				<Button onClick={() => setLoadVisible(true)}>
					{t("influencers.loadAudience")}
				</Button>
				<AudienceLoad
					loadVisible={loadVisible}
					setLoadVisible={setLoadVisible}
					setAudience={setAudience}
					setMetaAudience={setMetaAudience}
				/>
			</Space>
		);
	return (
		<Row gutter={[16, 16]}>
			<Col md={18} xs={24}>
				{audience.length === 0 ? (
					<PageHeader
						style={{ paddingTop: 52 }}
						title={t("influencers.noInfluencersAudienceTitle")}
						className="influencer-title-small metrics-title"
						subTitle={t("influencers.noInfluencersAudienceSubTitle")}
					/>
				) : (
					<Space wrap>
						{audience.map((influencer, key) => (
							<Tag
								key={key}
								className="audience-influencer"
								closable
								onClose={() => toggleInfluencer(influencer)}
							>
								<Avatar src={influencer.avatar} icon={<UserOutlined />} />
								<PageHeader
									title={`${influencer.firstname} ${influencer.lastname}`}
									className="influencer-title-small metrics-title"
								/>
							</Tag>
						))}
					</Space>
				)}
			</Col>
			<Col md={6} xs={24}>
				<Space direction="vertical" size={16}>
					{metaAudience.hasOwnProperty("name") && (
						<PageHeader
							title={metaAudience["name"]}
							className="influencer-title-small metrics-title"
						/>
					)}
					<Card bordered={false} className="metrics-card">
						<Space direction="vertical" size={12}>
							<PageHeader
								title={numeral(
									audience.reduce(
										(acc, influencer) =>
											acc +
											networks
												.filter((network) =>
													activeNetworks(influencer).includes(network.id)
												)
												.reduce(
													(acc, network) =>
														acc + influencer[network.id].followers,
													0
												),
										0
									)
								).format("0,0")}
								className="influencer-title-small metrics-title"
								subTitle={"Total Audience"}
							/>
							<Space wrap style={{ justifyContent: "center" }}>
								{networks.map((network, key) => (
									<Tag key={key} icon={network.icon}>
										{numeral(
											audience.reduce(
												(acc, influencer) =>
													typeof influencer[network.id] === "undefined"
														? acc
														: acc + influencer[network.id].followers,
												0
											)
										).format("0,0")}
									</Tag>
								))}
							</Space>
						</Space>
					</Card>
					<Space direction="vertical" style={{ width: "100%" }}>
						<Row gutter={[16, 16]}>
							<Col span={12}>
								<Button
									onClick={() => {
										setAudience(null);
										setMetaAudience({});
									}}
									className="audience-action"
									size="large"
								>
									{t("influencers.cancel")}
								</Button>
							</Col>
							<Col span={12}>
								<Button
									onClick={() => setSaveVisible(true)}
									className="audience-action"
									type="primary"
									size="large"
								>
									{t("missions.save")}
								</Button>
							</Col>
						</Row>
						{metaAudience.hasOwnProperty("objectID") && (
							<>
								<Button
									onClick={() => setDeleteModal(true)}
									className="audience-action"
									type="primary"
									size="large"
								>
									{t("influencers.deleteAudience")}
								</Button>
								<Modal
									open={deleteModal}
									onOk={deleteAudience}
									confirmLoading={deleting}
									onCancel={() => setDeleteModal(false)}
									okText={t("missions.confirm")}
									cancelText={t("missions.cancel")}
									className="influencer-confirm-modal"
								>
									<Text>{t("influencers.deleteAudienceTitle")}</Text>
									<PageHeader
										title={metaAudience["name"]}
										className="influencer-title-small"
									/>
								</Modal>
							</>
						)}
					</Space>
				</Space>
			</Col>
			<Modal
				title={t("influencers.saveAudienceTitle")}
				open={saveVisible}
				onOk={() => saveAudience()}
				okButtonProps={{
					disabled: !(
						metaAudience.hasOwnProperty("name") &&
						metaAudience.hasOwnProperty("client") &&
						metaAudience["name"] !== ""
					),
					loading: loading,
				}}
				okText={t("missions.save")}
				onCancel={() => {
					setSaveVisible(false);
				}}
				cancelText={t("influencers.cancel")}
			>
				<AudienceSave
					metaAudience={metaAudience}
					setMetaAudience={setMetaAudience}
				/>
			</Modal>
		</Row>
	);
};

export default Audience;
