import { Typography, Descriptions } from "antd";
import useTranslation from "../../intl/useTranslation";
import { useSelector } from "react-redux";
import { getUser } from "../login/loginSlice";

const { Text } = Typography;

const Details = ({ influencer }) => {
	const { t } = useTranslation();
	const user = useSelector(getUser);
	const regionNames = new Intl.DisplayNames([user.language], {
		type: "region",
	});

	return (
		<Descriptions bordered size="small">
			<Descriptions.Item label="IBAN">
				<Text copyable>{influencer.iban}</Text>
			</Descriptions.Item>
			<Descriptions.Item label={t("payments.addressLabel")}>
				<Text copyable>{influencer.address}</Text>
				<br />
				<Text copyable>{`${influencer.zip} ${influencer.city}`}</Text>
				<br />
				{regionNames.of(influencer.country.toUpperCase())}
			</Descriptions.Item>
		</Descriptions>
	);
};

export default Details;
