import { useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Input, Space, Select, PageHeader, Button } from "antd";
import { PhoneOutlined, PlusOutlined } from "@ant-design/icons";
import { AsYouType, CountryCode, parsePhoneNumber } from "libphonenumber-js";

import useTranslation from "../../intl/useTranslation";
import db from "../../data/Firebase";
import countries from "../../intl/countries";
import { getUser } from "../login/loginSlice";
import Title from "../layout/Title";
import UploadAvatar from "../../lib/components/UploadAvatar";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";

const { Option } = Select;

const ContactForm = ({ id, client, changeContactValue }) => {
	const { t } = useTranslation();

	const changePhone = (e) => {
		try {
			const newNum = parsePhoneNumber(
				client.contacts[id].phone,
				client.contacts[id].country
			);
			if (
				!(
					client.contacts[id].phone.length < e.target.value.length &&
					newNum.isValid()
				)
			)
				changeContactValue(
					{
						target: {
							value: new AsYouType(client.contacts[id].country).input(
								e.target.value
							),
							name: "phone",
						},
					},
					id
				);
		} catch (error) {
			changeContactValue(
				{
					target: {
						value: new AsYouType(client.contacts[id].country).input(
							e.target.value
						),
						name: "phone",
					},
				},
				id
			);
		}
	};

	const countrySelect = (
		<Select
			defaultValue="CH"
			className="select-before"
			onChange={(value) =>
				changeContactValue(
					{
						target: {
							value: value,
							name: "country",
						},
					},
					id
				)
			}
		>
			<Option value="CH">+41</Option>
			<Option value="FR">+33</Option>
		</Select>
	);

	return (
		<Space direction="vertical" size={20}>
			<PageHeader
				title={`${t("clients.contactTitle")} #${id + 1}`}
				className="influencer-title-small search-card-title"
			/>
			<Input
				name="firstname"
				size="large"
				type="text"
				defaultValue={
					client.contacts[id].hasOwnProperty("firstname")
						? client.contacts[id].firstname
						: ""
				}
				placeholder={t("clients.labelFirstName")}
				onChange={(e) => changeContactValue(e, id)}
			/>
			<Input
				name="lastname"
				size="large"
				type="text"
				defaultValue={
					client.contacts[id].hasOwnProperty("lastname")
						? client.contacts[id].lastname
						: ""
				}
				placeholder={t("clients.labelLastName")}
				onChange={(e) => changeContactValue(e, id)}
			/>
			<Input
				name="role"
				size="large"
				type="text"
				defaultValue={
					client.contacts[id].hasOwnProperty("role")
						? client.contacts[id].role
						: ""
				}
				placeholder={t("clients.labelRole")}
				onChange={(e) => changeContactValue(e, id)}
			/>
			<Input
				name="email"
				size="large"
				type="email"
				defaultValue={
					client.contacts[id].hasOwnProperty("email")
						? client.contacts[id].email
						: ""
				}
				placeholder={t("clients.labelEmail")}
				onChange={(e) => changeContactValue(e, id)}
			/>
			<Input
				prefix={<PhoneOutlined className="site-form-item-icon" />}
				addonBefore={countrySelect}
				type="tel"
				size="large"
				value={
					client.contacts[id].hasOwnProperty("phone")
						? client.contacts[id].phone
						: ""
				}
				onChange={changePhone}
				placeholder={t("clients.labelPhone")}
			/>
		</Space>
	);
};

const EditClient = ({ client, setClient }) => {
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();
	const user = useSelector(getUser);

	const changeValue = (e) =>
		setClient({ ...client, [e.target.name]: e.target.value });

	const changeContactValue = (e, index) => {
		const newContacts = [...client.contacts];
		newContacts[index] = {
			...newContacts[index],
			[e.target.name]: e.target.value,
		};
		setClient({ ...client, contacts: newContacts });
	};

	const saveClient = async () => {
		setLoading(true);
		if (client.hasOwnProperty("id")) {
			await updateDoc(doc(db, "clients", client.id), client);
		} else {
			await addDoc(collection(db, "clients"), {
				...client,
				contacts: client.contacts
					.filter((contact) => Object.values(contact).length > 1)
					.map((contact) => ({
						...contact,
						...(contact.phone && {
							phone: parsePhoneNumber(contact.phone, contact.country).format(
								"E.164"
							),
						}),
					})),
				deals: 0,
				phone: parsePhoneNumber(client.phone, client.phoneLocale).format(
					"E.164"
				),
			});
		}
		setLoading(false);
		setClient(null);
	};

	const changePhone = (e) => {
		try {
			const newNum = parsePhoneNumber(client.phone, client.phoneLocale);
			if (!(client.phone.length < e.target.value.length && newNum.isValid()))
				changeValue({
					target: {
						value: new AsYouType(client.phoneLocale).input(e.target.value),
						name: "phone",
					},
				});
		} catch (error) {
			changeValue({
				target: {
					value: new AsYouType(client.phoneLocale).input(e.target.value),
					name: "phone",
				},
			});
		}
	};

	const countrySelect = (
		<Select
			defaultValue="CH"
			className="select-before"
			onChange={(e: CountryCode) =>
				changeValue({ target: { value: e, name: "phoneLocale" } })
			}
		>
			<Option value="CH">+41</Option>
			<Option value="FR">+33</Option>
		</Select>
	);

	if (client === null) return null;

	return (
		<>
			<Title
				title={t(
					"clients." + (client.hasOwnProperty("id") ? "editTitle" : "newTitle")
				)}
				onBack={() => setClient(null)}
			/>
			<Row className="edit-client-form" gutter={[20, 20]}>
				<Col xs={24} sm={12}>
					<Space direction="vertical" size={20}>
						<UploadAvatar
							path="/clients/avatars"
							img={client.avatar}
							setImg={changeValue}
						/>
						<Input
							name="name"
							size="large"
							type="text"
							defaultValue={client.hasOwnProperty("name") ? client.name : ""}
							placeholder={t("clients.labelName")}
							onChange={changeValue}
						/>
						<Input
							prefix={<PhoneOutlined className="site-form-item-icon" />}
							addonBefore={countrySelect}
							type="tel"
							size="large"
							value={client.hasOwnProperty("phone") ? client.phone : ""}
							onChange={changePhone}
							placeholder={t("apply.phone")}
						/>
						<Input
							name="email"
							size="large"
							type="email"
							prefix="@"
							defaultValue={client.hasOwnProperty("email") ? client.email : ""}
							placeholder={t("clients.labelEmail")}
							onChange={changeValue}
						/>
						<Input
							name="address"
							size="large"
							type="text"
							defaultValue={
								client.hasOwnProperty("address") ? client.address : ""
							}
							placeholder={t("clients.labelAddress")}
							onChange={changeValue}
						/>
						<Space>
							<Input
								name="zip"
								size="large"
								type="number"
								className="input-zip"
								defaultValue={client.hasOwnProperty("zip") ? client.zip : ""}
								placeholder={t("clients.labelZip")}
								onChange={changeValue}
							/>
							<Input
								name="city"
								size="large"
								type="text"
								className="input-city"
								defaultValue={client.hasOwnProperty("city") ? client.city : ""}
								placeholder={t("clients.labelCity")}
								onChange={changeValue}
							/>
						</Space>
						<Select
							placeholder={t("clients.labelCountry")}
							size="large"
							className="input-country"
							defaultValue={client.country}
							onChange={(value) =>
								changeValue({
									target: {
										value: value,
										name: "country",
									},
								})
							}
						>
							{Object.keys(
								countries.getNames(user.language, { select: "official" })
							).map((country) => (
								<Option key={country} value={country}>
									{countries.getName(country, user.language, {
										select: "official",
									})}
								</Option>
							))}
						</Select>
					</Space>
				</Col>
				<Col xs={24} sm={12}>
					<Space direction="vertical">
						{client.hasOwnProperty("contacts")
							? client.contacts.map((contact, key) => (
									<ContactForm
										key={key}
										id={key}
										client={client}
										changeContactValue={changeContactValue}
									/>
							  ))
							: [{ country: "CH" }].map((contact, key) => (
									<ContactForm
										key={key}
										id={key}
										client={client}
										changeContactValue={changeContactValue}
									/>
							  ))}
						<Button
							onClick={() => {
								const newContacts = [...client.contacts, { country: "CH" }];
								changeValue({
									target: { value: newContacts, name: "contacts" },
								});
							}}
							icon={<PlusOutlined />}
						>
							{t("clients.actionNewContact")}
						</Button>
					</Space>
				</Col>
				<Col span={24} style={{ textAlign: "center", paddingTop: 32 }}>
					<Button
						style={{ maxWidth: "300px", width: "100%" }}
						size="large"
						type="primary"
						loading={loading}
						onClick={saveClient}
					>
						Save
					</Button>
				</Col>
			</Row>
		</>
	);
};

export default EditClient;
