import { Input, Space, Typography, Button, Form, Select } from "antd";
import db from "../../data/Firebase";
import { useState } from "react";
import { useSelector } from "react-redux";
import useTranslation from "../../intl/useTranslation";
import { getUser } from "../login/loginSlice";
import { doc, updateDoc } from "firebase/firestore";

const { Title } = Typography;
const { Option } = Select;

const ChangePayments = ({ success, error }) => {
	const { t } = useTranslation();
	const user = useSelector(getUser);
	const [loading, setLoading] = useState(false);

	const changePayment = async ({ iban, address, city, zip, country }) => {
		try {
			setLoading(true);
			await updateDoc(
				doc(
					db,
					user.role === "administrator" || user.role === "manager"
						? "managers"
						: "influencers",
					user.uid
				),
				{ iban: iban, address: address, city: city, zip: zip, country: country }
			);
			setLoading(false);
			success(t("settings.paymentsTitle"));
		} catch (error) {
			setLoading(false);
			error();
		}
	};

	return (
		<Form
			onFinish={changePayment}
			initialValues={{
				iban: user.iban,
				address: user.address,
				zip: user.zip,
				city: user.city,
				country: user.country,
			}}
		>
			<Space className="settings-section" direction="vertical" size={12}>
				<Title level={5}>{t("settings.paymentsTitle")}</Title>
				<Space
					direction="horizontal"
					size={32}
					className="settings-section-space"
					wrap
				>
					<Form.Item name="iban" rules={[{ required: true }]}>
						<Input
							size="large"
							className="settings-input"
							placeholder={t("settings.iban")}
						/>
					</Form.Item>
				</Space>
				<Space
					direction="horizontal"
					size={32}
					className="settings-section-space"
					wrap
				>
					<Form.Item name="address" rules={[{ required: true }]}>
						<Input
							size="large"
							className="settings-input"
							placeholder={t("settings.address")}
						/>
					</Form.Item>
					<Form.Item name="zip" rules={[{ required: true }]}>
						<Input
							size="large"
							className="settings-input"
							placeholder={t("settings.zip")}
						/>
					</Form.Item>
				</Space>
				<Space
					direction="horizontal"
					size={32}
					className="settings-section-space"
					wrap
				>
					<Form.Item name="city" rules={[{ required: true }]}>
						<Input
							size="large"
							className="settings-input"
							placeholder={t("settings.city")}
						/>
					</Form.Item>
					<Form.Item name="country" rules={[{ required: true }]}>
						<Select
							placeholder={t("settings.country")}
							size="large"
							className="onboarding-select"
						>
							<Option value="ch">
								{t("onboarding.labelCountrySwitzerland")}
							</Option>
							<Option value="fr">{t("onboarding.labelCountryFrance")}</Option>
						</Select>
					</Form.Item>
				</Space>
				<div className="settings-section-button">
					<Form.Item>
						<Button type="primary" htmlType="submit" loading={loading}>
							{t("settings.applyLabel")}
						</Button>
					</Form.Item>
				</div>
			</Space>
		</Form>
	);
};

export default ChangePayments;
