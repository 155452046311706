import { createSlice } from "@reduxjs/toolkit";

export const managersSlice = createSlice({
	name: "managers",
	initialState: null,
	reducers: {
		editManagers: (state, action) => {
			return action.payload;
		},
	},
});

export const { editManagers } = managersSlice.actions;

export const getManagers = (state) => state.managers;

export default managersSlice.reducer;
