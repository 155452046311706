import { useState } from "react";
import { httpsCallable } from "firebase/functions";
import { Button, message } from "antd";

import { fbFunctions } from "../../data/Firebase";
import useTranslation from "../../intl/useTranslation";

const RemindInfluencer = ({ influencer }) => {
	const { t } = useTranslation();

	const [reminding, setReminding] = useState(false);

	const remindInfluencer = async () => {
		setReminding(true);
		const remindFunction = httpsCallable(fbFunctions, "remindInfluencer");
		await remindFunction({ influencerId: influencer.id });
		setReminding(false);
		message.success(t("influencers.remindSuccess"));
	};

	return (
		<Button
			type="primary"
			className="btn-accept"
			onClick={remindInfluencer}
			loading={reminding}
		>
			{t("influencers.remind")}
		</Button>
	);
};

export default RemindInfluencer;
