import { Space, Input, Select, Checkbox } from "antd";
import { useCallback, useEffect, useState } from "react";

import { clientsIndex as index } from "../../data/Algolia";
import useTranslation from "../../intl/useTranslation";

const { Option } = Select;

const AudienceSave = ({ metaAudience, setMetaAudience }) => {
	const [clients, setClients] = useState([]);
	const { t } = useTranslation();

	const clientsRequest = useCallback(
		async () => setClients(Object.values((await index.search("")).hits)),
		[setClients]
	);

	useEffect(() => {
		clientsRequest();
	}, [clientsRequest]);

	const changeValue = (e) => {
		setMetaAudience({ ...metaAudience, [e.target.name]: e.target.value });
	};

	const toggleGeneralAudience = (e) => {
		setMetaAudience({
			...metaAudience,
			client: e.target.checked ? "general" : "",
		});
	};

	return (
		<Space direction="vertical" style={{ width: "100%" }} size={12}>
			<Input
				name="name"
				size="large"
				type="text"
				defaultValue={
					metaAudience.hasOwnProperty("name") ? metaAudience.name : ""
				}
				placeholder={t("influencers.audienceName")}
				onChange={changeValue}
				style={{ width: "100%" }}
				value={metaAudience.name}
			/>
			<Checkbox
				checked={metaAudience.client === "general"}
				onChange={toggleGeneralAudience}
			>
				{t("influencers.generalAudience")}
			</Checkbox>
			<Select
				showSearch
				size="large"
				placeholder={"Client"}
				optionFilterProp="children"
				className="client-select"
				disabled={metaAudience.client === "general"}
				onChange={(value) =>
					changeValue({ target: { name: "client", value: value } })
				}
				value={metaAudience.client}
			>
				{clients.map((client) => (
					<Option key={client.objectID} value={client.objectID}>
						{client.name}
					</Option>
				))}
			</Select>
		</Space>
	);
};
export default AudienceSave;
