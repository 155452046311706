import { Space } from "antd";
import ChangeAvatar from "./ChangeAvatar";
import ChangeNetworks from "./ChangeNetworks";

const Profile = ({ success, error }) => {
	return (
		<Space direction="vertical" size={24} className="settings-wrapper">
			<ChangeAvatar success={success} />
			<ChangeNetworks success={success} error={error} />
		</Space>
	);
};

export default Profile;
