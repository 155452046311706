import { useSelector, useDispatch } from "react-redux";
import { Menu as MenuAntd, Badge } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import { getUser } from "../login/loginSlice";
import {
	editNotificationsOpen,
	getNotifications,
} from "../notifications/notificationsSlice";
import ProfileIcon from "../../icons/ProfileIcon";
import MissionIcon from "../../icons/MissionIcon";
import NotificationIcon from "../../icons/NotificationIcon";
import RewardIcon from "../../icons/RewardIcon";
import SettingsIcon from "../../icons/SettingsIcon";
import InfluencersIcon from "../../icons/InfluencersIcon";
import ClientIcon from "../../icons/ClientIcon";

const Menu = ({ type }) => {
	const user = useSelector(getUser);
	const notifications = useSelector(getNotifications);
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const currentPath = () => {
		return "/" + location.pathname.split("/")[1];
	};

	const countNotifications = notifications.filter(
		(notification) => notification.status === "unread"
	).length;

	const mobileItems = [
		{
			key: "/rewards",
			icon: <RewardIcon />,
		},
		{
			key: "/missions",
			icon: <MissionIcon />,
		},
		{
			key: "/notifications",
			icon: (
				<Badge count={countNotifications}>
					<NotificationIcon />
				</Badge>
			),
		},
		{
			key: "/",
			icon: <ProfileIcon />,
		},
	];

	const mobileItemsAdmin = [
		{
			key: "/clients",
			icon: <ClientIcon />,
		},
		{
			key: "/payments",
			icon: <RewardIcon />,
		},
		{
			key: "/missions",
			icon: <MissionIcon />,
		},
		{
			key: "/notifications",
			icon: (
				<Badge count={countNotifications}>
					<NotificationIcon />
				</Badge>
			),
		},
		{
			key: "/influencers",
			icon: <InfluencersIcon />,
		},
	];

	const desktopItems = [
		...mobileItems,
		{
			key: "/settings",
			icon: <SettingsIcon />,
		},
	];

	const desktopItemsAdmin = [
		...mobileItemsAdmin,
		{
			key: "/settings",
			icon: <SettingsIcon />,
		},
	];

	const menuItems =
		user.role === "administrator" || user.role === "manager"
			? type === "mobile"
				? mobileItemsAdmin
				: desktopItemsAdmin
			: type === "mobile"
			? mobileItems
			: desktopItems;

	const itemClick = ({ key }) =>
		key === "/notifications"
			? dispatch(editNotificationsOpen(true))
			: navigate(key);

	return (
		<MenuAntd
			theme="dark"
			className="menu"
			mode="horizontal"
			selectedKeys={[currentPath()]}
			onClick={itemClick}
			items={menuItems}
		/>
	);
};

export default Menu;
