import {
	Button,
	Collapse,
	Descriptions,
	PageHeader,
	Space,
	Tag,
	Typography,
	Modal,
	Pagination,
} from "antd";
import { ExclamationCircleOutlined, DownloadOutlined } from "@ant-design/icons";
import { useState } from "react";
import useTranslation from "../../intl/useTranslation";
import Title from "../layout/Title";
import SearchPayments from "./SearchPayments";
import moment from "moment";
import { useSelector } from "react-redux";
import { getUser } from "../login/loginSlice";
import { httpsCallable } from "firebase/functions";
import { fbFunctions } from "../../data/Firebase";
import returnStatValue from "../../lib/returnStatValue";

const { Panel } = Collapse;
const { Title: AntdTitle, Text } = Typography;
const { confirm } = Modal;

const Payments = () => {
	const { t } = useTranslation();
	const [payments, setPayments] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalResults, setTotalResults] = useState(0);
	const [pageSize, setPageSize] = useState(50);
	const [confirmLoading, setConfirmLoading] = useState(null);
	const [downloadLoading, setDownloadLoading] = useState(null);
	const user = useSelector(getUser);

	const regionNames = new Intl.DisplayNames([user.language], {
		type: "region",
	});

	const showConfirm = (paymentId) => {
		confirm({
			title: t("payments.confirmTitle"),
			icon: <ExclamationCircleOutlined />,
			onOk: async () => {
				setConfirmLoading(paymentId);
				const confirmPayment = httpsCallable(fbFunctions, "confirmPayment");
				await confirmPayment({
					paymentId: paymentId,
				});
				setConfirmLoading(null);
			},
			okText: t("payments.confirmAction"),
			cancelText: t("payments.cancelAction"),
		});
	};

	const downloadInvoice = async (paymentId) => {
		setDownloadLoading(paymentId);
		const downloadInvoice = httpsCallable(fbFunctions, "downloadInvoice");
		const response: any = await downloadInvoice({
			paymentId: paymentId,
		});
		if (response.data.url) {
			window.open(response.data.url, "_blank"); // Open the URL in a new tab
		} else {
			console.log("No URL returned from the function");
		}
		setDownloadLoading(null);
	};

	const changePage = (page, pageSize) => {
		setCurrentPage(page);
		setPageSize(pageSize);
	};

	return (
		<>
			<Title title={t("payments.pageTitle")} />
			<SearchPayments
				setPayments={setPayments}
				currentPage={currentPage}
				setTotalResults={setTotalResults}
				pageSize={pageSize}
			/>

			{payments !== null && (
				<Collapse bordered={false} accordion className="payments-list">
					{payments.map((payment, index) => (
						<Panel
							header={
								<Space wrap size={24} className="payment-title-space">
									<AntdTitle className="payment-title" level={5}>
										{`${payment.recipient.firstname} ${payment.recipient.lastname} - ${payment.title}`}
									</AntdTitle>
									<div className="payment-title-details-wrapper">
										<Text type="secondary">
											{payment.executed
												? moment.unix(payment.executed).format("DD/MM/YY HH:mm")
												: moment.unix(payment.created).format("DD/MM/YY")}
										</Text>
										<Tag
											className={`mission-tag task-tag title-tag ${
												payment.status === "paid" && "payment-tag-paid"
											}`}
										>
											<PageHeader
												title={t(`payments.${payment.status}`)}
												className="task-title-small metrics-title"
											/>
										</Tag>
										<div className="payment-title-spacer" />
										<AntdTitle className="payment-title" level={5}>
											{`${payment.amount} ${payment.currency}`}
										</AntdTitle>
									</div>
								</Space>
							}
							key={index}
						>
							<Descriptions
								bordered
								title={t("payments.detailsTitle")}
								size="small"
								extra={
									<Space>
										<Button
											onClick={() => downloadInvoice(payment.objectID)}
											loading={payment.objectID === downloadLoading}
											icon={<DownloadOutlined />}
										>
											{t("payments.invoice")}
										</Button>
										{payment.status !== "paid" && (
											<Button
												onClick={() => showConfirm(payment.objectID)}
												loading={payment.objectID === confirmLoading}
											>
												{t("payments.confirmAction")}
											</Button>
										)}
									</Space>
								}
							>
								<Descriptions.Item label="IBAN">
									<Text copyable>{payment.recipient.iban}</Text>
								</Descriptions.Item>
								<Descriptions.Item label={t("payments.referenceLabel")}>
									<Text copyable>{payment.reference}</Text>
								</Descriptions.Item>
								<Descriptions.Item label={t("payments.amountLabel")}>
									{`${payment.amount} ${payment.currency}`}
								</Descriptions.Item>
								<Descriptions.Item label={t("payments.nameLabel")}>
									<Text
										copyable
									>{`${payment.recipient.firstname} ${payment.recipient.lastname}`}</Text>
								</Descriptions.Item>
								<Descriptions.Item label={t("payments.emailLabel")}>
									<Text copyable>{payment.recipient.email}</Text>
								</Descriptions.Item>
								<Descriptions.Item label={t("payments.addressLabel")}>
									<Text copyable>{payment.recipient.address}</Text>
									<br />
									<Text
										copyable
									>{`${payment.recipient.zip} ${payment.recipient.city}`}</Text>
									<br />
									{regionNames.of(payment.recipient.country.toUpperCase())}
								</Descriptions.Item>
							</Descriptions>
						</Panel>
					))}
				</Collapse>
			)}
			<Space align="start">
				<Pagination
					total={totalResults}
					onChange={changePage}
					current={currentPage}
					pageSize={pageSize}
					showSizeChanger
					className="payments-pagination"
				/>
				<Tag className="total-influencers">
					<Space>
						<Text strong>{returnStatValue(totalResults)}</Text>
						<Text>Payments</Text>
					</Space>
				</Tag>
			</Space>
		</>
	);
};

export default Payments;
