import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Routes, Route } from "react-router-dom";
import {
	Drawer,
	Menu,
	Card,
	Row,
	Col,
	Button,
	message,
	Modal,
	Typography,
} from "antd";
import { FireOutlined, TeamOutlined } from "@ant-design/icons";

import Title from "../layout/Title";
import { auth, fbFunctions } from "../../data/Firebase";
import useTranslation from "../../intl/useTranslation";

import NotificationIcon from "../../icons/NotificationIcon";
import ProfileIcon from "../../icons/ProfileIcon";
import SettingsIcon from "../../icons/SettingsIcon";

import colors from "../../themes/colors.json";
import Account from "./Account";
import Profile from "./Profile";
import Managers from "./Managers";
import { useSelector } from "react-redux";
import { getUser } from "../login/loginSlice";
import Notifications from "./Notifications";
import RewardIcon from "../../icons/RewardIcon";
import Rewards from "./Rewards";
import Interests from "./Interests";
import { useServiceWorker } from "../../lib/useServiceWorker";
import { httpsCallable } from "firebase/functions";

const { Text } = Typography;

function getWindowSize() {
	const { innerWidth, innerHeight } = window;
	return { innerWidth, innerHeight };
}

const Settings = ({ type }) => {
	const [visible, setVisible] = useState(false);
	const [opened, setOpened] = useState(false);
	const [deleteVisible, setDeleteVisible] = useState(false);
	const [deleting, setDeleting] = useState(false);
	const [windowSize, setWindowSize] = useState(getWindowSize());
	const user = useSelector(getUser);

	const location = useLocation();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { reloadPage } = useServiceWorker();

	const success = (title) => {
		message.success(`${title} ${t("settings.success")}`);
	};

	const error = (customMessage = null) => {
		message.error(customMessage ? t(customMessage) : t("settings.error"));
	};

	const deleteAccount = async () => {
		setDeleting(true);
		const deleteFunction = httpsCallable(fbFunctions, "deleteAccount");
		await deleteFunction({ accountId: user.id });
		setDeleteVisible(false);
		setDeleting(false);
		message.success(t("settings.deleteSuccess"));
		disconnect();
	};

	const menuItems = [
		{
			key: "account",
			label: t(`settings.account`),
			children: [],
			icon: <SettingsIcon style={{ color: colors[0] }} />,
		},
		user.role === "influencer" && {
			key: "profile",
			label: t(`settings.profile`),
			children: [],
			icon: <ProfileIcon style={{ color: colors[1] }} />,
		},
		user.role === "influencer" && {
			key: "interests",
			label: t(`settings.interests`),
			children: [],
			icon: <FireOutlined style={{ color: colors[4] }} />,
		},
		user.role === "influencer" && {
			key: "notifications",
			label: t(`settings.notifications`),
			children: [],
			icon: <NotificationIcon style={{ color: colors[2] }} />,
		},
		user.role === "influencer" && {
			key: "rewards",
			label: t(`settings.rewards`),
			children: [],
			icon: <RewardIcon style={{ color: colors[3] }} />,
		},
		user.role === "administrator" && {
			key: "managers",
			label: t(`settings.managers`),
			children: [],
			icon: <TeamOutlined style={{ color: colors[1] }} />,
		},
	];

	const locationState: { prevPath?: string } | null = location.state;
	const [prevPath] = useState(
		locationState !== null && locationState.hasOwnProperty("prevPath")
			? locationState.prevPath
			: null
	);

	const disconnect = () => {
		auth.signOut();
	};

	useEffect(() => {
		function handleWindowResize() {
			setWindowSize(getWindowSize());
		}
		window.addEventListener("resize", handleWindowResize);
		return () => {
			window.removeEventListener("resize", handleWindowResize);
		};
	}, []);

	useEffect(() => {
		setVisible(true);
		setOpened(true);
	}, []);

	useEffect(() => {
		if (visible === false && opened === true) {
			setOpened(false);
			navigate(prevPath === null ? "/" : prevPath);
		}
	}, [visible, navigate, opened, prevPath]);

	const matchSubmenu = (subRoute = undefined) => {
		if (location.pathname === "/settings") return false;
		const locationSplitted = location.pathname.split("/");
		if (locationSplitted[2] === "") return false;
		if (subRoute === `${locationSplitted[2]}`) return true;
		return type === "mobile" ? true : false;
	};

	const SettingsHeader = (
		<Title
			title={t("settings.title")}
			onBack={type === "mobile" ? () => setVisible(false) : null}
		/>
	);

	const itemClick = (key) => key.length > 0 && navigate(key[0]);

	const SettingsMenu = (
		<div className="settings-menu-wrapper">
			<Card className="settings-menu" bordered={false}>
				<Menu
					mode="vertical"
					onOpenChange={itemClick}
					selectedKeys={[
						location.pathname.split("/")[2]
							? location.pathname.split("/")[2]
							: type === "desktop" && "account",
					]}
					triggerSubMenuAction="click"
					items={menuItems}
				/>
			</Card>
			<Button
				className="button-disconnect"
				type="primary"
				shape="round"
				size="large"
				onClick={disconnect}
			>
				{t("settings.disconnect")}
			</Button>
			<Button
				className="button-disconnect"
				type="primary"
				shape="round"
				size="large"
				onClick={reloadPage}
			>
				{t("settings.update")}
			</Button>
			<Button
				className="button-disconnect btn-reject"
				type="primary"
				shape="round"
				size="large"
				onClick={() => setDeleteVisible(true)}
			>
				{t("settings.deleteAccount")}
			</Button>
			<Modal
				open={deleteVisible}
				onOk={deleteAccount}
				confirmLoading={deleting}
				onCancel={() => setDeleteVisible(false)}
				okText={t("settings.deleteAccount")}
				cancelText={t("influencers.cancel")}
				className="influencer-confirm-modal"
			>
				<Text>{t("settings.deleteWarning")}</Text>
			</Modal>
			<div className="settings-version">
				Version {`${process.env.REACT_APP_VERSION}`}
			</div>
		</div>
	);

	const SettingsContent = (
		<>
			{type === "mobile" && (
				<Title
					title={
						typeof location.pathname.split("/")[2] !== "undefined" &&
						t(`settings.${location.pathname.split("/")[2]}`)
					}
					onBack={() => navigate("/settings")}
				/>
			)}
			<Routes>
				<Route
					path={`/account/*`}
					element={<Account success={success} error={error} />}
				/>
				{user.role === "influencer" && (
					<>
						<Route
							path={`/profile/*`}
							element={<Profile success={success} error={error} />}
						/>
						<Route
							path={`/notifications/*`}
							element={<Notifications success={success} error={error} />}
						/>
						<Route
							path={`/rewards/*`}
							element={<Rewards success={success} error={error} />}
						/>
						<Route
							path={`/interests/*`}
							element={<Interests success={success} error={error} />}
						/>
					</>
				)}
				{user.role === "administrator" && (
					<Route
						path={`/managers/*`}
						element={<Managers success={success} error={error} />}
					/>
				)}
				<Route
					path={`*`}
					element={<Account success={success} error={error} />}
				/>
			</Routes>
		</>
	);

	if (type === "mobile")
		return (
			<div className="mobile">
				<Drawer
					width="100%"
					placement="right"
					className="mobile mobile-drawer"
					closable={false}
					open={windowSize.innerWidth > 949 ? false : visible}
				>
					{SettingsHeader}
					{SettingsMenu}
					<Drawer
						width="100%"
						placement="right"
						className="mobile mobile-drawer"
						closable={false}
						open={matchSubmenu()}
					>
						{SettingsContent}
					</Drawer>
				</Drawer>
			</div>
		);

	return (
		<>
			{SettingsHeader}
			<Row wrap={false}>
				<Col>{SettingsMenu}</Col>
				<Col flex="auto">{SettingsContent}</Col>
			</Row>
		</>
	);
};

export default Settings;
