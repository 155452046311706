import { Button, DatePicker, Input, Select, Space, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import useTranslation from "../../intl/useTranslation";
import UploadImages from "../../lib/components/UploadImages";
import tasksTypes from "../../data/tasksTypes";
import RichTextEditor from "../../lib/components/RichTextEditor";
import generateId from "../../lib/generateId";
import emptyTask from "../../data/emptyTask";

const { Title: AntdTitle } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;

const NewMissionTasks = ({ mission, setMission, docId }) => {
	const { t } = useTranslation();

	const removeTask = (id) => {
		const tasks = mission.tasks;
		const updatedTasks = tasks.filter((task) => task.id !== id);
		setMission({
			...mission,
			tasks: updatedTasks,
		});
	};

	const onChangeTask = (e, index) => {
		const tasks = [...mission.tasks];
		tasks[index][e.target.name] = e.target.value;
		setMission({ ...mission, tasks });
	};

	return (
		<Space size={24} direction="vertical" className="mission-form">
			{mission.tasks.map((task, index) => (
				<Space
					key={index}
					className="mission-form"
					direction="vertical"
					size={16}
				>
					<AntdTitle level={4}>
						{`${t("missions.taskTitle")} #${index + 1}`}{" "}
						{mission.tasks.length > 1 && (
							<Button
								type="link"
								shape="circle"
								icon={<DeleteOutlined />}
								onClick={() => removeTask(task?.id)}
							/>
						)}
					</AntdTitle>
					<Select
						showSearch
						onChange={(value) =>
							onChangeTask({ target: { name: "type", value: value } }, index)
						}
						value={task.type}
						size="large"
						className="onboarding-select"
						placeholder={t("missions.taskTypeLabel")}
					>
						{tasksTypes.map((type) => (
							<Option key={type} value={type}>
								{t(`missions.${type}`)}
							</Option>
						))}
					</Select>
					<span>
						<AntdTitle level={5}>{t("missions.descriptionLabel")}</AntdTitle>
						<RichTextEditor
							value={task.description}
							onChange={onChangeTask}
							name={"description"}
							index={index}
						/>
					</span>
					<Input
						name="hashtags"
						size="large"
						type="text"
						value={task.hashtags}
						placeholder={t("missions.hashtagsLabel")}
						onChange={(e) => onChangeTask(e, index)}
					/>
					<Input
						name="tags"
						size="large"
						type="text"
						value={task.tags}
						placeholder={t("missions.tagsLabel")}
						onChange={(e) => onChangeTask(e, index)}
					/>
					<Input
						name="link"
						size="large"
						type="text"
						value={task.link}
						placeholder={"https://www.example.com"}
						onChange={(e) => onChangeTask(e, index)}
					/>
					<RangePicker
						showTime
						className="onboarding-datepick"
						name="dueDate"
						size="large"
						format="DD/MM/YYYY HH:mm"
						value={[task.startDate, task.dueDate]}
						placeholder={[
							t("missions.startDateLabel"),
							t("missions.dueDateLabel"),
						]}
						onChange={(e) => {
							onChangeTask(
								{ target: { name: "startDate", value: e[0] } },
								index
							);
							onChangeTask({ target: { name: "dueDate", value: e[1] } }, index);
						}}
					/>
					<UploadImages
						path={`/missions/${docId}`}
						images={task.examples}
						setImages={onChangeTask}
						name="examples"
						index={index}
					/>
				</Space>
			))}
			<Button
				size="large"
				type="ghost"
				onClick={() =>
					setMission({
						...mission,
						tasks: [...mission.tasks, { ...emptyTask, id: generateId(10) }],
					})
				}
			>
				{t("missions.addTask")}
			</Button>
		</Space>
	);
};

export default NewMissionTasks;
