import {
	Avatar,
	PageHeader,
	Space,
	Tag,
	Typography,
	Collapse,
	Image,
	Button,
	message,
} from "antd";
import { useNavigate } from "react-router-dom";
import {
	TeamOutlined,
	SyncOutlined,
	DollarOutlined,
	SwapRightOutlined,
	AppstoreAddOutlined,
	LinkOutlined,
	NumberOutlined,
} from "@ant-design/icons";

import useTranslation from "../../intl/useTranslation";
import moment from "moment";
import DeleteMission from "./DeleteMission";
import TaskTag from "./TaskTag";
import { useState } from "react";
import { collection, doc, setDoc } from "firebase/firestore";
import db from "../../data/Firebase";
import CompleteMission from "./CompleteMission";

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const taskTags = [
	{ id: "hashtags", icon: <NumberOutlined />, ellipsis: false },
	{ id: "tags", icon: "@", ellipsis: false },
	{ id: "link", icon: <LinkOutlined />, ellipsis: true },
];

const MissionAdminDetails = ({ mission, client, setOpenEditMission }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [cloneLoading, setCloneLoading] = useState(false);

	const cloneMission = async () => {
		setCloneLoading(true);

		const missionDoc = doc(
			collection(db, "missions"),
			doc(collection(db, "missions")).id
		);

		await setDoc(missionDoc, {
			...mission,
			creationType: mission.creationType || mission.type,
			influencers: [],
			id: missionDoc.id,
			type: "clone",
			status: "draft",
			oldId: mission.id,
			enrolled: null,
			lastmodified: moment().valueOf(),
		});
		message.success(t("missions.newCloneMissionSuccess"));
		setCloneLoading(false);
		navigate(`../drafts`);
	};

	return (
		<Space className="mission-wrapper" direction="vertical">
			<Space wrap>
				{client && (
					<Tag
						className="mission-tag"
						onClick={() => navigate(`/clients/${client.id}`)}
					>
						<Avatar src={client.avatar} />
						<PageHeader
							title={client.name}
							className="influencer-title-small metrics-title"
						/>
					</Tag>
				)}
				<Tag className="mission-tag">
					<Avatar icon={<SyncOutlined />} />
					<PageHeader
						title={t(`missions.${mission.status}`)}
						className="influencer-title-small metrics-title"
					/>
				</Tag>
				{mission?.participants && (
					<Tag className="mission-tag">
						<Avatar icon={<TeamOutlined />} />
						<PageHeader
							title={`${mission?.enrolled ? mission.enrolled : 0}/${
								mission.participants
							}`}
							className="influencer-title-small metrics-title"
						/>
					</Tag>
				)}
				{typeof mission?.reward === "number" && (
					<Tag className="mission-tag">
						<Avatar icon={<DollarOutlined />} />
						<PageHeader
							title={`${mission.reward} ${mission.currency}`}
							className="influencer-title-small metrics-title"
						/>
					</Tag>
				)}
			</Space>
			{mission.description && (
				<Space
					className="reward-prompt"
					direction="vertical"
					align="center"
					size={8}
				>
					<Title level={5}>{t("missions.descriptionLabel")}</Title>
					<div dangerouslySetInnerHTML={{ __html: mission.description }} />
				</Space>
			)}
			{mission?.tasks && mission?.tasks.length > 1 && (
				<Collapse bordered={false} accordion className="mission-tasks">
					{mission.tasks.map((task, index) => (
						<Panel
							header={
								<>
									<Title className="task-title" level={5}>
										{`${t("missions.taskTitle")} #${index + 1}`}{" "}
									</Title>
									<Tag className="mission-tag task-tag title-tag">
										<PageHeader
											title={
												<>
													{moment.unix(task.startDate).format("DD/MM/YY HH:mm")}
													<SwapRightOutlined />
													{moment.unix(task.dueDate).format("DD/MM/YY HH:mm")}
												</>
											}
											className="task-title-small metrics-title"
										/>
									</Tag>
								</>
							}
							key={index}
						>
							<Space direction="vertical" size={22}>
								<Space wrap>
									<Tag className="mission-tag task-tag">
										<Avatar icon={<AppstoreAddOutlined />} />
										<PageHeader
											title={t(`missions.${task.type}`)}
											className="influencer-title-small metrics-title"
										/>
									</Tag>
									{taskTags.map((tag, key) =>
										task[tag.id].split(" ").length > 1 ? (
											task[tag.id]
												.split(" ")
												.map((value) => (
													<TaskTag key={value} tag={tag} tagValue={value} />
												))
										) : (
											<TaskTag key={key} tag={tag} tagValue={task[tag.id]} />
										)
									)}
								</Space>
								<Paragraph>
									<div dangerouslySetInnerHTML={{ __html: task.description }} />
								</Paragraph>
								<Space wrap>
									{task.examples.map((example) =>
										example.match("mp4") ? (
											<video width="150" height="100" controls>
												<source src={example} />
											</video>
										) : (
											<Image width={150} key={example} src={example} />
										)
									)}
								</Space>
							</Space>
						</Panel>
					))}
				</Collapse>
			)}
			{mission?.questions &&
				mission?.questions.length > 0 &&
				mission.questions.map((question, index) => (
					<Space
						key={index}
						className="reward-prompt"
						direction="vertical"
						align="center"
						size={8}
					>
						<Title level={5}>{`${t("missions.questionTitle")} #${
							index + 1
						}`}</Title>
						{question.question}
					</Space>
				))}
			{mission.terms && (
				<Space
					className="reward-prompt"
					direction="vertical"
					align="center"
					size={8}
				>
					<Title level={5}>{t("missions.termsLabel")}</Title>
					<div dangerouslySetInnerHTML={{ __html: mission.terms }} />
				</Space>
			)}
			<Space direction="vertical" style={{ width: "100%" }} size={12}>
				<Space className="influencer-actions">
					<Button
						type="primary"
						className="btn-accept"
						onClick={() => setOpenEditMission(true)}
					>
						{t("missions.edit")}
					</Button>
					<Button
						type="primary"
						loading={cloneLoading}
						className="btn-accept"
						onClick={cloneMission}
					>
						{t("missions.clone")}
					</Button>
				</Space>
				<CompleteMission mission={mission} />
				<DeleteMission mission={mission} />
			</Space>
		</Space>
	);
};

export default MissionAdminDetails;
