import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import { getUser } from "../login/loginSlice";
import MissionsAdmin from "./MissionsAdmin";
import MissionsInfluencer from "./MissionsInfluencer";
import NewMission from "./NewMission";

const adminRoutes = [
	{ path: "/new", component: <NewMission /> },
	{ path: "*", component: <MissionsAdmin /> },
];
const userRoutes = [{ path: "*", component: <MissionsInfluencer /> }];

const Missions = () => {
	const user = useSelector(getUser);

	const routes =
		user.role === "administrator" || user.role === "manager"
			? adminRoutes
			: userRoutes;

	return (
		<Routes>
			{routes.map((route, key) => (
				<Route key={key} path={route.path} element={route.component} />
			))}
		</Routes>
	);
};

export default Missions;
