import { Button, Modal, Space } from "antd";
import {
	WhatsAppOutlined,
	MailOutlined,
	SendOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import useTranslation from "../../intl/useTranslation";
import { useSelector } from "react-redux";
import { getUser } from "../login/loginSlice";

const HelpButtons = () => {
	const location = useLocation();
	const { t } = useTranslation();
	const user = useSelector(getUser);

	const items = [
		{
			key: "whatsapp",
			icon: <WhatsAppOutlined />,
			href: "https://wa.me/41793002373",
		},
		/*{
			key: "instagram",
			icon: <InstagramOutlined />,
			href: "https://ig.me/m/socialife.ch",
		},
		{
			key: "facebook",
			icon: <FacebookOutlined />,
			href: "",
		},*/
		{
			key: "email",
			icon: <MailOutlined />,
			href: "mailto:hello@socialife.ch",
		},
	];

	const help = () => {
		Modal.info({
			title: t("layout.helpTitle"),
			content: (
				<Space
					style={{
						paddingTop: 20,
						width: "100%",
						justifyContent: "center",
						paddingRight: 38,
					}}
					size={24}
				>
					{items.map((item) => (
						<a
							key={item.key}
							href={item.href}
							target="_blank"
							rel="noreferrer noopener"
						>
							<Button
								size="large"
								type="primary"
								shape="circle"
								icon={item.icon}
							/>
						</a>
					))}
				</Space>
			),
		});
	};

	return (
		<div
			className={
				"help-affix " +
				(location.pathname.split("/").length > 2 && "help-affix-mobile-full")
			}
			style={{
				display:
					user.role === "administrator" || user.role === "manager"
						? "none"
						: null,
			}}
		>
			<Button
				size="large"
				type="primary"
				shape="circle"
				onClick={help}
				icon={<SendOutlined />}
			/>
		</div>
	);
};

export default HelpButtons;
