import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signInWithEmailAndPassword } from "firebase/auth";
import { Link, useLocation } from "react-router-dom";
import {
	Form,
	Input,
	Button,
	Row,
	Col,
	message,
	Select,
	PageHeader,
	Typography,
	Space,
	Modal,
} from "antd";
// import { useThemeSwitcher } from "react-css-theme-switcher";
import { logEvent } from "firebase/analytics";
import { validate } from "email-validator";

import { auth, analytics, fbFunctions } from "../../data/Firebase";
import LangStrings from "../../intl/LangStrings";
import useTranslation from "../../intl/useTranslation";
import { editLocale, getLocale } from "../loading/loadingSlice";
import { httpsCallable } from "firebase/functions";

const { Option } = Select;
const { Password } = Input;
const { Text } = Typography;

const Login = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const locale = useSelector(getLocale);
	const [loading, setLoading] = useState(false);
	const location = useLocation();
	const [resetPassword, setResetPassword] = useState(false);
	const [resetLoading, setResetLoading] = useState(false);
	const [resetEmail, setResetEmail] = useState("");
	// const { switcher, themes, currentTheme, status } = useThemeSwitcher();

	useEffect(() => {
		logEvent(analytics, "screen_view", {
			firebase_screen: "/login",
			firebase_screen_class: "default",
		});
	}, [location]);

	const login = (values) => {
		setLoading(true);
		signInWithEmailAndPassword(auth, values.email, values.password)
			.then((user) => {
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				message.error(t("login.error"));
			});
	};

	const requestReset = async () => {
		setResetLoading(true);
		try {
			const resetPasswordFunction = httpsCallable(fbFunctions, "resetPassword");
			await resetPasswordFunction({ email: resetEmail });
		} catch (error) {}
		setResetLoading(false);
		successReset();
		setResetPassword(false);
		setResetEmail("");
	};

	const successReset = () => {
		message.success(t("login.successReset"));
	};

	const cancelReset = () => {
		setResetPassword(false);
		setResetEmail("");
	};

	const setNewLanguage = (lang) => {
		if (lang !== localStorage.getItem("locale")) {
			localStorage.setItem("locale", lang);
		}
		dispatch(editLocale(lang));
	};

	// const toggleTheme = () =>
	// 	switcher({ theme: currentTheme === "dark" ? themes.light : themes.dark });

	return (
		<Row className="login-row" align="middle" justify="space-around">
			<Col>
				<img
					src="/SocialifeLogoDark.svg"
					alt="Socialife Logo"
					className="login-logo"
				/>
				<PageHeader
					className="login-title"
					title={t("login.title")}
					extra={
						<Select value={locale} onChange={setNewLanguage}>
							{Object.keys(LangStrings).map((lang, key) => (
								<Option key={key} value={lang}>
									{t("login." + lang)}
								</Option>
							))}
						</Select>
					}
				/>
				<Form
					name="normal_login"
					className="form-login"
					initialValues={{ remember: true }}
					size="large"
					onFinish={login}
				>
					<Form.Item
						name="email"
						rules={[{ required: true, message: t("login.errorEmail") }]}
					>
						<Input type="email" placeholder={t("login.email")} />
					</Form.Item>
					<Form.Item
						name="password"
						rules={[{ required: true, message: t("login.errorPassword") }]}
					>
						<Password type="password" placeholder={t("login.password")} />
					</Form.Item>
					<Form.Item>
						<Button
							type="link"
							className="login-form-forgot"
							onClick={() => setResetPassword(true)}
						>
							{t("login.forgot")}
						</Button>
					</Form.Item>
					<Form.Item>
						<Button
							type="primary"
							htmlType="submit"
							className="login-form-button"
							loading={loading}
						>
							{t("login.login")}
						</Button>
					</Form.Item>
					<Space direction="vertical" size={0} className="login-register">
						<Text>{t("login.noAccount")}</Text>
						<a href="https://socialife.ch/influenceur">
							<Text strong>{t("login.register")}</Text>
						</a>
					</Space>
				</Form>
			</Col>
			<span className="login-terms">
				<Link to="/privacy">{t("login.terms")}</Link>
			</span>
			<Modal
				title={t("login.resetTitle")}
				open={resetPassword}
				onOk={requestReset}
				okButtonProps={{
					loading: resetLoading,
					disabled: !validate(resetEmail),
				}}
				okText={t("login.resetOk")}
				onCancel={cancelReset}
				cancelText={t("login.resetCancel")}
			>
				<Input
					size="large"
					type="email"
					value={resetEmail}
					onChange={(e) => setResetEmail(e.target.value)}
					className="settings-input"
					placeholder={"john.doe@gmail.com"}
				/>
			</Modal>
		</Row>
	);
};

export default Login;
