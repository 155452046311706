import { Avatar, Rate, Space, Tag, Typography } from "antd";
import { UserOutlined } from "@ant-design/icons";

import useTranslation from "../../intl/useTranslation";
import StatsChart from "./StatsChart";
import returnStatValue from "../../lib/returnStatValue";

const { Title, Text } = Typography;
//const chartGradient = ["#5489cf", "#6897d4", "#7ca5da", "#90b2e0", "#a4c0e5"];

const MissionAdminMetrics = ({ mission }) => {
	const { t } = useTranslation();

	return (
		<Space direction="vertical" className="metrics-wrapper" size={16}>
			<Space
				className="reward-prompt metrics-wrapper"
				direction="vertical"
				align="center"
				size={8}
			>
				<Space direction="horizontal" className="metrics-title">
					<Title level={5}>{t("missions.tasksMetrics")}</Title>
					{mission.stats && (
						<Tag className="total-visists">
							<Space>
								<Text>Total</Text>
								<Text strong>
									{returnStatValue(
										Object.values(mission.stats).reduce(
											(a: number, b: number) => a + b
										)
									)}
								</Text>
							</Space>
						</Tag>
					)}
				</Space>
				<StatsChart
					data={
						mission.stats
							? Object.keys(mission.stats)
									.map((country) => ({
										value: mission.stats[country],
										label: country,
									}))
									.sort((a, b) => a.value - b.value)
							: undefined
					}
				/>
			</Space>
			<Space
				className="reward-prompt metrics-wrapper"
				direction="vertical"
				align="center"
				size={8}
			>
				<Title level={5}>{t("missions.ratingTitle")}</Title>
				{mission.influencers.map(
					(participant, key) =>
						(participant.rateGiven || participant.rateReceived) && (
							<Space
								key={key}
								className="metric-rating"
								size={18}
								direction="vertical"
							>
								<div>
									<Avatar
										size="small"
										className="participants-avatar"
										src={participant.avatar}
										icon={<UserOutlined />}
									/>
									<Title className="task-title" level={5}>
										{`${participant.firstname} ${participant.lastname}`}
									</Title>
								</div>
								<Space
									direction="horizontal"
									className="mission-ratings"
									size={0}
									wrap
								>
									{participant.rateReceived && (
										<Space direction="vertical" size={0}>
											Socialife{" "}
											<Rate disabled value={participant.rateReceived} />
										</Space>
									)}
									{participant.rateGiven && (
										<Space direction="vertical" size={0}>
											Influencer <Rate disabled value={participant.rateGiven} />
										</Space>
									)}
								</Space>
							</Space>
						)
				)}
			</Space>
		</Space>
	);
};

export default MissionAdminMetrics;
