import { Button, Drawer, Input, Select, Space } from "antd";
import LangStrings from "../../intl/LangStrings";
import useTranslation from "../../intl/useTranslation";
import Title from "../layout/Title";
import { httpsCallable } from "firebase/functions";
import db, { fbFunctions } from "../../data/Firebase";
import { AsYouType, parsePhoneNumber } from "libphonenumber-js";
import { PhoneOutlined } from "@ant-design/icons";
import { useState } from "react";
import { collection, doc, updateDoc } from "firebase/firestore";

const { Option } = Select;

const EditManager = ({ manager, setEditManager, success, error }) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);

	const changeValue = (e) =>
		setEditManager({ ...manager, [e.target.name]: e.target.value });

	const saveManager = async () => {
		if (manager.hasOwnProperty("id")) {
			setLoading(true);
			try {
				const managerDoc = doc(collection(db, "managers"), manager.id);
				updateDoc(managerDoc, {
					...manager,
					phoneNumber: parsePhoneNumber(manager.phoneNumber, "CH").format(
						"E.164"
					),
				});
				success("Manager");
				setEditManager(null);
			} catch (e) {
				error("");
			}
			setLoading(false);
		} else {
			setLoading(true);
			try {
				const newManager = httpsCallable(fbFunctions, "newManager");
				await newManager({
					...manager,
					phoneNumber: parsePhoneNumber(manager.phoneNumber, "CH").format(
						"E.164"
					),
				});
				success("Manager");
				setEditManager(null);
			} catch (e) {
				error();
			}
			setLoading(false);
		}
	};

	const onPhoneChange = (e) =>
		setEditManager({
			...manager,
			phoneNumber: new AsYouType("CH").input(e.target.value),
		});

	const countrySelect = (
		<Select defaultValue="CH" className="select-before">
			<Option value="CH">+41</Option>
		</Select>
	);

	return (
		<Drawer
			placement="right"
			closable={false}
			width=""
			open={manager !== null}
			className="mobile-drawer"
		>
			{manager !== null && (
				<>
					<Title
						title={t(
							"settings." +
								(manager.hasOwnProperty("id") ? "editManager" : "addManager")
						)}
						onBack={() => setEditManager(null)}
					/>
					<Space
						size={18}
						direction="vertical"
						className="settings-section-space"
					>
						<Input
							name="firstname"
							size="large"
							type="text"
							defaultValue={manager.firstname}
							placeholder={t("settings.firstname")}
							onChange={changeValue}
						/>
						<Input
							name="lastname"
							size="large"
							type="text"
							defaultValue={manager.lastname}
							placeholder={t("settings.lastname")}
							onChange={changeValue}
						/>
						<Input
							name="email"
							size="large"
							type="text"
							defaultValue={manager.email}
							placeholder={t("settings.emailTitle")}
							onChange={changeValue}
						/>
						<Input
							prefix={<PhoneOutlined className="site-form-item-icon" />}
							addonBefore={countrySelect}
							type="tel"
							size="large"
							className="settings-input"
							value={manager ? manager.phoneNumber : ""}
							onChange={onPhoneChange}
							placeholder={t("clients.labelPhone")}
						/>
						<Select
							size="large"
							placeholder={t("settings.role")}
							defaultValue={manager.role}
							onChange={(value) => setEditManager({ ...manager, role: value })}
						>
							<Select.Option value="manager">Manager</Select.Option>
							<Select.Option value="administrator">Administrator</Select.Option>
						</Select>
						<Select
							size="large"
							onChange={(value) =>
								setEditManager({ ...manager, language: value })
							}
							defaultValue={manager.language}
						>
							{Object.keys(LangStrings).map((lang, key) => (
								<Select.Option key={key} value={lang}>
									{t("login." + lang)}
								</Select.Option>
							))}
						</Select>
						{!manager.hasOwnProperty("id") && (
							<Input
								name="password"
								size="large"
								type="password"
								defaultValue={manager.password}
								placeholder={t("settings.passwordTitle")}
								onChange={changeValue}
							/>
						)}
						<Button
							size="large"
							type="primary"
							loading={loading}
							onClick={saveManager}
						>
							{t("settings.save")}
						</Button>
					</Space>
				</>
			)}
		</Drawer>
	);
};

export default EditManager;
