import { useState } from "react";
import { Upload, message } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import {
	ref,
	uploadBytes,
	getDownloadURL,
	deleteObject,
} from "firebase/storage";
import { UploadRequestOption } from "rc-upload/lib/interface";

import { storageRef } from "../../data/Firebase";
import moment from "moment";
import generateId from "../generateId";
import useTranslation from "../../intl/useTranslation";

function UploadAvatar({ path, img, setImg, name = "avatar" }) {
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const beforeUpload = (file) => {
		const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
		if (!isJpgOrPng) {
			message.error(t("settings.errorAvatarType"));
			console.log("wrong file type");
		}
		const isLt2M = file.size / 1024 / 1024 < 2;
		if (!isLt2M) {
			message.error(t("settings.errorAvatarSize"));
		}
		return isJpgOrPng && isLt2M;
	};

	const upload = async ({
		onError,
		onSuccess,
		onProgress,
		file,
	}: UploadRequestOption<any>) => {
		try {
			const fileObject: File = file as File;
			onProgress({ percent: 0 });
			const imgRef = ref(
				storageRef,
				`${path}/${moment().unix()}-${generateId(5)}-${fileObject.name}`
			);
			const imgUrl = await getDownloadURL(
				(
					await uploadBytes(imgRef, file as Blob)
				).ref
			);
			setImg({ target: { value: imgUrl, name: name } });
			onSuccess(null);
		} catch (error) {
			onError(error);
		}
	};

	const deleteOldImg = async () =>
		typeof img === "string" && img !== "" && deleteObject(ref(storageRef, img));

	const handleChange = async (info) => {
		if (info.file.status === "uploading") {
			await deleteOldImg();
			setImg({ target: { value: "", name: "avatar" } });
			setLoading(true);
			return;
		}
		if (info.file.status === "done") {
			setLoading(false);
		}
	};

	const uploadButton = (
		<div>
			{loading ? <LoadingOutlined /> : <PlusOutlined />}
			<div style={{ marginTop: 8 }}>Upload</div>
		</div>
	);

	return (
		<Upload
			name="avatar"
			listType="picture-card"
			className="avatar-uploader"
			showUploadList={false}
			customRequest={upload}
			beforeUpload={beforeUpload}
			onChange={handleChange}
		>
			{img !== null && img !== "" && typeof img !== "undefined" ? (
				<img src={img} alt="avatar" style={{ width: "100%" }} />
			) : (
				uploadButton
			)}
		</Upload>
	);
}

export default UploadAvatar;
