import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Avatar, Empty, Tabs } from "antd";
import { StarFilled } from "@ant-design/icons";
import {
	onSnapshot,
	doc,
	collection,
	query,
	updateDoc,
} from "firebase/firestore";

import db from "../../data/Firebase";
import Title from "../layout/Title";
import { editMission, getMission } from "./missionsSlice";
import removeTrailingParam from "../../lib/removeTrailingParam";
import { getClients, setClient } from "../clients/clientsSlice";
import useTranslation from "../../intl/useTranslation";
import MissionAdminDetails from "./MissionAdminDetails";
import MissionAdminParticipants from "./MissionAdminParticipants";
import MissionAdminMetrics from "./MissionAdminMetrics";
import formatRating from "../../lib/formatRating";
import NewMission from "./NewMission";

const sortParticipantsOrder = {
	validating: 1,
	accepted: 2,
	completed: 3,
	answered: 4,
	invited: 5,
	declined: 6,
};

const MissionAdmin = ({ missionId }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const { t } = useTranslation();

	const [notFound, setNotFound] = useState(false);
	const [openEditMission, setOpenEditMission] = useState(false);

	const mission = useSelector((state) => getMission(state, missionId));
	const clients = useSelector(getClients);

	const client = !mission?.client
		? undefined
		: mission &&
		  clients !== null &&
		  clients.findIndex((client) => client.id === mission.client) !== -1
		? clients.find((client) => client.id === mission.client)
		: null;

	useEffect(() => {
		if (!mission && typeof missionId !== "undefined" && !notFound) {
			onSnapshot(doc(db, "missions", missionId), (mission) => {
				if (mission.exists && mission.data()) {
					onSnapshot(
						query(collection(db, `missions/${missionId}/influencers`)),
						(querySnapshot) => {
							const influencers = [];
							querySnapshot.forEach((document) => {
								influencers.push({ ...document.data(), id: document.id });
							});
							influencers.sort((a, b) => {
								return (
									sortParticipantsOrder[a.status] -
									sortParticipantsOrder[b.status]
								);
							});
							dispatch(
								editMission({
									...mission.data(),
									id: mission.id,
									influencers: [...influencers],
								})
							);
						}
					);
				} else {
					setNotFound(true);
				}
			});
		}
	}, [mission, dispatch, missionId, clients, notFound]);

	useEffect(() => {
		setNotFound(false);
	}, [missionId]);

	useEffect(() => {
		if (missionId && mission && mission.newActivity) {
			updateDoc(doc(db, "missions", missionId), { newActivity: false });
		}
	}, [mission, missionId]);

	useEffect(() => {
		if (!mission?.client) return;
		if (
			(mission && client === null) ||
			(mission &&
				clients.findIndex((client) => client.id === mission.client) === -1)
		) {
			onSnapshot(doc(db, "clients", mission.client), (document) => {
				if (document.exists) {
					dispatch(setClient({ ...document.data(), id: document.id }));
				}
			});
		}
	}, [client, clients, dispatch, mission]);

	const tabItems = [
		{
			label: t("missions.detailsTitle"),
			key: "details",
			children: (
				<MissionAdminDetails
					mission={mission}
					client={client}
					setOpenEditMission={setOpenEditMission}
				/>
			),
		},
		{
			label: t("missions.participantsTitle"),
			key: "participants",
			children: <MissionAdminParticipants mission={mission} />,
		},
		{
			label: t("missions.metricsTitle"),
			key: "metrics",
			children: <MissionAdminMetrics mission={mission} />,
		},
	];

	if ((!mission || client === null) && !notFound)
		return (
			<>
				<Title
					title={<>Mission</>}
					onBack={() => navigate(removeTrailingParam(location.pathname))}
				/>
				Loading
			</>
		);

	if (notFound)
		return (
			<>
				<Title
					title={<>Mission</>}
					onBack={() => navigate(removeTrailingParam(location.pathname))}
				/>
				<Empty description={t("missions.notFound")} />
			</>
		);

	if (openEditMission)
		return (
			<>
				<Title
					title={t("missions.edit")}
					onBack={() => setOpenEditMission(false)}
				/>
				<NewMission
					editMission={mission}
					setOpenEditMission={setOpenEditMission}
				/>
			</>
		);

	return (
		<>
			<Title
				title={
					<>
						<Avatar size="large" src={mission.image} />
						{mission.title}
					</>
				}
				extra={
					mission &&
					mission.rate && (
						<span className="influencer-active-rating">
							<StarFilled /> {formatRating(mission.rate)}
						</span>
					)
				}
				onBack={() => navigate(removeTrailingParam(location.pathname))}
				small
			/>
			<Tabs
				defaultActiveKey="details"
				items={
					mission?.type === "draft"
						? tabItems.filter((item) => item.key === "details")
						: mission?.type === "proposal"
						? tabItems.filter((item) => item.key !== "metrics")
						: tabItems
				}
			/>
		</>
	);
};

export default MissionAdmin;
