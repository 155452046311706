import { createSlice } from "@reduxjs/toolkit";

export const rewardsSlice = createSlice({
	name: "rewards",
	initialState: null,
	reducers: {
		setRewards: (state, action) => {
			return action.payload;
		},
	},
});

export const { setRewards } = rewardsSlice.actions;

export const getRewards = (state) => state.rewards;

export default rewardsSlice.reducer;
