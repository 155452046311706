import { ResponsiveBar } from "@nivo/bar";
import { useSelector } from "react-redux";
import { Empty } from "antd";

import returnStatValue from "../../lib/returnStatValue";
import countries from "../../intl/countries";
import { getUser } from "../login/loginSlice";
import useTranslation from "../../intl/useTranslation";

const StatsChart = ({ data }) => {
	const user = useSelector(getUser);
	const { t } = useTranslation();

	if (!data)
		return (
			<Empty
				image={Empty.PRESENTED_IMAGE_SIMPLE}
				description={t("missions.noMetrics")}
			/>
		);

	return (
		<div style={{ height: data.length * 50 }}>
			<ResponsiveBar
				data={data}
				keys={["value"]}
				indexBy="label"
				margin={{ top: 0, right: 0, bottom: 0, left: 22 }}
				padding={0.3}
				layout="horizontal"
				enableGridY={false}
				axisBottom={null}
				axisLeft={{ tickSize: 0 }}
				borderRadius={3}
				theme={{
					axis: {
						ticks: {
							text: {
								fill: "rgba(235, 227, 217, 0.85)",
							},
						},
					},
				}}
				label={({ value }) => returnStatValue(value)}
				tooltip={({ indexValue, value }) => (
					<div
						style={{
							padding: 8,
							color: "rgba(235, 227, 217, 0.85)",
							background: "#252734",
							borderRadius: 3,
						}}
					>
						{countries.getName(indexValue, user.language, {
							select: "official",
						})}
						: <strong>{value}</strong> {t("missions.tasksMetrics")}
					</div>
				)}
				defs={[
					{
						id: "gradient",
						type: "linearGradient",
						colors: [
							{ offset: 0, color: "#00cdac" },
							{ offset: 100, color: "#02aab0" },
						],
					},
				]}
				fill={[{ match: "*", id: "gradient" }]}
			/>
		</div>
	);
};

export default StatsChart;
