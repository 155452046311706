import React from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";

import Menu from "./Menu";

const { Header } = Layout;

const DesktopHeader = ({ logoOnly = false }) => {
	return (
		<Header className="desktop-header">
			<Link to="/">
				<img
					src="/SocialifeLogoDark.svg"
					alt="Socialife Logo"
					className="desktop-header-logo"
				/>
			</Link>
			<div className="header-spacer" />
			{!logoOnly && <Menu type="desktop" />}
		</Header>
	);
};

export default DesktopHeader;
