import YoutubeIcon from "../icons/YoutubeIcon";
import TiktokIcon from "../icons/TiktokIcon";
import LinkedinIcon from "../icons/LinkedinIcon";
import FacebookIcon from "../icons/FacebookIcon";
import InstagramIcon from "../icons/InstagramIcon";

const networks = [
	{
		id: "instagram",
		label: "Instagram",
		auditor: true,
		stats: ["followers", "following", "postCount"],
		rates: ["followRate", "engagementRate", "growthRate", "frequencyRate"],
		icon: <InstagramIcon />,
	},
	{
		id: "tiktok",
		label: "Tiktok",
		auditor: true,
		stats: ["followers", "following", "heart", "postCount"],
		rates: [
			"growthRate",
			"engagementRate",
			"reachRate",
			"frequencyRate",
			"followRate",
		],
		icon: <TiktokIcon />,
	},
	{
		id: "youtube",
		label: "Youtube",
		auditor: true,
		stats: ["followers", "viewCount", "postCount"],
		rates: ["engagementRate", "growthRate", "frequencyRate", "reachRate"],
		icon: <YoutubeIcon />,
	},
	{
		id: "facebook",
		label: "Facebook",
		auditor: false,
		stats: ["followers"],
		icon: <FacebookIcon />,
	},
	{ id: "linkedin", label: "Linkedin", auditor: false, icon: <LinkedinIcon /> },
];

const activeNetworks = (influencer) =>
	Object.keys(influencer).filter((network) =>
		networks.find((net) => net.id === network)
	);

export default networks;
export { activeNetworks };
