import { Space } from "antd";
import ChangePayments from "./ChangePayments";

const Rewards = ({ success, error }) => {
	return (
		<Space direction="vertical" size={24} className="settings-wrapper">
			<ChangePayments success={success} error={error} />
		</Space>
	);
};

export default Rewards;
