import { createSlice } from "@reduxjs/toolkit";

export const loadingSlice = createSlice({
  name: "loading",
  initialState: {status: "loading", locale: "fr"},
  reducers: {
    editLoadingStatus: (state, action) => {
      return {...state, status: action.payload}
		},
		editLocale: (state, action) => {
      return {...state, locale: action.payload}
    }
  },
});

export const {
	editLoadingStatus,
	editLocale
} = loadingSlice.actions;

export const getLoadingStatus = (state) => state.app.status;
export const getLocale = (state) => state.app.locale;

export default loadingSlice.reducer;
