import { Button, Modal, Space, message } from "antd";
import { useState } from "react";
import useTranslation from "../../intl/useTranslation";
import { httpsCallable } from "firebase/functions";
import { fbFunctions } from "../../data/Firebase";

const TaskReminder = ({ missionId, influencerId, taskNumber }) => {
	const [modal, setModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const { t } = useTranslation();

	const sendReminder = async () => {
		setLoading(true);
		const deleteFunction = httpsCallable(fbFunctions, "remindInfluencerTask");
		await deleteFunction({ missionId, influencerId, taskNumber });
		message.success(t("missions.remindSuccess"));
		setLoading(false);
		setModal(false);
	};

	return (
		<Space className="reminder-button">
			<Button onClick={() => setModal(true)} type="primary">
				{t("missions.remindButton")}
			</Button>
			<Modal
				title={t("missions.remindTitle") + taskNumber}
				open={modal}
				okText={t("missions.remindButton")}
				cancelText={t("missions.cancel")}
				onCancel={() => {
					setModal(false);
				}}
				onOk={sendReminder}
				confirmLoading={loading}
			>
				{t("missions.remindText")}
			</Modal>
		</Space>
	);
};

export default TaskReminder;
