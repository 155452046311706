const getCurrentLvl = (xp: number) => 0.1 * Math.sqrt(xp) + 1;

const getLvlXp = (lvl: number) => Math.pow((lvl - 1) / 0.1, 2);

const getLvlProgress = (xp: number) =>
	((xp - getLvlXp(Math.floor(getCurrentLvl(xp)))) /
		(getLvlXp(Math.floor(getCurrentLvl(xp)) + 1) -
			getLvlXp(Math.floor(getCurrentLvl(xp))))) *
	100;

export { getCurrentLvl, getLvlXp, getLvlProgress };
