import { Card, PageHeader, Tag, Space } from "antd";
import { MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { parsePhoneNumber } from "libphonenumber-js";

const { Meta } = Card;

const displayAttribute = [
	{ key: "email", icon: <MailOutlined /> },
	{ key: "phone", icon: <PhoneOutlined /> },
];

const ClientCard = ({ contact }) => {
	return (
		<Card bordered={false} className="contact-card">
			<Meta
				title={
					<PageHeader
						title={`${contact.firstname ? contact.firstname : ""} ${
							contact.lastname ? contact.lastname : ""
						}`}
						className="influencer-title-small search-card-title"
						subTitle={contact.role}
					/>
				}
				description={
					<Space wrap>
						{displayAttribute.map(
							(attribute, key) =>
								contact.hasOwnProperty(attribute.key) && (
									<Tag key={key} icon={attribute.icon}>
										{attribute.key === "phone"
											? parsePhoneNumber(
													contact[attribute.key]
											  ).formatInternational()
											: contact[attribute.key]}
									</Tag>
								)
						)}
					</Space>
				}
			/>
		</Card>
	);
};

export default ClientCard;
