import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	Layout as AntdLayout,
	Result,
	Button,
	Row,
	Col,
	Form,
	Input,
	PageHeader,
	Space,
} from "antd";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";

import { auth } from "../../data/Firebase";
import useTranslation from "../../intl/useTranslation";
import Loading from "../loading/Loading";
import MobileHeader from "../navigation/MobileHeader";
import DesktopHeader from "../navigation/DesktopHeader";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import PasswordValidator from "password-validator";

const { Content } = AntdLayout;
const { Item } = Form;
const { Password } = Input;

const passwordTemplate = new PasswordValidator()
	.is()
	.min(8)
	.has()
	.uppercase()
	.has()
	.lowercase()
	.has()
	.digits()
	.has()
	.not()
	.spaces();

const Reset = () => {
	const { t } = useTranslation();
	const params = useParams();
	const [userEmail, setUserEmail] = useState(null);
	const [password, setPassword] = useState("");
	const [resetLoading, setResetLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (userEmail === null)
			verifyPasswordResetCode(auth, params.code)
				.then(function (email) {
					setUserEmail(email);
				})
				.catch(function () {
					setUserEmail(false);
				});
	}, [userEmail, params.code]);

	const resetPassword = async () => {
		setResetLoading(true);
		await confirmPasswordReset(auth, params.code, password);
		setResetLoading(false);
		setUserEmail(true);
	};

	if (userEmail === null) return <Loading />;

	return (
		<AntdLayout>
			<MobileHeader logoOnly />
			<DesktopHeader logoOnly />
			<Content className="content onboarding-content">
				<Row className="onboarding-row" align="middle" justify="space-around">
					<Col>
						{!userEmail ? (
							<Result
								title={t("reset.invalidCodeTitle")}
								subTitle={t("reset.invalideCodeMessage")}
								icon={<CloseCircleFilled />}
								extra={
									<Button
										type="primary"
										key="console"
										onClick={() => navigate("/")}
									>
										{t("reset.backToLogin")}
									</Button>
								}
							/>
						) : userEmail === true ? (
							<Result
								title={t("reset.confirmationTitle")}
								subTitle={t("reset.confirmationMessage")}
								icon={<CheckCircleFilled />}
								extra={
									<Button
										type="primary"
										key="console"
										onClick={() => navigate("/")}
									>
										{t("reset.backToLogin")}
									</Button>
								}
							/>
						) : (
							<Space direction="vertical" size={18}>
								<PageHeader className="login-title" title={t("reset.title")} />
								<Form name="normal_login" className="form-login" size="large">
									<Item
										validateStatus={
											password &&
											!passwordTemplate.validate(password) &&
											"error"
										}
										help={
											password &&
											!passwordTemplate.validate(password) &&
											t(
												"onboarding." +
													passwordTemplate.validate(password, {
														list: true,
													})[0]
											)
										}
									>
										<Password
											placeholder={t("onboarding.labelPassword")}
											size="large"
											name="password"
											value={password}
											onChange={(e) => setPassword(e.target.value)}
										/>
									</Item>
								</Form>
								<Button
									type="primary"
									className="login-form-button"
									loading={resetLoading}
									size="large"
									disabled={!passwordTemplate.validate(password)}
									onClick={resetPassword}
								>
									{t("reset.reset")}
								</Button>
							</Space>
						)}
					</Col>
				</Row>
			</Content>
		</AntdLayout>
	);
};

export default Reset;
