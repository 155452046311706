import { useEffect, useState, useCallback } from "react";
import { Divider, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { paymentsIndex as index } from "../../data/Algolia";
import useTranslation from "../../intl/useTranslation";

const SearchPayments = ({
	setPayments,
	currentPage,
	setTotalResults,
	pageSize,
}) => {
	const [search, setSearch] = useState({ query: "" });
	const [searchInput, setSearchInput] = useState("");
	const { t } = useTranslation();

	const onChangeSearchQuery = (e) => setSearchInput(e.target.value);

	const queryAloglia = useCallback(async () => {
		const searchResult = await index.search(search.query, {
			page: currentPage - 1,
			hitsPerPage: pageSize,
		});
		setTotalResults(searchResult.nbHits);
		setPayments(Object.values(searchResult.hits));
	}, [search.query, setPayments, currentPage, pageSize, setTotalResults]);

	useEffect(() => {
		queryAloglia();
	}, [search, queryAloglia]);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			if (search.query !== searchInput)
				setSearch({ ...search, query: searchInput });
		}, 400);

		return () => clearTimeout(delayDebounceFn);
	}, [searchInput, search]);

	return (
		<Space direction="vertical" className="search-wrapper" size={16}>
			<Input
				size="large"
				placeholder={t("payments.searchPlaceholder")}
				className="search-input"
				suffix={<SearchOutlined />}
				onChange={onChangeSearchQuery}
			/>
			<Divider />
		</Space>
	);
};

export default SearchPayments;
