import { configureStore } from "@reduxjs/toolkit";

import loadingReducer from "../features/loading/loadingSlice";
import notificationsReducer from "../features/notifications/notificationsSlice";
import loginReducer from "../features/login/loginSlice";
import layoutReducer from "../features/layout/layoutSlice";
import influencersReducer from "../features/influencers/influencersSlice";
import clientsReducer from "../features/clients/clientsSlice";
import missionsReducer from "../features/missions/missionsSlice";
import rewardsReducer from "../features/rewards/rewardsSlice";
import managersReducer from "../features/settings/managersSlice";

export default configureStore({
	reducer: {
		app: loadingReducer,
		notifications: notificationsReducer,
		user: loginReducer,
		theme: layoutReducer,
		influencers: influencersReducer,
		clients: clientsReducer,
		missions: missionsReducer,
		rewards: rewardsReducer,
		managers: managersReducer,
	},
});
