import LangStrings from "./LangStrings";

const languageDetector = () => {
	// preference from the previous session
	const localSetting = localStorage.getItem("locale");
	if (localSetting && isLocale(localSetting)) {
		return localSetting;
	}

	// the language setting of the browser
	const [browserSetting] = navigator.language.split("-");
	if (isLocale(browserSetting)) {
		return browserSetting;
	}

	return "en";
};

const isLocale = (locale: string) => Object.keys(LangStrings).includes(locale);

export default languageDetector;
export { isLocale };
