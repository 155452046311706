import { Space, Typography, Button, Tag, Collapse } from "antd";
import db from "../../data/Firebase";
import { useState } from "react";
import { useSelector } from "react-redux";
import useTranslation from "../../intl/useTranslation";
import { getUser } from "../login/loginSlice";
import { doc, updateDoc } from "firebase/firestore";
import interests from "../../data/interests.json";

const { Title } = Typography;
const { Panel } = Collapse;

const Interests = ({ success, error }) => {
	const { t } = useTranslation();
	const user = useSelector(getUser);
	const [loading, setLoading] = useState(false);
	const [newInterests, setInterests] = useState(user.interests);

	const validate = () =>
		!(Array.isArray(newInterests) && newInterests.length === 5);

	const addTag = (tag) =>
		((Array.isArray(newInterests) && newInterests.length < 5) ||
			!Array.isArray(newInterests)) &&
		setInterests([...(Array.isArray(newInterests) ? newInterests : []), tag]);

	const removeTag = (tag) =>
		setInterests([...newInterests.filter((interest) => interest !== tag)]);

	const changeInterests = async () => {
		try {
			setLoading(true);
			await updateDoc(doc(db, "influencers", user.uid), {
				interests: newInterests,
			});
			setLoading(false);
			success(t("settings.interests"));
		} catch (error) {
			setLoading(false);
			error();
		}
	};

	return (
		<Space direction="vertical" size={24} className="settings-wrapper">
			<Space className="tag-selected" wrap align="center" size={8}>
				{typeof newInterests === "undefined" ||
				(Array.isArray(newInterests) && newInterests.length === 0)
					? t("onboarding.selectInterests")
					: newInterests.map((interest) => (
							<Tag
								closable
								className="selectable-tag"
								onClose={() => removeTag(interest)}
								onClick={() => removeTag(interest)}
								key={interest}
							>
								{t("onboarding." + interest)}
							</Tag>
					  ))}
				<Tag>
					{Array.isArray(newInterests) ? newInterests.length : "0"}
					/5
				</Tag>
			</Space>
			<Collapse accordion>
				{Object.keys(interests).map((key) => (
					<Panel
						key={key}
						header={<Title level={4}>{t("onboarding." + key)}</Title>}
					>
						<Space wrap>
							{interests[key].map(
								(interest) =>
									((Array.isArray(newInterests) &&
										!newInterests.includes(interest)) ||
										!Array.isArray(newInterests)) && (
										<Tag
											className="selectable-tag"
											key={interest}
											onClick={() => addTag(interest)}
										>
											{t("onboarding." + interest)}
										</Tag>
									)
							)}
						</Space>
					</Panel>
				))}
			</Collapse>
			<div className="settings-section-button">
				<Button
					type="primary"
					disabled={validate()}
					loading={loading}
					onClick={changeInterests}
				>
					{t("settings.applyLabel")}
				</Button>
			</div>
		</Space>
	);
};

export default Interests;
