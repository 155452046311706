import numeral from "numeral";

const returnStatValue = (value) => {
	if (Array.isArray(value))
		return typeof value[0] === "object" ? value[0].name : value[0];
	if (typeof value === "number")
		return value > 10
			? numeral(value).format("0.[00]a")
			: numeral(value).format("0.[0]");
	return value;
};

export default returnStatValue;
