import { useEffect } from "react";
import { Button, Space } from "antd";
import {
	MailOutlined,
	PhoneOutlined,
	EditOutlined,
	DollarCircleOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { onSnapshot, doc } from "firebase/firestore";
import { parsePhoneNumber } from "libphonenumber-js";

import db from "../../data/Firebase";
import useTranslation from "../../intl/useTranslation";
import { getClients, setClient } from "./clientsSlice";
import Title from "../layout/Title";
import ClientData from "./ClientData";
import ContactCard from "./ContactCard";

const ClientInfo = ({ setEditClient }) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { t } = useTranslation();

	const { id } = useParams();
	const clients = useSelector(getClients);
	const client =
		clients !== null && clients.findIndex((client) => client.id === id) !== -1
			? clients.find((client) => client.id === id)
			: null;

	useEffect(() => {
		if (client === null && typeof id !== "undefined") {
			onSnapshot(doc(db, "clients", id), (doc) => {
				if (doc.exists) {
					dispatch(setClient({ ...doc.data(), id: doc.id }));
				}
			});
		}
	}, [client, dispatch, id]);

	if (client === null)
		return (
			<>
				<Title title={<>Client</>} onBack={() => navigate("..")} />
				Loading
			</>
		);

	return (
		<>
			<Title
				title={client.name}
				avatar={client.avatar}
				onBack={() => navigate("..")}
				extraSpacer
				extra={
					<Button
						onClick={() => setEditClient({ ...client })}
						icon={<EditOutlined />}
					>
						{t("clients.actionEdit")}
					</Button>
				}
			/>
			<Space direction="vertical" size="large">
				<Space wrap size="large">
					<ClientData
						icon={<DollarCircleOutlined />}
						data={`${client.deals} Deals`}
					/>
					<ClientData
						icon={<PhoneOutlined />}
						data={parsePhoneNumber(client.phone).formatInternational()}
					/>
					<ClientData icon={<MailOutlined />} data={client.email} />
				</Space>
				<Space wrap size="large">
					{client.contacts.map((contact, key) => (
						<ContactCard contact={contact} key={key} />
					))}
				</Space>
			</Space>
		</>
	);
};

export default ClientInfo;
