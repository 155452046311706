import { Space, Typography, Checkbox, Slider } from "antd";

import FilterCard from "./FilterCard";
import useTranslation from "../../intl/useTranslation";
import returnStatValue from "../../lib/returnStatValue";

const { Text } = Typography;

const FiltersSmallNetwork = ({
	network,
	search,
	deleteFilter,
	onChangeFilter,
	followers,
	setFollowers,
	followersMin,
	followersMax,
}) => {
	const { t } = useTranslation();

	return (
		<FilterCard
			title={
				<>
					<Checkbox>
						{" "}
						{network.charAt(0).toUpperCase() + network.slice(1)}
					</Checkbox>
				</>
			}
		>
			<Space direction="vertical" size={16}>
				<Space direction="vertical" size={0}>
					<Text strong>
						{network === "facebook"
							? t("influencers.facebookFollowers")
							: network === "linkedin"
							? t("influencers.linkedinFollowers")
							: t("influencers.followers")}{" "}
						{followers !== null &&
							`${returnStatValue(followers[0])} - ${returnStatValue(
								followers[1]
							)}`}
					</Text>
					<Slider
						range
						min={followersMin}
						max={followersMax}
						tooltip={{ formatter: (value) => returnStatValue(value) }}
						step={100}
						defaultValue={
							followers === null ? [followersMin, followersMax] : followers
						}
						onAfterChange={(val) => {
							setFollowers(val);
							onChangeFilter(network + ".followers", val, "<>");
						}}
						marks={{
							[followersMin]: returnStatValue(followersMin),
							[followersMax]: returnStatValue(followersMax),
						}}
					/>
				</Space>
			</Space>
		</FilterCard>
	);
};

export default FiltersSmallNetwork;
