import {useSelector} from "react-redux"

import {getLocale} from "../features/loading/loadingSlice"
import LangStrings from "./LangStrings"

export default function useTranslation() {
	const fallbackLocale = "en"
	const locale = useSelector(getLocale)

	function t(key: string) {
		if (!LangStrings[locale][key]) {
			console.warn(`No string '${key}' for locale '${locale}'`)
		}

		return LangStrings[locale][key] || LangStrings[fallbackLocale][key] || ""
	}

	return { t, locale }
}
