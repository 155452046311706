import { createSlice } from "@reduxjs/toolkit";

export const notificationsSlice = createSlice({
	name: "notifications",
	initialState: { notificationsOpen: false, notifications: [] },
	reducers: {
		editNotifications: (state, action) => {
			return {
				...state,
				notifications: action.payload,
			};
		},
		editNotificationsOpen: (state, action) => {
			return { ...state, notificationsOpen: action.payload };
		},
	},
});

export const { editNotificationsOpen, editNotifications } =
	notificationsSlice.actions;

export const getNotificationsOpen = (state) =>
	state.notifications.notificationsOpen;

export const getNotifications = (state) => state.notifications.notifications;

export default notificationsSlice.reducer;
