import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Drawer, Space, Typography, Badge, Result, Button } from "antd";
import moment from "moment";
import "moment/locale/fr";

import Title from "../layout/Title";
import {
	getNotificationsOpen,
	editNotificationsOpen,
	getNotifications,
} from "./notificationsSlice";
import { getUser } from "../login/loginSlice";
import useTranslation from "../../intl/useTranslation";
import NotificationIcon from "../../icons/NotificationIcon";
import db from "../../data/Firebase";
import { doc, updateDoc } from "firebase/firestore";
import { useState } from "react";

const AntdTitle = Typography.Title;

const Notifications = () => {
	const { t } = useTranslation();
	const notificationsOpen = useSelector(getNotificationsOpen);
	const notifications = useSelector(getNotifications);
	const dispatch = useDispatch();
	const user = useSelector(getUser);
	const [readLoading, setReadLoading] = useState(false);

	const readNotification = (notificationId) => {
		const notificationDoc = doc(
			db,
			`${user.role !== "influencer" ? "managers" : "influencers"}/${
				user.uid
			}/notifications`,
			notificationId
		);
		updateDoc(notificationDoc, { status: "read" });
	};

	const readAllNotifications = async () => {
		setReadLoading(true);
		await Promise.all(
			notifications
				.filter((notification) => notification.status === "unread")
				.map((notification) => readNotification(notification.id))
		);
		setReadLoading(false);
	};

	const NotificationsHeader = (
		<Title
			title={t("notifications.title")}
			onBack={() => dispatch(editNotificationsOpen(false))}
			extraSpacer
			extra={
				<Button
					size="small"
					onClick={readAllNotifications}
					loading={readLoading}
				>
					{t("notifications.readAll")}
				</Button>
			}
		/>
	);

	const Notification = ({ title, time, desc, notificationId }) => (
		<Space
			className="notification-container"
			onClick={() => {
				dispatch(editNotificationsOpen(false));
				readNotification(notificationId);
			}}
			direction="vertical"
			size={2}
		>
			<AntdTitle className="notification-title" level={5}>
				{title}
			</AntdTitle>
			<Typography>{desc}</Typography>
			<Typography className="notification-time">
				{moment.unix(time).locale(user.language).fromNow()}
			</Typography>
		</Space>
	);

	const NotificationsContent =
		notifications.length === 0 ? (
			<Result icon={<NotificationIcon />} title={t("notifications.notFound")} />
		) : (
			<Space direction="vertical" className="notifications-wrapper" size={18}>
				{notifications.map((notification, key) => (
					<Link key={key} to={`/${notification.path}`}>
						{notification.status === "unread" ? (
							<Badge className="notification-badge" dot>
								<Notification
									title={notification.title}
									desc={notification.subTitle}
									time={notification.time}
									notificationId={notification.id}
								/>
							</Badge>
						) : (
							<Notification
								title={notification.title}
								desc={notification.subTitle}
								time={notification.time}
								notificationId={notification.id}
							/>
						)}
					</Link>
				))}
			</Space>
		);

	return (
		<Drawer
			placement="right"
			width=""
			className="notifications-drawer  mobile-drawer"
			closable={false}
			open={notificationsOpen}
		>
			{NotificationsHeader}
			{NotificationsContent}
		</Drawer>
	);
};

export default Notifications;
