import { Input, Space, Typography, Button, Form } from "antd";
import db from "../../data/Firebase";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import useTranslation from "../../intl/useTranslation";
import { getUser } from "../login/loginSlice";
import { deleteField, doc, updateDoc } from "firebase/firestore";
import networks, { activeNetworks } from "../../data/networks";

const { Title } = Typography;

const ChangeNetworks = ({ success, error }) => {
	const { t } = useTranslation();
	const user = useSelector(getUser);
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	const currentNetworks = networks.filter((network) =>
		activeNetworks(user).includes(network.id)
	);

	const getInitialValues = () => {
		const values = {
			instagramUsername: "",
			instagramFollowers: null,
			tiktokUsername: "",
			tiktokFollowers: null,
			youtubeUsername: "",
			youtubeFollowers: null,
			linkedinUsername: "",
			linkedinFollowers: null,
			facebookUsername: "",
			facebookFollowers: null,
		};
		currentNetworks.forEach((network) => {
			values[`${network.id}Username`] =
				network.id === "linkedin" || network.id === "facebook"
					? user[network.id].url
					: user[network.id].username;

			values[`${network.id}Followers`] = user[network.id].followers;
		});
		return values;
	};

	const changeNetworks = async (values) => {
		const newNetworks = {};
		networks.forEach((network) => {
			const username =
				network.id === "linkedin" || network.id === "facebook"
					? { url: values[`${network.id}Username`] }
					: { username: values[`${network.id}Username`].toLowerCase() };
			if (values[`${network.id}Followers`]) {
				newNetworks[network.id] = {
					...user[network.id],
					...username,
					followers: parseInt(values[`${network.id}Followers`]),
				};
			} else {
				newNetworks[network.id] = deleteField();
			}
		});

		try {
			setLoading(true);
			await updateDoc(doc(db, "influencers", user.uid), {
				...newNetworks,
			});
			form.resetFields();
			setLoading(false);
			success(t("settings.networksTitle"));
		} catch (error) {
			setLoading(false);
			error();
		}
	};

	return (
		<Form
			form={form}
			onFinish={changeNetworks}
			initialValues={getInitialValues()}
		>
			<Space className="settings-section" direction="vertical" size={12}>
				{networks.map((network) => (
					<React.Fragment key={network.id}>
						<Title level={5}>{network.label}</Title>
						<Space
							direction="horizontal"
							size={32}
							className="settings-section-space"
							wrap
						>
							<Form.Item name={`${network.id}Username`}>
								<Input
									size="large"
									className="settings-input"
									placeholder={
										network.id === "facebook"
											? "https://www.facebook.com/john.doe/"
											: network.id === "linkedin"
											? "https://linkedin.com/in/john-doe/"
											: t("settings.username")
									}
								/>
							</Form.Item>
							<Form.Item name={`${network.id}Followers`}>
								<Input
									size="large"
									className="settings-input"
									type="number"
									placeholder={
										network.id === "linkedin"
											? t("onboarding.labelConnections")
											: network.id === "facebook"
											? t("onboarding.labelFriends")
											: t("onboarding.labelFollowers")
									}
								/>
							</Form.Item>
						</Space>
					</React.Fragment>
				))}
				<div className="settings-section-button">
					<Form.Item>
						<Button type="primary" htmlType="submit" loading={loading}>
							{t("settings.applyLabel")}
						</Button>
					</Form.Item>
				</div>
			</Space>
		</Form>
	);
};

export default ChangeNetworks;
