import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, useLocation } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { logEvent, setUserId, setUserProperties } from "firebase/analytics";

import { analytics } from "./data/Firebase";

import Login from "./features/login/Login";
import Loading from "./features/loading/Loading";
import Layout from "./features/layout/Layout";
import Privacy from "./features/policies/Privacy";
import Onboarding from "./features/onboard/Onboarding";

import { getLoadingStatus } from "./features/loading/loadingSlice";
import { getTheme } from "./features/layout/layoutSlice";
import { getUser } from "./features/login/loginSlice";

import "./App.less";
import "@fontsource/roboto";
import "@fontsource/roboto/700.css";
import Reset from "./features/reset/Reset";
import { useServiceWorker } from "./lib/useServiceWorker";
import { Modal } from "antd";
import useTranslation from "./intl/useTranslation";
import getEnvironment from "./lib/getEnvironment";

const themes = {
	dark: `${process.env.PUBLIC_URL}/dark-theme.css`,
	light: `${process.env.PUBLIC_URL}/light-theme.css`,
};

function App() {
	const loadingStatus = useSelector(getLoadingStatus);
	const user = useSelector(getUser);
	const theme = useSelector(getTheme);
	const location = useLocation();
	const { showReload, reloadPage } = useServiceWorker();
	const { t } = useTranslation();
	const [modal, contextHolder] = Modal.useModal();

	useEffect(() => {
		if (showReload) {
			modal.info({
				title: t("notifications.updateTitle"),
				content: t("notifications.updateDescription"),
				okText: t("notifications.updateButton"),
				okButtonProps: { block: true },
				centered: true,
				onOk: reloadPage,
			});
		}
	}, [showReload, reloadPage, modal, t]);

	useEffect(() => {
		logEvent(analytics, "screen_view", {
			firebase_screen: location.pathname,
			firebase_screen_class: "default",
			app_version: process.env.REACT_APP_VERSION,
			app_environment: getEnvironment(),
		});
	}, [location]);

	useEffect(() => {
		if (user !== null && user !== "disconnected") {
			setUserProperties(analytics, {
				app_version: process.env.REACT_APP_VERSION,
			});
			setUserId(analytics, user.uid);
		}
	}, [user]);

	return (
		<ThemeSwitcherProvider
			themeMap={themes}
			defaultTheme={theme}
			insertionPoint={document.getElementById("inject-styles-here")}
		>
			<HelmetProvider>
				<Helmet
					defaultTitle="Socialife App"
					titleTemplate="%s - Socialife App"
				/>
				{typeof loadingStatus === "undefined" ||
				loadingStatus === "loading" ||
				user === null ? (
					<Loading />
				) : user === "disconnected" ? (
					<Routes>
						<Route path="/onboarding/:token/:email" element={<Onboarding />} />
						<Route path="/reset/:code" element={<Reset />} />
						<Route path="/privacy" element={<Privacy />} />
						<Route path="*" element={<Login />} />
					</Routes>
				) : user.status === "boarding" ? (
					<Onboarding />
				) : (
					<Layout />
				)}
				{contextHolder}
			</HelmetProvider>
		</ThemeSwitcherProvider>
	);
}

export default App;
