import { Button, Row, Col, Space, Typography, Tag, Collapse } from "antd";

import useTranslation from "../../intl/useTranslation";
import interests from "../../data/interests.json";

const { Title } = Typography;
const { Panel } = Collapse;

const Step2 = ({ influencer, setInfluencer, activeStep, setActiveStep }) => {
	const { t } = useTranslation();

	const validate = () =>
		!(Array.isArray(influencer.interests) && influencer.interests.length === 5);

	const addTag = (tag) =>
		((Array.isArray(influencer.interests) && influencer.interests.length < 5) ||
			!Array.isArray(influencer.interests)) &&
		setInfluencer({
			...influencer,
			interests: [
				...(Array.isArray(influencer.interests) ? influencer.interests : []),
				tag,
			],
		});

	const removeTag = (tag) =>
		setInfluencer({
			...influencer,
			interests: [
				...influencer.interests.filter((interest) => interest !== tag),
			],
		});

	return (
		<Row className="step-content" align="middle" justify="space-around">
			<Col className="onboarding-tags">
				<Space direction="vertical" size={42}>
					<Space className="tag-selected" wrap align="center" size={8}>
						{typeof influencer.interests === "undefined" ||
						(Array.isArray(influencer.interests) &&
							influencer.interests.length === 0)
							? t("onboarding.selectInterests")
							: influencer.interests.map((interest) => (
									<Tag
										closable
										className="selectable-tag"
										onClose={() => removeTag(interest)}
										onClick={() => removeTag(interest)}
										key={interest}
									>
										{t("onboarding." + interest)}
									</Tag>
							  ))}
						<Tag>
							{Array.isArray(influencer.interests)
								? influencer.interests.length
								: "0"}
							/5
						</Tag>
					</Space>
					<Collapse accordion>
						{Object.keys(interests).map((key) => (
							<Panel
								key={key}
								header={<Title level={4}>{t("onboarding." + key)}</Title>}
							>
								<Space wrap>
									{interests[key].map(
										(interest) =>
											((Array.isArray(influencer.interests) &&
												!influencer.interests.includes(interest)) ||
												!Array.isArray(influencer.interests)) && (
												<Tag
													className="selectable-tag"
													key={interest}
													onClick={() => addTag(interest)}
												>
													{t("onboarding." + interest)}
												</Tag>
											)
									)}
								</Space>
							</Panel>
						))}
					</Collapse>
					<Space size={18}>
						<Button size="large" onClick={() => setActiveStep(activeStep - 1)}>
							{t("onboarding.back")}
						</Button>
						<Button
							size="large"
							type="primary"
							disabled={validate()}
							onClick={() => setActiveStep(activeStep + 1)}
						>
							{t("onboarding.next")}
						</Button>
					</Space>
				</Space>
			</Col>
		</Row>
	);
};

export default Step2;
