import React from "react";
import { PageHeader } from "antd";

const Title = ({
	title,
	onBack = null,
	extra = null,
	subTitle = null,
	avatar = null,
	small = false,
	extraSpacer = false,
}) => {
	return (
		<PageHeader
			className={
				"page-title " +
				(subTitle !== null && "header-subtitle") +
				" " +
				(small && "header-small") +
				" " +
				(extraSpacer && "header-extra-spacer")
			}
			{...(avatar !== null && { avatar: { src: avatar, size: "large" } })}
			title={title}
			subTitle={subTitle}
			onBack={onBack}
			extra={extra}
		/>
	);
};

export default Title;
