import { Button, Row, Col, Space, InputNumber, Typography, Form } from "antd";
import { useState } from "react";
import { activeNetworks } from "../../data/networks";

import useTranslation from "../../intl/useTranslation";

const { Title } = Typography;
const { Item } = Form;

const Step3 = ({ influencer, setInfluencer, activeStep, setActiveStep }) => {
	const { t } = useTranslation();
	const [networks] = useState(activeNetworks(influencer));

	const validate = () =>
		networks
			.map(
				(network) =>
					typeof influencer[network].followers !== "number" ||
					influencer[network].followers === 0
			)
			.includes(true);

	const changeValue = (network, field, value) =>
		setInfluencer({
			...influencer,
			[network]: { ...influencer[network], [field]: value },
		});

	const Network = ({ network }) => (
		<Space
			key={network}
			direction="vertical"
			className="onboarding-form"
			size={1}
		>
			<Title level={4}>
				{typeof network === "string" &&
					network.charAt(0).toUpperCase() + network.slice(1)}
			</Title>
			<Item
				help={
					network === "linkedin"
						? t("onboarding.helpConnections")
						: network === "facebook"
						? t("onboarding.helpFriends")
						: t("onboarding.helpFollowers")
				}
			>
				<InputNumber
					size="large"
					name={`${network}Followers`}
					id={`${network}Followers`}
					min={2}
					style={{ width: "100%" }}
					value={
						influencer[network].followers === 0
							? null
							: influencer[network].followers
					}
					onChange={(value) => changeValue(network, "followers", value)}
					placeholder={
						network === "linkedin"
							? t("onboarding.labelConnections")
							: network === "facebook"
							? t("onboarding.labelFriends")
							: t("onboarding.labelFollowers")
					}
				/>
			</Item>
		</Space>
	);

	return (
		<Row className="step-content" align="middle" justify="space-around">
			<Col>
				<Space className="onboarding-form" direction="vertical" size={18}>
					{networks.map((network, key) => Network({ network }))}
					<Space size={18}>
						<Button size="large" onClick={() => setActiveStep(activeStep - 1)}>
							{t("onboarding.back")}
						</Button>
						<Button
							size="large"
							type="primary"
							disabled={validate()}
							onClick={() => setActiveStep(activeStep + 1)}
						>
							{t("onboarding.next")}
						</Button>
					</Space>
				</Space>
			</Col>
		</Row>
	);
};

export default Step3;
