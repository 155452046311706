const ClientData = ({ icon, data }) => {
	return (
		<div className="influencer-info">
			<div className="influencer-info-icon">{icon}</div>
			<div className="influencer-info-data">{data}</div>
		</div>
	);
};

export default ClientData;
