import { useSelector } from "react-redux";
import { Layout as AntdLayout } from "antd";
import { Routes, Route, Navigate } from "react-router-dom";

import MobileNav from "../navigation/MobileNav";
import MobileHeader from "../navigation/MobileHeader";
import DesktopHeader from "../navigation/DesktopHeader";
import Notifications from "../notifications/Notifications";
import { getUser } from "../login/loginSlice";

import Settings from "../settings/Settings";
import Influencers from "../influencers/Influencers";
import Influencer from "../dashboard/Influencer";
import InfluencerRewards from "../rewards/InfluencerRewards";
import Clients from "../clients/Clients";
import Missions from "../missions/Missions";
import HelpButtons from "./HelpButtons";
import Payments from "../payments/Payments";

const { Content } = AntdLayout;

const Layout = () => {
	const user = useSelector(getUser);

	const adminRoutes = [
		{
			path: "/influencers/*",
			component: <Influencers />,
		},
		{
			path: "/clients/*",
			component: <Clients />,
		},
		{
			path: "/missions/*",
			component: <Missions />,
		},
		{
			path: "/payments/*",
			component: <Payments />,
		},
		{
			path: "/*",
			component: <Navigate to="/influencers" />,
		},
	];

	const userRoutes = [
		{
			path: "/rewards/*",
			component: <InfluencerRewards />,
		},
		{
			path: "/missions/*",
			component: <Missions />,
		},
		{
			path: "/*",
			component: <Influencer />,
		},
	];

	const routes =
		user.role === "administrator" || user.role === "manager"
			? adminRoutes
			: userRoutes;

	return (
		<AntdLayout>
			<MobileHeader />
			<DesktopHeader />
			<Content className="content">
				<Routes>
					<Route
						path="/settings/*"
						element={
							<>
								<div className="desktop">
									<Settings type="desktop" />
								</div>
								<Settings type="mobile" />
							</>
						}
					/>

					{routes.map((route) => (
						<Route
							key={route.path}
							path={route.path}
							element={route.component}
						/>
					))}
				</Routes>
			</Content>
			<MobileNav />
			<Notifications />
			<HelpButtons />
		</AntdLayout>
	);
};

export default Layout;
